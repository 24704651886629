<template>
  <v-speed-dial ref="base-fab-speed-dial-root" direction="top" transition="slide-x-reverse-transition" color="primary" v-model="fab" :open-on-hover="true" large fixed bottom right>
    <template ref="base-fab-activator" v-if="isVisible" v-slot:activator>
      <v-btn ref="base-fab-buttons" color="primary" v-model="fab" @click="handleBaseFabClick" fab>
        <v-icon ref="base-fab-close-button" v-if="fab">mdi-close</v-icon>
        <v-icon ref="base-fab-plus-button" v-else>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-hover ref="base-fab-item-hover" v-for="(element, index) in fabOptions" close-delay="50" open-delay="50" :key="index" v-slot="{ hover }">
      <v-btn
        ref="base-fab-item-btn"
        class="me-0 align-self-end"
        elevation="8"
        :color="hover ? 'primary' : 'secondary'"
        :disabled="element.isFabDisabled"
        :fab="!hover"
        :rounded="hover"
        :x-large="hover"
        @click="element.onClickAction()"
        ripple
      >
        <div ref="base-fab-hover-text" v-show="hover" class="mx-2 align-self-center">{{ element.text }}</div>
        <v-icon ref="base-fab-hover-white-icon" v-show="hover" class="mx-1 pa-1 white primary--text" style="border-radius: 16px">{{ element.icon }}</v-icon>
        <v-icon ref="base-fab-hover-transparent-icon" v-show="!hover" class="transparent white--text">{{ element.icon }}</v-icon>
      </v-btn>
    </v-hover>
  </v-speed-dial>
</template>

<script>
export default {
  name: "BaseFab",
  props: {
    fabOptions: {
      type: Array,
      default: () => [{ icon: "mdi-blank", text: "", isFabDisabled: false, onClickAction: undefined }]
    },
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fab: false
    }
  },
  methods: {
    handleBaseFabClick() {
      this.fab = !this.fab
    }
  }
}
</script>
