<template>
  <v-layout>
    <v-snackbar
      v-for="(snackbar, index) in this.getSnackbars"
      app
      class="wrap d-inline-flex"
      :key="snackbar.id"
      :value="snackbar"
      :color="snackbar.color"
      :timeout="snackbar.timeout"
      :style="determineSpacing(index)"
      @input="removeSnackbar(snackbar.id)"
      elevation="4"
      :max-width="determineSnackbarWidth"
      max-height="150px"
    >
      <v-icon ref="snackbar-icon" class="pa-0 transparent">{{ snackbar.icon }}</v-icon>
      <span class="ps-4 text-sm-subtitle-1 text-capitalize">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-if="snackbar.negativeAction.isEnabled"
          ref="snackbar-negative-action-btn"
          color="transparent"
          elevation="0"
          v-bind="attrs"
          ripple
          rounded
          v-text="snackbar.negativeAction.text"
          @click="() => removeSnackbar(snackbar.id)"
        >
        </v-btn>
        <v-btn
          v-if="snackbar.positiveAction.isEnabled"
          ref="snackbar-positive-action-btn"
          color="transparent"
          elevation="0"
          v-bind="attrs"
          ripple
          rounded
          v-text="snackbar.positiveAction.text"
          @click="handleOnClick(snackbar)"
        >
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { round } from "@/utils/Round"
import { mapActions, mapState } from "pinia"
import { useSnackbarStore } from "@/stores/Snackbar"

export default {
  name: "AppSnackbarPresenter",
  methods: {
    ...mapActions(useSnackbarStore, ["removeSnackbar"]),
    async handleOnClick(snackbar) {
      if (snackbar.positiveAction.event !== undefined) {
        await snackbar.positiveAction.event()
      }
      this.removeSnackbar(snackbar.id)
    },
    determineSpacing(index) {
      return this.$vuetify.breakpoint.smAndDown ? `bottom: ${round((index + 0.8) * 85)}px` : `bottom: ${round(index * 60)}px`
    }
  },
  computed: {
    ...mapState(useSnackbarStore, ["getSnackbars"]),
    determineSnackbarWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? "800px" : this.$vuetify.breakpoint.sm ? "500px" : "400px"
    }
  }
}
</script>
