<template>
  <v-card class="flex-fill" ref="dashboard-card-root" min-width="175" min-height="175" :loading="isLoading">
    <slot name="title">
      <v-card-title class="mt-0" ref="dashboard-card-title">
        <v-list-item-avatar color="primary">
          <v-icon class="elevation-6" color="white" ref="dashboard-card-icon">{{ icon }}</v-icon>
        </v-list-item-avatar>
      </v-card-title>
    </slot>
    <slot name="subheader">
      <v-card-subtitle class="mb-n1" ref="dashboard-card-subtitle-text">{{ subTitle }}</v-card-subtitle>
    </slot>
    <slot name="content">
      <v-card-text class="flex-fill" ref="dashboard-card-content-text" :style="{ 'font-size': '1.2em' }">{{ formatAsCurrency }}</v-card-text>
      <v-list-item class="mt-n4">
        <v-list-item-subtitle class="ma-0 pa-0" ref="dashboard-card-content-subtitle-text">Avg. Revenue</v-list-item-subtitle>
      </v-list-item>
    </slot>
    <slot name="actions">
      <v-card-actions />
    </slot>
  </v-card>
</template>

<script>
import { formatInternationalCurrency } from "@/utils/InternationalCurrencyFormatters"

export default {
  name: "DashboardCard",
  props: {
    subTitle: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: "mdi-blank"
    },
    revenueAmount: {
      type: Number,
      default: 0.0
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatAsCurrency() {
      return formatInternationalCurrency(this.revenueAmount)
    }
  }
}
</script>
