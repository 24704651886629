<template>
  <v-container fluid>
    <v-navigation-drawer class="elevation-4 rounded-card" ref="app-nav-tab-drawer-root" color="navigationDrawerBackground" permanent width="100%" location="top">
      <v-tabs ref="app-tab-nav-drawer-v-tabs" v-model="tab" show-arrows optional>
        <v-tab v-for="(tab, index) in getJobTabs" :key="index" @click="navToJob(tab)">
          <div>{{ getItemText(tab) }}</div>
          <v-icon @click.stop="closeTab(tab.jobId)" class="float-end">
            mdi-close
          </v-icon>
        </v-tab>
      </v-tabs>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { useMainStore } from "@/stores/Main"
import { mapState, mapActions } from "pinia"

export default {
  name: "AppTabNavDrawer",
  data() {
    return {
      tab: null
    }
  },
  methods: {
    ...mapActions(useMainStore, ["deleteJobTab"]),
    navToJob(tab) {
      if (this.$route.name === "Job" && this.$route.params.jobId === tab.jobId) return
      this.$router.push({ name: "Job", params: { jobId: tab.jobId } })
    },
    async closeTab(jobId) {
      let i = this.getJobTabs.findIndex(t => t.jobId === jobId)
      if (i === this.tab) {
        this.tab = null
      } else if (i < this.tab) {
        this.tab = this.tab - 1
      }
      await this.deleteJobTab(jobId)
    },
    getItemText(item) {
      return item.displayText()
    }
  },
  computed: {
    ...mapState(useMainStore, ["getJobTabs"])
  }
}
</script>

<style scoped>
.rounded-card {
  border-radius: 16px !important;
}
</style>
