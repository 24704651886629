<template>
  <base-dialog ref="settings-create-operating-unit-allocation-group-dialog-root" title="Create Operating Unit Allocation Group" :is-dialog-visible="isDialogVisible" dialog-image="mdi-basket-plus">
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-create-operating-unit-allocation-group-dialog-form">
        <v-row dense>
          <v-col>
            <v-text-field ref="settings-create-operating-unit-allocation-group-dialog-name" outlined label="Group Name" counter="50" v-model="groupName" :rules="isRequiredRules" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-select
              ref="settings-create-tax-dialog-franchise-selector"
              label="Selected Franchises"
              v-model="selectedFranchises"
              :items="getFranchisesInOperatingUnit"
              item-text="franchiseNameAndNumber"
              item-value="franchiseId"
              :rules="isRequiredSelectorRules"
              multiple
              outlined
              :menu-props="{ bottom: true, offsetY: true }"
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-create-operating-unit-allocation-group-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="settings-create-operating-unit-allocation-group-dialog-confirm-btn" color="primary" @click="save" :loading="getIsLoadingOperatingUnitAllocationGroups" :disabled="getIsLoadingOperatingUnitAllocationGroups" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog.vue"
import { postOperatingUnitAllocationGroupDto } from "@/api/dtos/JunkDtos"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"

export default {
  name: "SettingsCreateOperatingUnitAllocationGroupDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      groupName: "",
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      selectedFranchises: [],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["createOperatingUnitAllocationGroup"]),
    async save() {
      if (this.$refs["settings-create-operating-unit-allocation-group-dialog-form"].validate()) {
        const dto = postOperatingUnitAllocationGroupDto(this.groupName.trim(), this.selectedFranchises)
        await this.createOperatingUnitAllocationGroup({ operatingUnitId: this.getSelectedOperatingUnitId, postOperatingUnitAllocationGroupDto: dto }).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, ["getFranchisesInOperatingUnit", "getIsLoadingOperatingUnitAllocationGroups"])
  }
}
</script>

<style scoped></style>
