<template>
  <dashboard-chart-card ref="dashboard-closed-conversion-chard-card-root" title="Closed Conversion" sub-card-text="WEEK" :is-loading="getIsLoadingDashboardClosedConversionReport">
    <template v-slot:content>
      <v-card-text>
        <v-row>
          <v-col>
            <g-chart
              v-if="isClosedConversionReportDataVisible"
              ref="dashboard-closed-conversion-chard-card-chart"
              :type="chartType"
              :data="formattedChartData"
              :options="chartOptions"
              :settings="{ packages: ['sankey'] }"
            />
            <AppNotFound ref="dashboard-closed-conversion-chart-card-app-not-found" v-else content="Not enough job data available for report.">
              <template v-slot:actions>
                <div />
              </template>
            </AppNotFound>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </dashboard-chart-card>
</template>

<script>
import DashboardChartCard from "@/components/DashboardChartCard"
import { GChart } from "vue-google-charts/legacy"
import AppNotFound from "@/components/AppNotFound.vue"
import { useDashboardStore } from "@/stores/Dashboard"
import { mapState } from "pinia"
import { JobStatus } from "@/enums/JobStatus"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import applicationColors from "@/utils/ApplicationColors"

export default {
  name: "DashboardClosedConversionChartCard",
  components: { DashboardChartCard, "g-chart": GChart, AppNotFound },
  data() {
    return {
      chartType: "Sankey"
    }
  },
  computed: {
    ...mapState(useDashboardStore, ["getIsLoadingDashboardClosedConversionReport", "getClosedConversionReportData"]),
    chartOptions() {
      return {
        sankey: {
          iterations: 0,
          node: {
            label: {
              fontName: "roboto",
              fontSize: 12,
              color: this.$vuetify.theme.dark ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred,
              bold: false,
              italic: false
            },
            colors: [
              getJobStatusColorByStatusName(JobStatus.SCHEDULED.name),
              getJobStatusColorByStatusName(JobStatus.CLOSED.name),
              getJobStatusColorByStatusName(JobStatus.CANCELLED.name)
            ],
            interactivity: true,
            nodePadding: 36,
            width: 20
          },
          link: {
            colorMode: "gradient",
            color: {
              stroke: getJobStatusColorByStatusName(JobStatus.SCHEDULED.name), // Color of the link border.
              strokeWidth: 0.5 // Thickness of the link border (default 0).
            },
            colors: [
              getJobStatusColorByStatusName(JobStatus.SCHEDULED.name),
              getJobStatusColorByStatusName(JobStatus.CLOSED.name),
              getJobStatusColorByStatusName(JobStatus.CANCELLED.name)
            ]
          }
        }
      }
    },
    formattedChartData() {
      if (this.getClosedConversionReportData === undefined || this.getClosedConversionReportData === null) {
        return [["From", "To", "Job Count"], ["","",""]]
      }
      let closedTitle = this.getClosedConversionReportData?.closedTitle ?? "0.00 %"
      let closedCount = this.getClosedConversionReportData?.closed ?? 1

      let cancelledTitle = this.getClosedConversionReportData?.cancelledTitle ?? "0.00 %"
      let cancelledCount = this.getClosedConversionReportData?.cancelled ?? 1

      let fromLabel = `${this.getClosedConversionReportData?.scheduledTitle ?? "Scheduled"}`

      return [
        ["From", "To", "Job Count"],
        [fromLabel, closedTitle, closedCount],
        [fromLabel, cancelledTitle, cancelledCount]
      ]
    },
    isClosedConversionReportDataVisible() {
      return (
        this.getClosedConversionReportData.scheduled > 0 &&
        (this.getClosedConversionReportData.cancelled > 0 || this.getClosedConversionReportData.closed > 0) &&
        !this.getIsLoadingDashboardClosedConversionReport
      )
    }
  }
}
</script>
