<template>
  <base-dialog ref="schedule-truck-unavailability-dialog-root" :is-dialog-visible="isDialogVisible" dialog-image="mdi-car-wrench" title="Schedule Truck Unavailability" @close-dialog="closeDialog">
    <template v-slot:content>
      <v-form ref="form" v-model="isFormValid">
        <v-layout row>
          <v-autocomplete
            ref="schedule-truck-unavailability-dialog-truck-selector"
            label="Selected Truck"
            v-model="selectedTruckId"
            :rules="truckSelectionValidationRules"
            :error-messages="recordAlreadyExistsError"
            :items="getActiveAndFutureTrucksInOperatingUnitSortedByName"
            :disabled="getTruckUnavailabilityLoadingState"
            item-value="id"
            item-text="name"
            outlined
          />
        </v-layout>
        <v-layout row>
          <app-date-range-menu-picker
            ref="schedule-truck-unavailability-dialog-date-range-picker"
            text-field-class="mb-n4"
            :minimum-date-value="minimumDate"
            :date-range="selectedDateRange"
            :is-disabled="getTruckUnavailabilityLoadingState"
            @updatedRange="setDateRange"
          ></app-date-range-menu-picker>
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn
        class="pa-4"
        ref="schedule-truck-unavailability-dialog-cancel-btn"
        color="secondary"
        @click="closeDialog"
        :disabled="getTruckUnavailabilityLoadingState"
        text
        rounded
        ripple
        >Cancel</v-btn
      >
      <v-btn
        class="pa-4"
        ref="schedule-truck-unavailability-dialog-confirm-btn"
        @click="save"
        :disabled="getTruckUnavailabilityLoadingState"
        :loading="getTruckUnavailabilityLoadingState"
        color="primary"
        text
        rounded
        ripple
        >Confirm</v-btn
      >
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDateRangeMenuPicker from "@/components/AppDateRangeMenuPicker"
import { dateAsDayOfWeekMonthDayYear, formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsCreateTruckUnavailabilityDialog",
  components: { AppDateRangeMenuPicker, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: false,
      minimumDate: todayAsDate(),
      selectedTruckId: undefined,
      selectedDateRange: [todayAsDate()],
      truckSelectionValidationRules: [value => !!value || "A truck is required."]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["createTruckUnavailability"]),
    setDateRange(dateRange) {
      this.selectedDateRange = dateRange
    },
    async save() {
      if (this.$refs.form.validate() && !this.doesOverlappingRecordExist) {
        let truckUnavailabilityDto = await this.createTruckUnavailabilityDto()
        await this.createTruckUnavailability(truckUnavailabilityDto).then(() => this.closeDialog())
      }
    },
    async createTruckUnavailabilityDto() {
      return {
        junkTruckId: this.selectedTruckId,
        beginDateTime: this.beginDate,
        endDateTime: this.endDate,
        modifiedBy: this.$msal.getCurrentUserId()
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    generateErrorMessage() {
      const truckName = this.findOverlappingRecords[0].truckName
      const beginDateFormatted = dateAsDayOfWeekMonthDayYear(this.findOverlappingRecords[0].beginDateTime)
      const endDateFormatted = dateAsDayOfWeekMonthDayYear(this.findOverlappingRecords[0].endDateTime)
      return [`Sorry, a record already exists with an overlapping date range for truck '${truckName}'! Range: ${beginDateFormatted} to ${endDateFormatted}.`]
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getAllUnavailableTrucks", "getActiveAndFutureTrucksInOperatingUnitSortedByName", "getTruckUnavailabilityLoadingState"]),
    beginDate() {
      return this.selectedDateRange[0]
    },
    endDate() {
      return this.selectedDateRange[1] ?? this.beginDate
    },
    recordAlreadyExistsError() {
      return this.doesOverlappingRecordExist ? this.generateErrorMessage() : []
    },
    doesOverlappingRecordExist() {
      return this.findOverlappingRecords?.length > 0
    },
    findOverlappingRecords() {
      return this.getAllUnavailableTrucks
        ?.slice()
        .filter(
          gau =>
            gau.junkTruckId === this.selectedTruckId &&
            ((formatAsDateOnly(gau.beginDateTime) <= this.beginDate && this.beginDate <= (formatAsDateOnly(gau.endDateTime) ?? todayAsDate())) ||
              (formatAsDateOnly(gau.beginDateTime) <= this.endDate && this.endDate <= (formatAsDateOnly(gau.endDateTime) ?? todayAsDate())))
        )
    }
  }
}
</script>

<style scoped></style>
