<template>
  <v-container fluid>
    <base-dialog
      :is-dialog-visible="isDialogVisible"
      title="Override Global Down Time?"
      dialog-image="mdi-alert-outline"
      ref="job-override-global-down-time-confirmation-dialog-root"
    >
      <template v-slot:content>
        <v-row>
          <span class="text-md-body-1" ref="job-override-global-down-time-confirmation-dialog-phrase-one">You are about to manually set the Down Time for all trucks and employees on this job which will delete all existing Down Time records with the value set for Global Down Time.</span>
        </v-row>
        <v-row class="mt-8">
          <span class="text-md-body-1" ref="job-override-global-down-time-confirmation-dialog-phrase-two">Are you sure you want to continue?</span>
        </v-row>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="job-override-global-down-time-confirmation-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded>Cancel</v-btn>
        <v-btn class="pa-4" ref="job-override-global-down-time-confirmation-dialog-confirm-btn" color="primary" @click="saveGlobalTimes" text rounded>Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { formatTimeAsDateUtc } from "@/utils/DateTimeFormatters"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { updateTrucksAndEmployeeGlobalTimesDto } from "@/api/dtos/JunkDtos"
import { mapState, mapActions } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobOverrideGlobalDownTimeConfirmationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapActions(useJobStore, ["updateGlobalTimes"]),
    async saveGlobalTimes() {
      const dto = this.generateDto()
      await this.updateGlobalTimes(dto).then(() => this.closeDialog())
    },
    generateDto() {
      const jobTruckIds = this.getTrucksOnJob?.map(truck => truck.jobTruckId)
      const startDateTime = formatTimeAsDateUtc(this.getCurrentJobScheduledDateOrToday, this.getTrucksAndEmployeesGlobalStartTime)
      const endDateTime = formatTimeAsDateUtc(this.getCurrentJobScheduledDateOrToday, this.getTrucksAndEmployeesGlobalEndTime)
      const parseDownTime = parseFloat(this.getTrucksAndEmployeesGlobalDownTime)
      const downTime = isNaN(parseDownTime) ? formatDecimalValue(0) : parseDownTime
      return updateTrucksAndEmployeeGlobalTimesDto(this.getCurrentJob?.id, jobTruckIds, downTime, startDateTime, endDateTime, this.$msal.getCurrentUserId())
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getCurrentJob",
      "getCurrentJobScheduledDateOrToday",
      "getTrucksOnJob",
      "getEmployeesOnJob",
      "getTrucksAndEmployeesGlobalStartTime",
      "getTrucksAndEmployeesGlobalDownTime",
      "getTrucksAndEmployeesGlobalEndTime"
    ])
  }
}
</script>
