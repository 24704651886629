<template>
  <v-container class="mt-4" fluid>
    <v-row>
      <v-col>
        <settings-auth-management-users-card
          v-if="!isActionsDisabled"
          ref="settings-employee-tab-auth-management-users-card"
          :isActionsDisabled="isActionsDisabled"
          @editItem="handleUpdateHelperEmployee"
          @removeItem="handleRemoveHelperEmployee"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <settings-auth-management-roles-card v-if="!isActionsDisabled" ref="settings-employee-tab-auth-management-roles-card" />
      </v-col>
    </v-row>
    <settings-create-update-helper-employee-dialog
      v-if="isCreateUpdateHelperEmployeeDialogVisible"
      :is-dialog-visible="isCreateUpdateHelperEmployeeDialogVisible"
      :title="createUpdateHelperEmployeeDialogTitle"
      :id="getCreateUpdateHelperEmployeeId"
      :first-name="getCreateUpdateHelperEmployeeFirstName"
      :last-name="getCreateUpdateHelperEmployeeLastName"
      :assigned-operating-units="getCreateUpdateHelperEmployeeAssignedOperatingUnits"
      :confirmation-button-text="createUpdateHelperEmployeeDialogConfirmationButtonText"
      :type="createUpdateHelperEmployeeType"
      @closeDialog="setCreateUpdateHelperEmployeeDialogVisibility(false)"
    />
    <settings-delete-helper-employee-dialog
      v-if="isDeleteHelperEmployeeDialogVisible"
      :is-dialog-visible="isDeleteHelperEmployeeDialogVisible"
      :id="getDeleteHelperEmployeeId"
      :first-name="getDeleteHelperEmployeeFirstName"
      :last-name="getDeleteHelperEmployeeLastName"
      :assigned-operating-units="getDeleteHelperEmployeeAssignedOperatingUnits"
      @closeDialog="setDeleteHelperEmployeeDialogVisibility(false)"
    />

    <base-fab ref="settings-tab-base-fab" v-if="!isActionsDisabled" :is-visible="!isActionsDisabled" :fab-options="fabOptions" />
  </v-container>
</template>

<script>
import SettingsAuthManagementUsersCard from "@/components/SettingsAuthManagementUsersCard"
import SettingsAuthManagementRolesCard from "@/components/SettingsAuthManagementRolesCard"
import SettingsCreateUpdateHelperEmployeeDialog from "@/components/SettingsCreateUpdateHelperEmployeeDialog"
import SettingsDeleteHelperEmployeeDialog from "@/components/SettingsDeleteHelperEmployeeDialog"
import BaseFab from "@/components/BaseFab"

export default {
  name: "SettingsEmployeeTab",
  components: {
    SettingsDeleteHelperEmployeeDialog,
    SettingsAuthManagementRolesCard,
    SettingsAuthManagementUsersCard,
    SettingsCreateUpdateHelperEmployeeDialog,
    BaseFab
  },
  data() {
    return {
      createUpdateHelperEmployeeObject: {},
      isCreateUpdateHelperEmployeeDialogVisible: false,
      createUpdateHelperEmployeeDialogTitle: "",
      createUpdateHelperEmployeeDialogConfirmationButtonText: "",
      createUpdateHelperEmployeeType: null,
      isDeleteHelperEmployeeDialogVisible: false,
      deleteHelperEmployeeObject: {}
    }
  },
  methods: {
    handleCreateHelperEmployee() {
      this.createUpdateHelperEmployeeObject = Object.assign({}, {})
      this.createUpdateHelperEmployeeDialogTitle = "Add Helper Employee"
      this.createUpdateHelperEmployeeDialogConfirmationButtonText = "Create"
      this.createUpdateHelperEmployeeType = 1
      this.setCreateUpdateHelperEmployeeDialogVisibility(true)
    },
    handleUpdateHelperEmployee(helperEmployee) {
      this.createUpdateHelperEmployeeObject = Object.assign({}, helperEmployee)
      this.createUpdateHelperEmployeeDialogTitle = "Edit Helper Employee"
      this.createUpdateHelperEmployeeDialogConfirmationButtonText = "Update"
      this.createUpdateHelperEmployeeType = 2
      this.setCreateUpdateHelperEmployeeDialogVisibility(true)
    },
    handleRemoveHelperEmployee(helperEmployee) {
      this.deleteHelperEmployeeObject = Object.assign({}, helperEmployee)
      this.setDeleteHelperEmployeeDialogVisibility(true)
    },
    setCreateUpdateHelperEmployeeDialogVisibility(isVisible) {
      this.isCreateUpdateHelperEmployeeDialogVisible = isVisible
    },
    setDeleteHelperEmployeeDialogVisibility(isVisible) {
      this.isDeleteHelperEmployeeDialogVisible = isVisible
    }
  },
  computed: {
    fabOptions() {
      return [
        {
          isFabDisabled: this.isActionsDisabled,
          icon: "mdi-account-plus-outline",
          text: "Create Helper Employee",
          onClickAction: () => this.handleCreateHelperEmployee()
        }
      ]
    },
    isActionsDisabled() {
      return !this.$msal.getUserHasWriteSettingsPermission()
    },
    getCreateUpdateHelperEmployeeId() {
      return this.createUpdateHelperEmployeeObject?.id ?? null
    },
    getCreateUpdateHelperEmployeeFirstName() {
      return this.createUpdateHelperEmployeeObject?.firstName ?? ""
    },
    getCreateUpdateHelperEmployeeLastName() {
      return this.createUpdateHelperEmployeeObject?.lastName ?? ""
    },
    getCreateUpdateHelperEmployeeAssignedOperatingUnits() {
      return this.createUpdateHelperEmployeeObject?.operatingUnits ?? []
    },
    getDeleteHelperEmployeeId() {
      return this.deleteHelperEmployeeObject?.id ?? null
    },
    getDeleteHelperEmployeeFirstName() {
      return this.deleteHelperEmployeeObject?.firstName ?? ""
    },
    getDeleteHelperEmployeeLastName() {
      return this.deleteHelperEmployeeObject?.lastName ?? ""
    },
    getDeleteHelperEmployeeAssignedOperatingUnits() {
      return this.deleteHelperEmployeeObject?.operatingUnits?.filter(x => x.isEnabled).map(ou => ou.operatingUnitId) ?? []
    }
  }
}
</script>
