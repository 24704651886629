<template>
  <div ref="job-receipt-phrase-step-carousel" v-if="isPhraseStepDialogVisible" class="mx-auto">
    <v-window v-model="phraseStep" touchless>
      <v-window-item ref="job-receipt-phrase-step-carousel-documents-list" v-for="item in jobDocumentPhrases" :key="item.jobFranchiseDocumentPhraseId" :value="item.stepNumber">
        <v-card-text ref="job-receipt-phrase-step-carousel-phrases" v-if="!item.isIntermission" class="px-8 pb-8 pt-8">
          <v-row>
            <v-col class="text-center">
              <span ref="job-receipt-signature-step-phrase-heading-text" class="text-h6 mb-4 word-wrap text-break">{{ getSignatureStepPhraseHeading }}</span>
            </v-col>
          </v-row>
          <v-divider class="mb-4 mt-4" />
          <v-row v-if="phraseStep === 3" class="align-center justify-center mb-4">
            <v-col class="text-center">
              <v-row class="justify-center">
                Price
              </v-row>
              <v-row class="justify-center">
                <span class="text-h6">${{ (getCurrentJob.price).toFixed(2) || 0 }}</span>
              </v-row>
            </v-col>
            <v-col v-if="hasDiscount" class="text-center">
              <v-row class="justify-center">
                Discount
              </v-row>
              <v-row class="justify-center">
                <span class="text-h6">(${{ (getCurrentJob.discountAmount).toFixed(2) || 0 }})</span>
              </v-row>
            </v-col>
            <v-col class="text-center">
              <v-row class="justify-center">
                Tips
              </v-row>
              <v-row class="justify-center">
                <span class="text-h6">${{ (getCurrentJob.totalTip).toFixed(2) || 0 }}</span>
              </v-row>
            </v-col>
            <v-col class="text-center">
              <v-row class="justify-center">
                Tax
              </v-row>
              <v-row class="justify-center">
                <span class="text-h6">${{ (getCurrentJob.totalTax).toFixed(2) || 0 }}</span>
              </v-row>
            </v-col>
            <div style="height: 45px; width: 2px; background-color: #BBB"></div>
            <v-col class="text-center">
              <v-row class="justify-center">
                <strong>Total</strong>
              </v-row>
              <v-row class="justify-center">
                <span class="text-h6">${{ (getCurrentJob.totalPrice).toFixed(2) || 0 }}</span>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-checkbox
                ref="job-receipt-phrase-step-carousel-signing-checkbox"
                :key="getPhraseCheckboxKey"
                :input-value="item.customerRepresentativeId || item.employeeProfileId"
                :disabled="isCheckboxDisabled(item)"
                @change="handleToggleCheckbox($event)"
                color="primary"
              />
            </v-col>
            <v-col>
              <span ref="job-receipt-phrase-step-carousel-phrase-text" class="text-h5">{{ item.phraseText }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-slide-x-reverse-transition>
                <v-card :style="{background: getCardBackgroundColor}" ref="job-receipt-phrase-step-carousel-signature-card" v-if="item.customerRepresentativeId">
                  <v-img ref="job-receipt-phrase-step-carousel-signature-image" :src="getSignatureImage(item)"></v-img>
                  <v-card-text ref="job-receipt-phrase-step-carousel-signature-text" class="text-h4 text-center" :style="{color: getCardColor}">{{ getSignaturePrintedName(item) }}</v-card-text>
                </v-card>
                <v-card :style="{background: getCardBackgroundColor}" ref="job-receipt-phrase-step-carousel-override-card" v-else-if="hasDriverOverride">
                  <v-card-text ref="job-receipt-phrase-step-carousel-override-reason" class="text-center" :style="{color: getCardColor}">
                    DRIVER OVERRIDE: {{ OverrideReason.CUSTOMER_NOT_PRESENT.reasonText }}
                  </v-card-text>
                  <v-card-text ref="job-receipt-phrase-step-carousel-override-name" class="text-h4 text-center" :style="{color: getCardColor}">
                    {{ getEmployeeInfo(item) }}
                  </v-card-text>
                </v-card>
              </v-slide-x-reverse-transition>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text ref="job-receipt-phrase-step-carousel-intermissions" class="mb-8 px-8 pt-6" v-else style="min-height: 100px">
          <v-row>
            <v-col class="text-center">
              <span ref="job-receipt-phrase-step-carousel-intermission-text" class="text-h6 mb-4 word-wrap">{{ getIntermissionStepPhrase }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-slide-x-reverse-transition>
                <v-img ref="job-receipt-phrase-step-carousel-intermission-image" :src="getIntermissionImage" min-width="50" min-height="50"></v-img>
              </v-slide-x-reverse-transition>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>
    <v-divider v-if="phraseStep === 1 || phraseStep === 3" />
    <v-card-actions v-if="phraseActionsVisible">
      <v-btn v-if="phraseStep > 1" ref="job-receipt-phrase-step-carousel-back-button" class="ms-2" outlined rounded elevation="1" @click="handlePrev">
        Back
      </v-btn>
      <v-spacer />
      <v-btn
        ref="job-receipt-phrase-step-carousel-next-button"
        class="me-2"
        :disabled="isNextDisabled"
        color="primary"
        outlined
        rounded
        elevation="1"
        :loading="getIsLoadingPhrase"
        @click="handleNext"
      >
        {{nextButtonText}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import ApplicationColors from "@/utils/ApplicationColors"
import { OverrideReason } from "@/enums/JobDocumentPhraseOverrideReasons"
import { isObjectEmpty } from "@/utils/ObjectHelpers"
import { mapState } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobReceiptPhraseStepCarousel",
  props: {
    isPhraseStepDialogVisible: {
      type: Boolean,
      required: true
    },
    hasDriverOverride: {
      type: Boolean,
      required: true
    },
    currentCustomerRepresentative: {
      type: Object,
      required: true
    },
    phraseCustomerRepresentativeId: {
      type: String,
      required: false
    },
    phraseEmployeeProfileId: {
      type: String,
      required: false
    },
    jobDocumentPhrases: {
      type: Array,
      required: true
    },
    isPhraseIntermission: {
      type: Boolean,
      required: true
    },
    phraseStep: {
      type: Number,
      required: true
    },
    phraseActionsVisible: {
      type: Boolean,
      required: true
    },
    nextButtonText: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(useJobStore, ["getCustomerRepresentativeProfiles", "getJobDocumentPhraseEmployeeProfiles", "getPhraseCheckboxKey", "getIsLoadingPhrase", "getCurrentJob", "getSelectedJobDiscount"]),
    OverrideReason() {
      return OverrideReason
    },
    getSignatureStepPhraseHeading() {
      return (this.phraseStep === 1) ? "Hi, " + this.currentCustomerRepresentative.firstName + "! Let's get started with your signature."
          : this.currentCustomerRepresentative.firstName + ", everything look good?"
    },
    getIntermissionStepPhrase() {
      return "Thank you, " + this.currentCustomerRepresentative.firstName + "! Please hand the tablet back to " + this.$msal.getCurrentUserGivenName() + "."
    },
    getIntermissionImage() {
      if (this.isDarkThemeEnabled) {
        return require("@/../public/tmjt-logo-dark.png")
      }
      return require("@/../public/tmjt-two-guys.jpeg")
    },
    isNextDisabled() {
      return (!this.phraseCustomerRepresentativeId && !this.phraseEmployeeProfileId && !this.isPhraseIntermission) || this.getIsLoadingPhrase
    },
    isDarkThemeEnabled() {
      return this.$vuetify.theme.dark
    },
    getCardBackgroundColor() {
      return ApplicationColors.vuetify.light
    },
    getCardColor() {
      return ApplicationColors.vuetify.dark
    },
    hasDiscount() {
      return !isObjectEmpty(this.getSelectedJobDiscount)
    }
  },
  methods: {
    isCheckboxDisabled(item) {
      return item.customerRepresentativeId && item.isSavedToServer
    },
    handleToggleCheckbox($event) {
      if ($event) {
        this.$emit("on-update-phrase-complete")
      } else {
        this.$emit("on-update-phrase-uncomplete")
      }
    },
    handlePrev() {
      this.$emit("on-prev")
    },
    handleNext(item) {
      this.$emit("on-next", item.jobFranchiseDocumentPhraseId)
    },
    getSignatureImage(item) {
      const id = item.customerRepresentativeId
      const profile = this.getCustomerRepresentativeProfiles.find(profile => profile.customerRepresentativeId === id)
      return profile ? profile.signature : ""
    },
    getSignaturePrintedName(item) {
      const id = item.customerRepresentativeId
      const profile = this.getCustomerRepresentativeProfiles.find(profile => profile.customerRepresentativeId === id)
      return profile ? `${profile.firstName} ${profile.lastName}` : "name unavailable"
    },
    getEmployeeInfo(item) {
      const id = item.employeeProfileId
      const employee = this.getJobDocumentPhraseEmployeeProfiles.find(profile => profile.employeeProfileId === id)
      return employee ? `${employee.firstName} ${employee.lastName}` : "name unavailable"
    }
  }
}
</script>
