<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card :loading="getIsLoadingOperatingUnitAllocationGroups" min-height="275" elevation="4">
      <v-card-title>
        <span class="headline me-2 word-wrap" ref="settings-franchise-allocation-bucket-card-heading">Operating Unit Allocation Groups</span>
        <base-tool-tip
          class="ps-4 ms-4"
          ref="settings-franchise-allocation-bucket-card-info-tool-tip"
          dialog-title=""
          :nudge-dialog="this.$vuetify.breakpoint.mobile ? '80' : '150'"
          icon-color="primary"
          tooltip-color="primary"
          tooltip-icon="mdi-information-outline"
          :is-bottom-dialog="true"
        >
          <template v-slot:content>
            <div ref="settings-franchise-allocation-bucket-card-info-tool-tip-content" class="subtitle-1">When looking at slot availability for a franchise, the slot availability of all franchises in the grouping(s) that franchise is in will be considered.</div>
          </template>
        </base-tool-tip>
      </v-card-title>
      <v-card-text class="px-8">
        <v-responsive class="overflow-y-auto" max-height="500">
          <v-row>
            <v-col cols="auto" v-for="(group, index) in this.getOperatingUnitAllocationGroups" :key="index" min-height="150" min-width="150">
              <v-card elevation="0" class="primary-light-solid-border">
                <v-card-title>
                  <span v-if="editedGroupNameInitial === group.operatingUnitAllocationGroupName">
                    <v-btn :disabled="getIsLoadingOperatingUnitAllocationGroups" class="float-end" color="red" ref="settings-franchise-allocation-bucket-card-cancel-item-btn" @click="setIsEditingGroupName(false, {})" icon>
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                    <v-btn :disabled="getIsLoadingOperatingUnitAllocationGroups" class="me-2 float-end" icon @click="updateGroupName">
                      <v-icon color="primary">mdi-content-save</v-icon>
                    </v-btn>
                    <v-text-field class="headline" v-model="editedGroupName" :rules="nameRules" @keyup.enter="updateGroupName" style="min-width: 400px" counter="50"></v-text-field>
                  </span>
                  <span v-else class="headline">{{ group.operatingUnitAllocationGroupName }}
                    <v-btn v-if="!isActionsDisabled" class="me-8" icon @click="setIsEditingGroupName(true, group)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn class="me-2" icon color="primary" v-if="showDeleteIcon" @click="promptAddFranchiseAssociationDialog(true, group)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn color="error" v-if="showDeleteIcon" medium icon @click="promptDeleteGroupConfirmationDialog(true, group)">
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-divider class="mt-n2 mb-3" />
                  <v-list-item-group>
                    <v-list-item v-for="(item, index) in group.franchiseOperatingUnitAllocationDtos" :key="index" :selectable="false" class="my-n2">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.franchiseName }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn color="error" v-if="showDeleteIcon" medium icon @click="promptDeleteAssociationConfirmationDialog(true, item)">
                          <v-icon>mdi-minus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-responsive>
      </v-card-text>
    </v-card>

    <app-confirmation-dialog
      v-if="isDeleteAssociationConfirmationDialogVisible"
      ref="settings-franchise-allocation-bucket-card-delete-association-confirmation-dialog"
      :is-dialog-visible="isDeleteAssociationConfirmationDialogVisible"
      :is-loading="getIsLoadingOperatingUnitAllocationGroups"
      :title="`Delete Association for ${franchiseToBeDeleted.franchiseNumber} - ${franchiseToBeDeleted.franchiseName}?`"
      :content-text="`You're attempting to delete an associated franchise. Are you sure you want to continue deleting?`"
      dialog-image="mdi-help"
      @closeDialog="promptDeleteAssociationConfirmationDialog(false, {})"
      @confirmed="deleteAssociatedFranchise"
    ></app-confirmation-dialog>

    <app-confirmation-dialog
      v-if="isDeleteGroupConfirmationDialogVisible"
      ref="settings-franchise-allocation-bucket-card-delete-association-confirmation-dialog"
      :is-dialog-visible="isDeleteGroupConfirmationDialogVisible"
      :is-loading="getIsLoadingOperatingUnitAllocationGroups"
      :title="`Delete Group ${groupToBeDeleted.operatingUnitAllocationGroupName}?`"
      :content-text="`You're attempting to delete an Operating Unit Allocation Group, '${groupToBeDeleted.operatingUnitAllocationGroupName}'. Are you sure you want to continue deleting?`"
      dialog-image="mdi-help"
      @closeDialog="promptDeleteGroupConfirmationDialog(false, {})"
      @confirmed="deleteGroup"
    ></app-confirmation-dialog>

    <settings-create-franchise-operating-unit-allocation-dialog
      v-if="isAddFranchiseAssociationDialogVisible"
      ref="settings-franchise-allocation-bucket-card-create-franchise-operating-unit-dialog"
      :is-dialog-visible="isAddFranchiseAssociationDialogVisible"
      :operating-unit-allocation-group-id="franchiseOperatingUnitAllocationGroupIdToAssociate"
      @closeDialog="promptAddFranchiseAssociationDialog(false, {})"
    ></settings-create-franchise-operating-unit-allocation-dialog>
  </v-container>
</template>

<script>
import AppConfirmationDialog from "@/components/AppConfirmationDialog.vue"
import SettingsCreateFranchiseOperatingUnitAllocationDialog from "@/components/SettingsCreateFranchiseOperatingUnitAllocationDialog.vue"
import { putOperatingUnitAllocationGroupDto } from "@/api/dtos/JunkDtos"
import BaseToolTip from "@/components/BaseToolTip.vue"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"

export default {
  name: "SettingsFranchiseAllocationBucketCard",
  components: { BaseToolTip, SettingsCreateFranchiseOperatingUnitAllocationDialog, AppConfirmationDialog },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isDeleteAssociationConfirmationDialogVisible: false,
      franchiseOperatingUnitAllocationGroupIdToAssociate: 0,
      isAddFranchiseAssociationDialogVisible: false,
      franchiseToBeDeleted: {},
      isDeleteGroupConfirmationDialogVisible: false,
      groupToBeDeleted: {},
      editedGroupId: 0,
      editedGroupNameInitial: "",
      editedGroupName: "",
      isEditingGroupName: false,
      nameRules: [value => (!!value && !!value.trim()) || "Name is required.", value => value.length <= 50 || "Name must be 50 characters or less."]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, [
      "fetchOperatingUnitAllocationGroups",
      "deleteOperatingUnitAllocationGroup",
      "updateOperatingUnitGroupName",
      "deleteFranchiseOperatingUnitAllocationGroup"
    ]),
    promptDeleteAssociationConfirmationDialog(isVisible, franchiseOperatingUnitAllocationDto) {
      this.isDeleteAssociationConfirmationDialogVisible = isVisible
      this.franchiseToBeDeleted = Object.assign({}, franchiseOperatingUnitAllocationDto)
    },
    async deleteAssociatedFranchise() {
      let franchiseOperatingUnitAllocationGroupId = this.franchiseToBeDeleted?.franchiseOperatingUnitAllocationGroupId

      if (franchiseOperatingUnitAllocationGroupId !== null && franchiseOperatingUnitAllocationGroupId !== undefined) {
        await this.deleteFranchiseOperatingUnitAllocationGroup(franchiseOperatingUnitAllocationGroupId).then(() => {
          this.promptDeleteAssociationConfirmationDialog(false, {})
        })
      }
    },
    promptAddFranchiseAssociationDialog(isVisible, group) {
      this.isAddFranchiseAssociationDialogVisible = isVisible
      this.franchiseOperatingUnitAllocationGroupIdToAssociate = group?.operatingUnitAllocationGroupId ?? 0
    },
    setIsEditingGroupName(isEditing, group) {
      this.editedGroupId = group?.operatingUnitAllocationGroupId ?? 0
      this.editedGroupNameInitial = group?.operatingUnitAllocationGroupName ?? ""
      this.editedGroupName = group?.operatingUnitAllocationGroupName ?? ""
      this.isEditingGroupName = isEditing
    },
    promptDeleteGroupConfirmationDialog(isVisible, group) {
      this.isDeleteGroupConfirmationDialogVisible = isVisible
      this.groupToBeDeleted = Object.assign({}, group)
    },
    async deleteGroup() {
      await this.deleteOperatingUnitAllocationGroup(this.groupToBeDeleted.operatingUnitAllocationGroupId).then(() => {
        this.promptDeleteGroupConfirmationDialog(false, {})
      })
    },
    async updateGroupName() {
      const dto = putOperatingUnitAllocationGroupDto(this.editedGroupId, this.editedGroupName)
      await this.updateOperatingUnitGroupName(dto).then(() => {
        this.setIsEditingGroupName(false, "")
      })
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, ["getOperatingUnitAllocationGroups", "getIsLoadingOperatingUnitAllocationGroups"]),
    showDeleteIcon() {
      return this.getOperatingUnitAllocationGroups.length > 1 && !this.isActionsDisabled
    }
  },
  async created() {
    await this.fetchOperatingUnitAllocationGroups(this.getSelectedOperatingUnitId)
  }
}
</script>

<style scoped></style>
