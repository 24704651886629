<template>
  <base-dialog
    ref="job-add-trucks-and-employees-dialog-root"
    title="Where would you like to go?"
    dialog-image="mdi-dump-truck"
    :is-dialog-visible="isDialogVisible"
    @close-dialog="closeDialog"
  >
    <template v-slot:content>
      <v-row>
        <v-col>
          <v-btn
            class="mb-4"
            ref="job-queue-address-picker-dialog-add-address-btn"
            color="primary"
            v-for="(address, index) in addresses"
            :class="`${getItemClass(address)}`"
            :key="index"
            @click="navigate(address)"
            outlined
            rounded
            dark
            large
          >
            <v-icon ref="job-queue-address-picker-item-icon" class="me-2">
              {{ getItemIcon(address) }}
            </v-icon>
            <div ref="job-queue-address-picker-item-address" class="text-md-body-1">
              {{ address.fullAddress }}
            </div>
            <v-icon right>
              mdi-navigation-variant-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" color="primaryText" ref="job-queue-address-picker-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { openMapLocation } from "@/utils/OpenLocationOnMap"

export default {
  name: "JobQueueAddressPickerDialog",
  components: { BaseDialog },
  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    isDialogVisible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    navigate(jobAddress) {
      openMapLocation(jobAddress)
    },
    getItemClass(address) {
      return address.isFranchiseLocation ? "ms-0" : "ms-8"
    },
    getItemIcon(address) {
      return address.isFranchiseLocation ? "mdi-blank" : "mdi-account"
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  }
}
</script>
