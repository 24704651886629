<template>
  <div>
    <app-off-canvas-blade :is-disabled="getIsLoadingJob" :is-notification-icon-visible="isNotificationIconVisible">
      <template v-slot:title>
        <span class="me-4">Documents</span>
      </template>
      <template v-slot:body>
        <v-card :loading="getIsLoadingJobDocuments">
          <v-card-title>Statement</v-card-title>
          <v-card-text>
            <v-row class="row-block">
              <v-col v-if="!isReceiptGenerated">
                <v-btn
                    v-if="!hasReceipt"
                    :disabled="isStartReceiptLoading"
                    :loading="isStartReceiptLoading"
                    class="btn-wide"
                    @click="startReceipt">
                  <v-icon class="me-2">mdi-file-document-plus-outline</v-icon>
                  Start Statement
                </v-btn>
                <v-btn
                    v-if="isResumeReceiptButtonVisible"
                    :disabled="isResumeReceiptLoading"
                    :loading="isResumeReceiptLoading"
                    class="btn-wide"
                    @click="resumeReceipt">
                  <v-icon class="me-2">mdi-file-document-edit-outline</v-icon>
                  Resume Statement
                </v-btn>
              </v-col>
              <v-col v-else>
                <v-row>
                  <v-col>
                    <v-btn :disabled="isDownloadReceiptDisabled" :href="getJobDocumentReceiptInfo.blobUrl" :loading="getIsLoadingJobDocuments">
                      Download Statement
                      <v-icon class="ms-2">mdi-cloud-download-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div>{{ getStepCompletionText }}</div>
                <div>
                  <app-meter :value="getProgressValue" :max="getProgressMax" />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </app-off-canvas-blade>

    <job-receipt-full-screen-dialog
      v-if="isJobReceiptFullScreenDialogVisible"
      :is-dialog-visible="isJobReceiptFullScreenDialogVisible"
      :progress="getProgressValue"
      @close-dialog="handleCloseDialog" />

  </div>
</template>

<script>
import AppOffCanvasBlade from "@/components/AppOffCanvasBlade"
import AppMeter from "@/components/AppMeter"
import JobReceiptFullScreenDialog from "@/components/JobReceiptFullScreenDialog"
import { createReceiptDto } from "@/api/dtos/DocumentDtos"
import { mapState, mapActions } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobOffCanvasBlade",
  components: { AppOffCanvasBlade, AppMeter, JobReceiptFullScreenDialog },
  data() {
    return {
      isJobReceiptFullScreenDialogVisible: false,
      isLoadingStartReceipt: false,
      isLoadingResumeReceipt: false
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getCurrentJob",
      "getIsJobStatusClaimed",
      "getJobDocumentReceiptPhrases",
      "getJobDocumentReceiptInfo",
      "getIsLoadingJob",
      "getIsLoadingJobDocuments",
    ]),
    isNotificationIconVisible() {
      return this.getIsJobStatusClaimed && !this.isReceiptGenerated && !this.getIsLoadingJobDocuments
    },
    isResumeReceiptButtonVisible() {
      return this.hasReceipt && !this.isReceiptGenerated
    },
    getStepCompletionText() {
      let signaturePhrases = this.getJobDocumentReceiptPhrases.filter(phrase => phrase.isIntermission !== true)
      let signedPhrases = signaturePhrases.filter(phrase => (phrase.customerRepresentativeId !== null || phrase.employeeProfileId !== null) && phrase.isSavedToServer)
      if (this.isReceiptGenerated) {
        return "Complete"
      }
      if (this.hasReceipt && signedPhrases.length === 0) {
        return `Completed ${signedPhrases.length} of ${signaturePhrases.length}`
      }
      if (this.hasReceipt) {
        return `Completed ${signedPhrases.length} of ${signaturePhrases.length}`
      }
      return "Not Started"
    },
    getProgressValue() {
      if (this.isReceiptGenerated) {
        return this.getProgressMax
      } else {
        return (
          this.getJobDocumentReceiptPhrases.filter(
            phrase => (phrase.customerRepresentativeId !== null || phrase.employeeProfileId !== null) && phrase.isSavedToServer && phrase.isIntermission !== true
          ).length ?? 0
        )
      }
    },
    getProgressMax() {
      return this.getJobDocumentReceiptPhrases.filter(phrase => phrase.isIntermission !== true).length
    },
    hasReceipt() {
      return Object.keys(this.getJobDocumentReceiptInfo).length !== 0
    },
    isReceiptGenerated() {
      return this.getJobDocumentReceiptInfo.isGenerated
    },
    isDownloadReceiptDisabled() {
      return this.getIsLoadingJobDocuments || this.getJobDocumentReceiptInfo.blobUrl === null || this.getJobDocumentReceiptInfo.blobUrl === ""
    },
    isStartReceiptLoading() {
      return this.isLoadingStartReceipt || this.getIsLoadingJobDocuments
    },
    isResumeReceiptLoading() {
      return this.isLoadingResumeReceipt || this.getIsLoadingJobDocuments
    }
  },
  methods: {
    ...mapActions(useJobStore, [
      "createJobReceipt",
      "fetchJobDocumentCustomerRepresentativeSignatureProfilesByJobId",
      "fetchJobDocumentEmployeeProfilesFromMarkedPhrases",
      "fetchJobDocumentEmployeeProfileByEmployeeId",
      "fetchJobReceiptPayments",
      "fetchTipConfigurationByFranchiseId"
    ]),
    async startReceipt() {
      this.isLoadingStartReceipt = true
      const jobReceiptDto = createReceiptDto(this.getCurrentJob.id, this.getCurrentJob.franchiseId, this.$msal.getCurrentUserId())

      await Promise.allSettled([
        this.createJobReceipt(jobReceiptDto),
        this.fetchJobDocumentEmployeeProfileByEmployeeId({
          employeeId: this.$msal.getCurrentUserId(),
          firstName: this.$msal.getCurrentUserGivenName(),
          lastName: this.$msal.getCurrentUserFamilyName()
        }),
        this.fetchTipConfigurationByFranchiseId(this.getCurrentJob.franchiseId)
      ])
        .then(results => {
          if (results.every(result => result.status === "fulfilled")) {
            this.setIsJobReceiptFullScreenDialogVisible(true)
          }
        })
        .finally(() => {
          this.isLoadingStartReceipt = false
        })
    },
    async resumeReceipt() {
      this.isLoadingResumeReceipt = true

      await Promise.allSettled([
        this.fetchJobDocumentCustomerRepresentativeSignatureProfilesByJobId(this.getCurrentJob.id),
        this.fetchJobDocumentEmployeeProfilesFromMarkedPhrases(),
        this.fetchJobDocumentEmployeeProfileByEmployeeId({
          employeeId: this.$msal.getCurrentUserId(),
          firstName: this.$msal.getCurrentUserGivenName(),
          lastName: this.$msal.getCurrentUserFamilyName()
        }),
        this.fetchTipConfigurationByFranchiseId(this.getCurrentJob.franchiseId),
        this.fetchJobReceiptPayments(this.getJobDocumentReceiptInfo.jobFranchiseDocumentId)
      ])
        .then(results => {
          if (results.every(result => result.status === "fulfilled")) {
            this.setIsJobReceiptFullScreenDialogVisible(true)
          }
        })
        .then(() => {
          this.isLoadingResumeReceipt = false
        })
    },
    setIsJobReceiptFullScreenDialogVisible(isVisible) {
      this.isJobReceiptFullScreenDialogVisible = isVisible
    },
    handleCloseDialog() {
      this.setIsJobReceiptFullScreenDialogVisible(false)
    }
  }
}
</script>

<style>
.btn-wide {
  width: 100%;
}
.row-block {
  display: block;
}
</style>
