<template>
  <base-dialog ref="app-create-contact-dialog-root" title="Create Contact" :is-dialog-visible="isDialogVisible" dialog-image="mdi-account-plus-outline">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="app-create-contact-dialog-form">
        <v-row dense>
          <v-col>
            <v-text-field ref="app-create-contact-dialog-first-name-text" autocomplete="null" label="First Name *" v-model="firstName" outlined :rules="isRequiredRules" counter="50"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-text-field ref="app-create-contact-dialog-last-name-text" autocomplete="null" label="Last Name *" v-model="lastName" outlined :rules="isRequiredRules" counter="50"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-phone-input class="d-flex" ref="app-create-contact-dialog-phone-primary-text" label="Primary Phone Number *" autocomplete="null" default-country="USA" v-model="phonePrimary" :rules="isRequiredRules" validate-on-blur outlined />
          </v-col>
          <v-col cols="3">
            <v-text-field ref="app-create-contact-dialog-phone-primary-ext-text" label="Extension" v-model="phonePrimaryExtension" type="number" outlined counter="6"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-phone-input class="d-flex" ref="app-create-contact-dialog-phone-secondary-text" label="Secondary Phone Number" autocomplete="null" default-country="USA" v-model="phoneSecondary" validate-on-blur outlined />
          </v-col>
          <v-col cols="3">
            <v-text-field ref="app-create-contact-dialog-phone-secondary-ext-text" label="Extension" v-model="phoneSecondaryExtension" outlined counter="6"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <div v-if="refusedToProvideEmail" class="mt-n2 ps-1 pb-2" v-html="franchiseEmailUsedMessage" style="color: var(--v-primary-base)" />
          <v-col class="flex-column">
            <v-text-field ref="app-create-contact-dialog-email-text" label="Email Address *" :disabled="refusedToProvideEmail" v-model="email" :rules="emailRules" autocomplete="null" outlined counter="50" validate-on-blur></v-text-field>
          </v-col>
          <v-col cols="3" class="flex-column">
            <v-checkbox class="align-center mt-1" label="Refused to Provide" :false-value="false" :true-value="true" :value="refusedToProvideEmail" @change="setIsEmailOptedOut($event)"></v-checkbox>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <v-autocomplete ref="app-create-contact-dialog-preferred-contact-method-selector" label="Preferred Method of Contact *" v-model="preferredMethodOfContactId" :items="getPreferredMethodOfContactsFiltered" item-value="id" item-text="name" :rules="preferredMethodOfContactRules" outlined />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="app-create-contact-dialog-cancel-btn" color="primaryText" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="app-create-contact-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { createCustomerContactDto } from "@/api/dtos/JunkDtos"
import { PreferredMethodOfContact } from "@/enums/PreferredMethodOfContacts"
import { useMainStore } from "@/stores/Main"
import { mapActions, mapState } from "pinia"

export default {
  name: "AppCreateContactDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    customerId: {
      type: Number,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    franchiseId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      businessName: undefined,
      phonePrimary: "",
      phonePrimaryExtension: "",
      phoneSecondary: "",
      phoneSecondaryExtension: "",
      preferredMethodOfContactId: 1,
      refusedToProvideEmail: false,
      email: "",
      selectedCustomerType: 0,
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      emailRules: [v => /.+@.+\..+/.test((v ?? "").trim()) || "E-mail must be valid"],
      preferredMethodOfContactRules: [value => !(value === 2 && (this.email || "").length <= 0) || "Please provide an email address or select a different method of contact"]
    }
  },
  methods: {
    ...mapActions(useMainStore, ["getFranchiseEmailByFranchiseId"]),
    async save() {
      if (this.$refs["app-create-contact-dialog-form"].validate()) {
        const dto = createCustomerContactDto(
          this.firstName,
          this.lastName,
          this.phonePrimary,
          this.phonePrimaryExtension,
          this.phoneSecondary,
          this.phoneSecondaryExtension,
          this.customerId,
          false,
          false,
          this.email,
          this.$msal.getCurrentUserId(),
          this.preferredMethodOfContactId,
          this.refusedToProvideEmail
        )

        this.$emit("confirm", dto)
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setIsEmailOptedOut(isOptedOut) {
      this.refusedToProvideEmail = isOptedOut
      if (isOptedOut) {
        this.setEmailToSelectedFranchise(this.franchiseId)
      } else {
        this.email = ""
      }
    },
    setEmailToSelectedFranchise(franchiseId) {
      let franchiseEmailAddress = this.getFranchiseEmailByFranchiseId(franchiseId)
      this.email = franchiseEmailAddress !== undefined ? franchiseEmailAddress : ""
    }
  },
  computed: {
    ...mapState(useMainStore, ["getPreferredMethodOfContacts"]),
    getPreferredMethodOfContactsFiltered() {
      let preferredMethodOfContacts = JSON.parse(JSON.stringify(this.getPreferredMethodOfContacts))
      preferredMethodOfContacts = preferredMethodOfContacts.filter(contactMethod => contactMethod.id !== PreferredMethodOfContact.TEXT.id)

      if (this.refusedToProvideEmail) {
        preferredMethodOfContacts = preferredMethodOfContacts.filter(contactMethod => contactMethod.id !== PreferredMethodOfContact.EMAIL.id)
      }
      return preferredMethodOfContacts
    },
    franchiseEmailUsedMessage() {
      return "* By selecting <em>Refused to Provide</em>, the franchise's email address will be stored for this customer instead. The franchise will be responsible for passing along any communications that would've been emailed to the customer."
    }
  }
}
</script>

<style scoped></style>
