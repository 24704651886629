<template>
  <base-dialog ref="settings-edit-franchise-tax-dialog-root" title="End Date Tax?" :is-dialog-visible="isDialogVisible" dialog-image="mdi-cash-minus">
    <template class="flex-fill" v-slot:content>
      <v-layout class="mx-0 px-0 mb-2" row>
        <span class="text-md-body-1 ms-n2 pa-0">You are about to end date tax record <em><strong>{{ franchiseTax.taxName }}</strong></em> for the following franchises:</span>
      </v-layout>
      <v-layout class="ms-1" row v-for="franchiseNumberAndName in franchiseNumberAndNames" :key="franchiseNumberAndName">
        <v-icon>mdi-circle-medium</v-icon>
        <span>{{ franchiseNumberAndName }}</span>
      </v-layout>
      <v-layout row class="pt-4">
        <app-date-picker-field-selector
          ref="settings-edit-franchise-tax-dialog-end-date-selector"
          :minimum-date-value="minimumDate"
          :date-picker-value="endDate"
          @updatedPickerValue="setEndDate($event)"
          text-field-label="End Date"
          :text-field-disabled="false"
        />
      </v-layout>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" color="primaryText" ref="settings-edit-franchise-tax-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" color="primary" ref="settings-edit-franchise-tax-dialog-confirm-btn" @click="confirm" :loading="false" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { getIsFirstDateOnOrBeforeSecondDate, todayAsDate } from "@/utils/DateTimeFormatters"
import { putFranchiseTaxEndDateDto } from "@/api/dtos/JunkDtos"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions } from "pinia"

export default {
  name: "SettingsEndDateFranchiseTaxDialog",
  components: { AppDatePickerFieldSelector, BaseDialog },
  props: {
    franchiseTax: Object,
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      endDate: todayAsDate()
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["endDateFranchiseTax"]),
    setEndDate(event) {
      this.endDate = event
    },
    async confirm() {
      const franchiseTaxId = this.franchiseTax.franchises[0].franchiseTaxId
      const dto = putFranchiseTaxEndDateDto(franchiseTaxId, this.endDate)
      await this.endDateFranchiseTax(dto).then(() => this.closeDialog())
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    franchiseNumberAndNames() {
      return this.franchiseTax?.franchises?.map(franchise => franchise.franchiseNumberAndName) ?? []
    },
    minimumDate() {
      let mostRecentBeginDate = this.franchiseTax?.franchises?.map(x => x?.beginDate)?.slice()?.sort((a, b) => b?.localeCompare(a))[0] ?? todayAsDate()
      return getIsFirstDateOnOrBeforeSecondDate(mostRecentBeginDate, todayAsDate()) ? todayAsDate() : mostRecentBeginDate
    }
  }
}
</script>

<style scoped></style>
