<template>
  <v-card elevation="0" class="pb-4" ref="job-queue-slot-card-root">
    <v-card-text>
      <v-row>
        <v-responsive class="overflow-y-auto" max-height="550" min-height="200">
          <v-data-table
            class="flex-fill row-pointer"
            ref="job-queue-slot-card-data-table"
            loading-text="🔎 Fetching Jobs 🔍"
            :headers="getJobQueueHeaders"
            :loading="isLoading"
            :items="getFilteredJobQueue"
            :sort-by="['preferredTimeSlotId', 'jobDesiredTimeFrames', 'totalDistance']"
            @click:row="emitClaimJobEvent"
            mobile-breakpoint="840"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found title="No Results" content="No Jobs Are Currently Scheduled for the Selected Date.">
                  <template v-slot:actions><div /></template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.jobNumber="{ item }">
              <span>
                <router-link ref="job-queue-slot-card-router" class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">
                  {{ item.jobNumber }}
                </router-link>
                <v-icon ref="job-queue-slot-card-pin-icon" color="primary" @click.stop="addNewJobTab(item)" class="ms-2">
                  mdi-pin-outline
                </v-icon>
                <span ref="job-queue-slot-card-self-scheduled" v-if="checkIsSelfScheduled(item)" class="ms-2">
                  <base-tool-tip
                    ref="job-queue-self-scheduled-tool-tip"
                    tooltipColor="blue"
                    icon-color="blue"
                    dialog-title="Customer Self-Scheduled"
                    nudge-dialog="0"
                    tooltip-icon="mdi-account-tag-outline"
                    :is-bottom-dialog="true"
                  />
                </span>
              </span>
            </template>
            <template v-slot:item.jobDesiredTimeFrames="{ item }">
              <span ref="job-queue-slot-card-customer-and-address" class="text-md-body-1" style="white-space: pre-line">
                <div v-for="(frame, index) in item.jobDesiredTimeFrames" :key="index">
                  <v-chip class="elevation-0" :key="frame.desiredTimeFrameId" ripple>{{ `${frame.formattedWindowStartTime} - ${frame.formattedWindowEndTime}` }}</v-chip>
                </div>
              </span>
            </template>
            <template v-slot:item.customerAndAddress="{ item }">
              <span ref="job-queue-slot-card-customer-and-address" class="text-md-body-1" style="white-space: pre-line">
                {{ customerAndAddress(item) }}
              </span>
              <base-tool-tip
                v-if="true"
                ref="settings-franchise-time-slots-card-edit-tool-tip"
                tooltipColor="red"
                icon-color="red"
                nudge-dialog="0"
                :dialog-title="getCustomerAddressToolTipTitle(item)"
                :tooltip-icon="getAdditionalAddressesIcon(item)"
                :is-bottom-dialog="true"
              />
            </template>
            <template v-slot:item.jobAddresses="{ item }">
              <v-list dense class="transparent">
                <v-list-item-group class="transparent">
                  <v-list-item ref="job-queue-slot-card-item-job-address" class="transparent" v-for="(address, index) in item.jobAddresses" :key="index">
                    <v-list-item-content>
                      <div ref="job-queue-slot-card-item-content-job-address" class="text-md-body-1 primaryText--text" disabled>{{ address.fullAddress }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
            <template v-slot:item.capacityName="{ item }">
              <span ref="job-queue-slot-card-item-capacity" class="text-md-body-1">{{ item.capacityName }}</span>
            </template>
            <template v-slot:item.preferredTimeSlotId="{ item }">
              <v-row class="justify-center text-center align-center" dense>
                <v-col ref="job-queue-slot-card-item-time-slot" v-if="isPreferredTimeSlotTextVisible" cols="auto" class="d-flex">
                  <div ref="job-queue-slot-card-item-content-time-slot">{{ item.preferredTimeSlotName }}</div>
                </v-col>
                <v-col cols="auto" class="d-flex mt-n1">
                  <v-icon ref="job-queue-slot-card-preferred-time-icon">
                    {{ getPreferredTimeSlotIcon(item.preferredTimeSlotId) }}
                  </v-icon>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.statusName="{ item }">
              <v-chip ref="job-queue-slot-card-status-chip" :color="determineBackgroundColor(item.statusName)" :text-color="determineBackgroundColor(item.statusName)" outlined>
                {{ item.statusName }}
              </v-chip>
            </template>
            <template v-slot:item.claimedBy="{ item }">
              <v-list dense class="transparent">
                <v-list-item-group class="transparent">
                  <v-list-item ref="job-queue-slot-card-item-trucks" class="transparent" v-for="(truck, index) in item.jobTrucks" :key="index" inactive disabled>
                    <v-list-item-content ref="job-queue-slot-card-item-truck-name" class="text-md-body-1 primaryText--text">{{ truck.name }}</v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
            <template v-slot:item.totalDistance="{ item }">
              <span ref="job-queue-slot-card-item-distance" class="text-md-body-1">{{ formatDistanceFromUser(item.totalDistance) }}</span>
            </template>
          </v-data-table>
        </v-responsive>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AppNotFound from "@/components/AppNotFound"
import BaseToolTip from "@/components/BaseToolTip"
import { JobStatus } from "@/enums/JobStatus"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import { PreferredTimeSlot } from "@/enums/PreferredTimeSlot"
import { useMainStore } from "@/stores/Main"
import { useJobQueueStore } from "@/stores/JobQueue"
import { mapActions, mapState } from "pinia"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import { JobTab } from "@/models/JobTab"

export default {
  name: "JobQueueSlotCard",
  components: { AppNotFound, BaseToolTip },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useJobQueueStore, ["getFilteredJobQueue"]),
    isPreferredTimeSlotTextVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    getJobQueueHeaders() {
      return [
        { text: "Job Number", align: "start", value: "jobNumber" },
        { text: "Customer & Address", align: "start", value: "customerAndAddress" },
        { text: "Distance From Me", align: "start", value: "totalDistance" },
        { text: "Capacity", align: "start", value: "capacityName" },
        { text: "Preferred Time Slot", align: "center", value: "preferredTimeSlotId" },
        { text: "Status", align: "center", value: "statusName" },
        { text: "Time Frame", align: "center", value: "jobDesiredTimeFrames", sortable: true, sort: (a, b) => this.sortTimeFrame(a, b) },
        { text: "Claimed By", align: "center", value: "claimedBy" }
      ]
    }
  },
  methods: {
    ...mapActions(useMainStore, ["addJobTab"]),
    sortTimeFrame(first, second) {
      const timeA = new Date(`1970-01-01T${first[0]?.windowStartTime ?? '23:59:59'}`).getTime()
      const timeB = new Date(`1970-01-01T${second[0]?.windowStartTime ?? '23:59:59'}`).getTime()
      return timeA - timeB
    },
    async addNewJobTab(item) {
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, item.customerLastName, item.businessName)
      await this.addJobTab(tab)
    },
    checkIsSelfScheduled(item) {
      return item.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id
    },
    emitClaimJobEvent(job) {
      if (job.statusName === JobStatus.SCHEDULED.name) this.$emit("claimJob", job)
    },
    customerAndAddress(item) {
      let address = item.jobAddresses.find(ja => !ja.isFranchiseLocation)
      if (address) {
        return `${item.customerName}\n${address?.fullAddress ?? ""}`
      }
      return item.customerName
    },
    getCustomerAddressToolTipTitle(item) {
      return `${this.getNumberOfAdditionalAddresses(item)} Additional customer address(es)`
    },
    getPreferredTimeSlotIcon(preferredTimeSlotId) {
      switch (preferredTimeSlotId) {
        case PreferredTimeSlot.FIRST.id:
          return `mdi-numeric-1-circle`
        case PreferredTimeSlot.AM.id:
          return `mdi-sun-angle`
        case PreferredTimeSlot.PM.id:
          return `mdi-weather-night`
        case PreferredTimeSlot.NONE.id:
          return `mdi-theme-light-dark`
        default:
          return `mdi-blank`
      }
    },
    getAdditionalAddressesIcon(item) {
      let count = this.getNumberOfAdditionalAddresses(item)
      if (count) {
        return `mdi-numeric-${count}-circle`
      }
    },
    getNumberOfAdditionalAddresses(item) {
      let length = item.jobAddresses.slice().filter(ja => !ja.isFranchiseLocation).length - 1
      if (length > 0) {
        return length
      }
    },
    determineBackgroundColor(statusName) {
      return getJobStatusColorByStatusName(statusName)
    },
    formatDistanceFromUser(distance) {
      return distance >= 0 ? `${distance} Miles` : "No Address"
    }
  }
}
</script>
