<template>
  <base-dialog ref="settings-add-franchise-national-discount-dialog-root" :title="`Associate Franchises to ${nationalDiscount.name}`" :is-dialog-visible="isDialogVisible" dialog-image="mdi-cash-plus">
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-add-franchise-national-discount-dialog-form" v-model="isFormValid">
        <v-layout row>
          <v-select
            ref="settings-add-franchise-national-discount-dialog-franchise-selector"
            label="Selected Franchises"
            v-model="selectedFranchiseIds"
            item-text="franchiseNameAndNumber"
            item-value="franchiseId"
            multiple
            outlined
            menu-props="{ bottom: true, offsetY: true }"
            :items="getFranchisesInOperatingUnitNotAssociatedToNationalDiscountRecord"
            :rules="isRequiredSelectorRules"
          />
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-add-franchise-national-discount-dialog-cancel-button" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="settings-add-franchise-national-discount-dialog-confirm-button" color="primary" @click="confirm" :loading="getIsLoadingDiscounts" :disabled="getIsLoadingDiscounts" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapState } from "pinia"
import { useSettingsStore } from "@/stores/Settings"
import { createNationalDiscountElectedFranchisesDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsAddFranchiseNationalDiscountDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    nationalDiscount: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isFormValid: false,
      selectedFranchiseIds: [],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["associateFranchisesToNationalDiscount", "fetchElectedDiscountsForWebsiteByFranchiseId"]),
    async confirm() {
      if (this.$refs["settings-add-franchise-national-discount-dialog-form"].validate()) {
        const dto = createNationalDiscountElectedFranchisesDto(this.nationalDiscount.nationalDiscountId, this.selectedFranchiseIds, this.$msal.getCurrentUserId())
        await this.associateFranchisesToNationalDiscount(dto).then(async () => {
          await this.fetchElectedDiscountsForWebsite()
          this.closeDialog()
        })
      }
    },
    async fetchElectedDiscountsForWebsite() {
      if (this.selectedFranchiseIds?.includes(this.getSelectedFranchise)) {
        this.fetchElectedDiscountsForWebsiteByFranchiseId(this.getSelectedFranchise)
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getFranchisesInOperatingUnit", "getIsLoadingDiscounts", "getActiveAndFutureNationalDiscounts", "getSelectedFranchise"]),
    getFranchisesInOperatingUnitNotAssociatedToNationalDiscountRecord() {
      let matchingRecord = this.getActiveAndFutureNationalDiscounts?.find(f => f.nationalDiscountId === this.nationalDiscount.nationalDiscountId)
      return this.getFranchisesInOperatingUnit?.slice().filter(fou => !matchingRecord.franchiseElectedDiscounts?.some(f => fou.franchiseId === f.franchiseId))
    }
  }
}
</script>
