<template>
  <v-card class="elevation-4 mb-0 pb-0" :loading="getIsLoadingJob">
    <v-stepper v-if="isWebLayoutVisible" class="flex-fill elevation-0 transparent" ref="app-status-stepper-web-layout">
      <v-stepper-header>
        <template v-for="(jobStatus, i) in buildFlow">
          <v-stepper-step color="primary" :key="`${jobStatus.jobStatusId}-${jobStatus.jobJobStatusId}-step`" :complete="jobStatus.isComplete" :step="i + 1">
            {{ jobStatus.jobStatusName }}
          </v-stepper-step>
          <v-divider v-if="i !== buildFlow.length - 1" :key="`${jobStatus.jobStatusId}-${jobStatus.jobJobStatusId}-divider`"></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
    <v-stepper v-else class="flex-fill elevation-0 transparent pb-1" vertical min-height="100px" ref="app-status-stepper-mobile-layout">
      <template v-for="(jobStatus, i) in buildFlow">
        <v-stepper-step class="mb-1" color="primary" :key="`${jobStatus.jobStatusId}-${jobStatus.jobJobStatusId}-step`" :complete="jobStatus.isComplete" :step="i + 1">
          {{ jobStatus.jobStatusName }}
        </v-stepper-step>
        <v-divider v-if="i !== buildFlow.length - 1" :key="`${jobStatus.jobStatusId}-${jobStatus.jobJobStatusId}-divider`"></v-divider>
      </template>
    </v-stepper>
  </v-card>
</template>

<script>
import { mapState } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "AppStatusStepperCard",
  computed: {
    ...mapState(useJobStore, ["getIsLoadingJob", "getJobCompletionStatuses"]),
    isWebLayoutVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    buildFlow() {
      const isAbandoned = this.getJobCompletionStatuses.slice().some(jobCompletionStatus => jobCompletionStatus.isComplete === true && jobCompletionStatus.jobStatusName === "Abandoned")
      const isCancelled = this.getJobCompletionStatuses.slice().some(jobCompletionStatus => jobCompletionStatus.isComplete === true && jobCompletionStatus.jobStatusName === "Cancelled")
      const isClosed = this.getJobCompletionStatuses.slice().some(jobCompletionStatus => jobCompletionStatus.isComplete === true && jobCompletionStatus.jobStatusName === "Closed")

      if (isAbandoned) {
        return this.getJobCompletionStatuses.slice().filter(jobCompletionStatus => jobCompletionStatus.jobStatusName === "Created" || jobCompletionStatus.jobStatusName === "Abandoned")
      } else if (isCancelled || isClosed) {
        return this.getJobCompletionStatuses.slice().filter(jobCompletionStatus => jobCompletionStatus.isComplete === true)
      } else {
        return this.getJobCompletionStatuses
          .slice()
          .filter(
            jobCompletionStatus =>
              jobCompletionStatus.jobStatusName === "Created" ||
              jobCompletionStatus.jobStatusName === "Scheduled" ||
              jobCompletionStatus.jobStatusName === "Claimed" ||
              jobCompletionStatus.jobStatusName === "Completed" ||
              jobCompletionStatus.jobStatusName === "Closed"
          )
      }
    }
  }
}
</script>
