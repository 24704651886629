<template>
  <v-container fluid>
    <base-dialog ref="job-add-trucks-and-employees-dialog-root" :is-dialog-visible="isDialogVisible" title="Add Trucks and Employees" dialog-image="mdi-calendar-check" @close-dialog="closeDialog">
      <template v-slot:content>
        <v-form ref="job-add-trucks-and-employees-dialog-form" v-model="isAddTrucksAndEmployeesFormValid">
          <v-row dense>
            <v-autocomplete
              v-model="selectedTrucks"
              ref="job-add-trucks-and-employees-dialog-selected-trucks"
              :items="getActiveTrucksNotOnJob"
              label="Selected Trucks"
              item-value="junkTruckId"
              no-data-text="No Trucks Available"
              :item-text="formatTruckName"
              :disabled="getIsLoadingTrucksAndEmployees"
              outlined
              multiple
              chips
              flat
              solo
              clearable
              deletable-chips
            ></v-autocomplete>
          </v-row>
          <v-row dense>
            <v-autocomplete
              v-model="selectedEmployees"
              ref="job-add-trucks-and-employees-dialog-selected-employees"
              :items="getSortedEmployeesNotOnJob"
              label="Selected Employees"
              item-value="id"
              item-text="displayName"
              no-data-text="No Employees Available"
              :disabled="getIsLoadingTrucksAndEmployees"
              outlined
              flat
              solo
              clearable
              multiple
              chips
              deletable-chips
            ></v-autocomplete>
          </v-row>
          <v-row v-if="isTruckSelectionPopulated" dense class="my-n4 mx-n5">
            <v-col>
              <app-time-picker-menu
                ref="job-add-trucks-and-employees-dialog-start-time-picker"
                text-field-label="Start Time"
                :time-value="startTime"
                @updateTimeValue="setStartTime($event)"
                :allowed-minutes="allowedMinutes"
                :max-value="endTime"
                :validation-rules="timeEntryValidationRules"
                :is-disabled="getIsLoadingTrucksAndEmployees"
              ></app-time-picker-menu>
            </v-col>
            <v-col>
              <app-time-picker-menu
                ref="job-add-trucks-and-employees-dialog-end-time-picker"
                text-field-label="End Time"
                :time-value="endTime"
                @updateTimeValue="setEndTime($event)"
                :allowed-minutes="allowedMinutes"
                :min-value="startTime"
                :validation-rules="timeEntryValidationRules"
                :is-disabled="getIsLoadingTrucksAndEmployees"
              ></app-time-picker-menu>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" color="primaryText" ref="job-add-trucks-and-employees-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn class="pa-4" color="primary" ref="job-add-trucks-and-employees-dialog-confirm-btn" :loading="getIsLoadingTrucksAndEmployees" @click="save" :disabled="isSaveButtonDisabled" text rounded ripple>Confirm</v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppTimePickerMenu from "@/components/AppTimePickerMenu"
import { createJobEmployeeDto, createJobTruckDto } from "@/api/dtos/JunkDtos"
import { formatTimeAsDateUtc } from "@/utils/DateTimeFormatters"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobAddTrucksAndEmployeesDialog",
  components: { BaseDialog, AppTimePickerMenu },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startTime: undefined,
      endTime: undefined,
      selectedEmployees: [],
      selectedTrucks: [],
      isAddTrucksAndEmployeesFormValid: false,
      timeEntryValidationRules: [value => !!value || "Time is required."]
    }
  },
  methods: {
    ...mapActions(useJobStore, ["createJunkJobEmployees", "createJunkJobTrucks"]),
    allowedMinutes: m => m % 15 === 0,
    async save() {
      if (this.$refs["job-add-trucks-and-employees-dialog-form"].validate()) {
        const startDateTime = this.startTime ? formatTimeAsDateUtc(this.getCurrentJobScheduledDateOrToday, this.startTime) : null
        const endDateTime = this.endTime ? formatTimeAsDateUtc(this.getCurrentJobScheduledDateOrToday, this.endTime) : null

        if (this.isEmployeeSelectionPopulated && this.isTruckSelectionPopulated) {
          await this.saveEmployeeSelection()
            .then(() => {
              return this.saveTruckSelection(startDateTime, endDateTime)
            })
            .then(() => this.closeDialog())
        } else if (this.isEmployeeSelectionPopulated) {
          await this.saveEmployeeSelection().then(() => this.closeDialog())
        } else if (this.isTruckSelectionPopulated) {
          await this.saveTruckSelection(startDateTime, endDateTime).then(() => this.closeDialog())
        }
      }
    },
    async saveTruckSelection(startDateTime, endDateTime) {
      const createJobTruckDtos = this.generateCreateJobTruckDtos(startDateTime, endDateTime)
      return await this.createJunkJobTrucks({ jobId: this.getCurrentJob.id, createJobTruckDto: createJobTruckDtos })
    },
    async saveEmployeeSelection() {
      const createJobEmployeeDtos = this.selectedEmployees.slice().map(selectedEmployeeId => createJobEmployeeDto(this.getCurrentJob.id, selectedEmployeeId, this.$msal.getCurrentUserId()))
      return await this.createJunkJobEmployees({ jobId: this.getCurrentJob.id, createJobEmployeeDto: createJobEmployeeDtos })
    },
    generateCreateJobTruckDtos(startDateTime, endDateTime) {
      return this.selectedTrucks.slice().map(selectedTruckId => createJobTruckDto(this.getCurrentJob.id, selectedTruckId, startDateTime, endDateTime, this.$msal.getCurrentUserId()))
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    formatEmployeeName(item) {
      return `${item.lastName}, ${item.firstName}`
    },
    formatTruckName(item) {
      return item.isUnavailable ? `${item.name} (unavailable)` : `${item.name}`
    },
    setStartTime(timeEvent) {
      this.startTime = timeEvent
    },
    setEndTime(timeEvent) {
      this.endTime = timeEvent
    }
  },
  computed: {
    ...mapState(useJobStore, ["getIsLoadingTrucksAndEmployees", "getCurrentJob", "getCurrentJobScheduledDateOrToday", "getActiveTrucksNotOnJob", "getActiveEmployeesNotOnJob"]),
    isSaveButtonDisabled() {
      return this.getIsLoadingTrucksAndEmployees || (this.selectedTrucks.length === 0 && this.selectedEmployees.length === 0)
    },
    isTruckSelectionPopulated() {
      return this.selectedTrucks.length > 0
    },
    isEmployeeSelectionPopulated() {
      return this.selectedEmployees.length > 0
    },
    getSortedEmployeesNotOnJob() {
      return this.getActiveEmployeesNotOnJob.slice().sort((a, b) => a.givenName.localeCompare(b.givenName))
    }
  }
}
</script>
