<template>
  <v-card id="truck-utilization-rates-card" class="elevation-4 pb-1" data-cy="truck-utilization-rates-card">
    <v-card-title id="truck-utilization-rates-card-title">
      <span class="headline word-wrap">Truck Utilization Rates</span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        id="truck-utilization-rates-table"
        class="flex-fill"
        style="min-height: 350px; min-width: 350px"
        :headers="truckUtilizationHeaders"
        :items="getAllActiveFranchiseCapacities"
        sort-by="capacityId"
        :loading="getTruckUtilizationRatesLoadingState"
        loading-text="🔎 Fetching Utilization Rates 🔍"
        no-results-text="Could Not Find Any Utilization Rates"
        no-data-text="No Data Available"
        mobile-breakpoint="840"
        show-expand
        single-expand
        fixed-header
        hide-default-footer
        disable-pagination
        data-cy="truck-utilization-rates-table"
      >
        <template v-slot:no-data>
          <v-col class="ma-0 pa-0 align-self-center d-flex">
            <app-not-found title="Ope! No available records :(" content="Looks like you don't have any records, please select a franchise.">
              <template v-slot:actions>
                <v-btn
                  ref="settings-truck-utilization-rates-card-franchise-selector-btn"
                  class="mt-4 primary justify-center align-self-center"
                  @click="$emit('selectFranchiseSelector')"
                  ripple
                  rounded
                  large
                  >Select Franchise</v-btn
                >
              </template>
            </app-not-found>
          </v-col>
        </template>
        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <td v-if="shouldDisplayExpandableIcon(item)" class="text-start">
            <v-btn icon @click="expand(!isExpanded)" class="v-data-table__expand-icon" :class="{ 'v-data-table__expand-icon--active': isExpanded }">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="pa-0" :colspan="headers.length">
            <v-responsive class="overflow-y-auto" max-height="310">
              <v-data-table
                :headers="truckUtilizationSubHeaders"
                :items="filterPastAndFutureUtilizationRatesByCapacityId(item)"
                sort-by="beginDateTime"
                :sort-desc="true"
                style="width: 80vw"
                :item-class="itemRowBackground"
                no-data-text="There are no past or future rates associated to this utilization amount."
                :dark="!$vuetify.breakpoint.mobile"
                mobile-breakpoint="840"
                disable-pagination
                fixed-header
                hide-default-footer
                data-cy="truck-utilization-rates-subtable"
              >
                <template v-slot:item.rate="{ value }">
                  <app-number-formatter class="text-md-body-1 grey--text" :amount="value" currency-sign="accounting" number-format-style="currency"></app-number-formatter>
                </template>
                <template v-slot:item.beginDateTime="{ item }">
                  <app-date-time-locale-formatter class="text-md-body-1 grey--text" :date-time-value="item.beginDateTime"></app-date-time-locale-formatter>
                </template>
                <template v-slot:item.endDateTime="{ item }">
                  <app-date-time-locale-formatter class="text-md-body-1 grey--text" :date-time-value="item.endDateTime"></app-date-time-locale-formatter>
                </template>
              </v-data-table>
            </v-responsive>
          </td>
        </template>
        <template v-slot:item.capacityName="{ item }">
          <div data-cy="truck-utilization-rates-item-utilization" class="text-md-body-1 grey--text">{{ item.capacityName }}</div>
        </template>
        <template v-slot:item.rate="{ item }">
          <v-text-field
            v-if="isItemBeingEdited(item.id)"
            id="rateTextField"
            class="mt-4 mb-n2 flex-fill justify-center text-center text-no-wrap"
            ref="rateTextField"
            @change="setEditableItemRate"
            :value="editedItem.rate"
            :rules="rateValidationRules"
            type="number"
            min="0"
            max="999999.99"
            counter="9"
            dense
            prepend-inner-icon="mdi-currency-usd"
            single-line
            outlined
            data-cy="truck-utilization-rates-item-rate"
          ></v-text-field>
          <app-number-formatter class="text-md-body-1 grey--text" v-else :amount="item.rate" currency-sign="accounting" number-format-style="currency"></app-number-formatter>
        </template>
        <template v-slot:item.beginDateTime="{ item }">
          <app-date-picker-field-selector
            v-if="isItemBeingEdited(item.id)"
            :date-picker-value="editedItem.beginDateTime"
            :minimum-date-value="minimumPickerDate"
            text-field-format="MMMM D, YYYY"
            @updatedPickerValue="setEditableItemBeginDateTime($event)"
            :isTextFieldDense="true"
          ></app-date-picker-field-selector>
          <app-date-time-locale-formatter class="text-md-body-1 grey--text" v-else :date-time-value="item.beginDateTime"></app-date-time-locale-formatter>
        </template>
        <template v-slot:item.endDateTime="{ item }">
          <app-date-time-locale-formatter class="transparent" v-if="isItemBeingEdited(item.id)"></app-date-time-locale-formatter>
          <app-date-time-locale-formatter class="text-md-body-1 grey--text" v-else :date-time-value="item.endDateTime"></app-date-time-locale-formatter>
        </template>
        <template v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
          <div class="justify-center" v-if="isItemBeingEdited(item.id)">
            <v-btn color="primary" ref="truck-utilization-save-item-btn" data-cy="truck-utilization-save-item-btn" @click="saveEditableItem" icon>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn color="red" ref="truck-utilization-cancel-item-btn" data-cy="truck-utilization-cancel-item-btn" @click="resetEditableItem" icon>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
          <div v-else class="justify-center">
            <v-btn color="primary" ref="truck-utilization-edit-item-btn" data-cy="truck-utilization-edit-item-btn" @click="editItem(item)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import AppNotFound from "@/components/AppNotFound"
import { tomorrowAsDate } from "@/utils/DateTimeFormatters"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsTruckUtilizationRatesCard",
  components: { AppNotFound, AppNumberFormatter, AppDateTimeLocaleFormatter, AppDatePickerFieldSelector },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editedItem: {},
      minimumPickerDate: tomorrowAsDate(),
      rateValidationRules: [
        value => !!value || "Price is required.",
        value => value > 0 || "Price must be positive.",
        value => value <= 999999.99 || "Price must be less than or equal to $999,999.99."
      ],
      truckUtilizationSubHeaders: [
        { text: "Rate", value: "rate", align: "center", class: "secondaryDark white--text" },
        { text: "Begin Date", value: "beginDateTime", align: "center", class: "secondaryDark white--text" },
        { text: "End Date", value: "endDateTime", align: "center", class: "secondaryDark white--text" }
      ]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["upsertFranchiseCapacity"]),
    filterPastAndFutureUtilizationRatesByCapacityId(item) {
      return this.getAllInactiveFranchiseCapacities?.filter(t => {
        return t.capacityId === item.capacityId
      })
    },
    shouldDisplayExpandableIcon(item) {
      return this.filterPastAndFutureUtilizationRatesByCapacityId(item)?.length > 0 ?? false
    },
    isItemBeingEdited(itemId) {
      return itemId === this.editedItem?.id
    },
    editItem(item) {
      this.setEditedItem(item)
      this.focusOnRateTextField()
    },
    setEditedItem(item) {
      this.editedItem = this.createEditableItemObject(item)
    },
    createEditableItemObject(item) {
      let newItem = Object.assign({}, item)
      newItem.beginDateTime = this.minimumPickerDate
      newItem.rate = ""
      return newItem
    },
    focusOnRateTextField() {
      this.$nextTick(() => {
        this.$refs.rateTextField?.focus()
      })
    },
    setEditableItemRate(itemRate) {
      this.editedItem.rate = itemRate ? parseFloat(itemRate).toFixed(2) : ""
    },
    setEditableItemBeginDateTime(event) {
      this.editedItem.beginDateTime = event
    },
    async saveEditableItem() {
      if (this.$refs["rateTextField"].validate()) {
        let franchiseCapacityDto = this.createFranchiseCapacityDto(this.editedItem)
        await this.upsertFranchiseCapacity(franchiseCapacityDto).then(() => {
          this.$refs.rateTextField.resetValidation()
          this.resetEditableItem()
        })
      }
    },
    resetEditableItem() {
      this.editedItem = {}
    },
    createFranchiseCapacityDto(item) {
      return {
        FranchiseId: item.franchiseId,
        CapacityId: item.capacityId,
        Rate: item.rate,
        BeginDateTime: item.beginDateTime,
        EndDateTime: item.endDateTime,
        ModifiedBy: this.$msal.getCurrentUserId()
      }
    },
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getAllActiveFranchiseCapacities", "getAllInactiveFranchiseCapacities", "getTruckUtilizationRatesLoadingState"]),
    truckUtilizationHeaders() {
      let headers = [
        { text: "Utilization", value: "capacityName", align: "center", sortable: false, width: "10%" },
        { text: "Rate", value: "rate", align: "center", sortable: false },
        { text: "Begin Date", value: "beginDateTime", align: "center", sortable: false },
        { text: "End Date", value: "endDateTime", align: "center", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false, width: "5%" }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    }
  }
}
</script>

<style scoped>
.v-text-field {
  min-width: 175px;
}
</style>
