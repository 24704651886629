<template>
  <v-card ref="settings-franchise-selector-card" class="elevation-4">
    <v-card-title ref="settings-franchise-selector-card-title">
      <span class="headline">Franchise</span>
    </v-card-title>
    <v-card-text class="mb-2">
      <v-select
        ref="settings-franchise-selector-card-select"
        data-cy="settings-franchise-selector-card-select"
        class="text-md-body-1 cursor_pointer"
        color="primary"
        label="Selected Franchise"
        item-text="franchiseName"
        item-value="franchiseId"
        outlined
        return-object
        hide-details
        :value="getSelectedFranchise"
        :items="getFranchisesInOperatingUnit"
        :loading="isLoading"
        :disabled="isFranchiseSelectionDisabled"
        @change="setSelectedFranchiseHandler"
      ></v-select>
    </v-card-text>
  </v-card>
</template>

<script>
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"

export default {
  name: "SettingsFranchiseSelectorCard",
  methods: {
    ...mapActions(useSettingsStore, [
      "fetchFranchiseCapacitiesByFranchiseId",
      "fetchActiveFranchisesInOperatingUnit",
      "fetchFranchiseBusinessHoursByFranchiseId",
      "fetchFranchiseSpecialHoursByFranchiseId",
      "fetchElectedDiscountsForWebsiteByFranchiseId",
      "fetchTipConfigurationByFranchiseId",
      "setSelectedFranchise",
      "setSelectedFranchiseObject"
    ]),
    async setSelectedFranchiseHandler(franchiseObject) {
      this.setSelectedFranchise(franchiseObject.franchiseId)
      this.setSelectedFranchiseObject(franchiseObject)
      this.$emit("selectedFranchiseUpdated")
      await Promise.allSettled([
        this.fetchFranchiseCapacitiesByFranchiseId(franchiseObject.franchiseId),
        this.fetchFranchiseBusinessHoursByFranchiseId(franchiseObject.franchiseId),
        this.fetchFranchiseSpecialHoursByFranchiseId(franchiseObject.franchiseId),
        this.fetchElectedDiscountsForWebsiteByFranchiseId(franchiseObject.franchiseId),
        this.fetchTipConfigurationByFranchiseId(franchiseObject.franchiseId)
      ])
    }
  },
  computed: {
    ...mapState(useMainStore, ["getIsLoading", "getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, ["getFranchisesInOperatingUnit", "getSelectedFranchise", "getTruckUtilizationRatesLoadingState", "getIsLoadingTipConfiguration"]),
    isFranchiseSelectionDisabled() {
      return (this.isLoading || this.getFranchisesInOperatingUnit?.length < 2)
    },
    isLoading() {
      return this.getIsLoading || this.getTruckUtilizationRatesLoadingState || this.getIsLoadingTipConfiguration
    }
  },
  async created() {
    if (this.getSelectedOperatingUnitId !== null && this.getSelectedOperatingUnitId !== undefined) {
      await this.fetchActiveFranchisesInOperatingUnit(this.getSelectedOperatingUnitId)
    }
  }
}
</script>
