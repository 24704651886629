<template>
  <v-card class="elevation-1" ref="settings-franchise-cutoff-distance-card-root" :loading="getIsLoadingFranchiseDistanceCutoff">
    <v-card-title>
      <span class="headline me-2 word-wrap" ref="settings-franchise-cutoff-distance-card-heading">Self-Scheduling Cutoff Distance</span>
      <base-tool-tip
        ref="settings-franchise-cutoff-distance-card-tooltip"
        dialog-title="Represents a one-way distance from the franchise to the customer's location."
        icon-color="primary"
        tooltip-icon="mdi-information-outline"
        tooltip-color="primary"
        :is-top-dialog="true"
        nudge-dialog="0"
      ></base-tool-tip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col class="mx-2 col-xl-4 col-lg-4 col-md-9 col-sm-9">
          <v-text-field
            ref="settings-franchise-cutoff-distance-card-cut-off-distance"
            label="Cut-Off Distance (in miles)"
            outlined
            :key="franchiseCutoffDistanceKey"
            :value="getSelfBookingCutoffDistance"
            @change="setSelfBookingCutoffDistance"
            :rules="[cutOffDistanceRules]"
            :disabled="isFieldsDisabled"
            counter="3"
            type="number"
            min="20"
            max="350"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" class="col-2 fill-height justify-center" v-if="!isActionsDisabled">
          <div class="justify-center" v-if="isEditing">
            <v-btn ref="settings-franchise-cutoff-distance-card-save-btn" color="primary" data-cy="truck-utilization-save-item-btn" @click="save" icon>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn ref="settings-franchise-cutoff-distance-card-cancel-btn" color="red" data-cy="truck-utilization-cancel-item-btn" @click="setIsEditing(false)" icon>
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
          <div v-else>
            <v-btn v-if="getIsSelectedFranchiseObjectPopulated" class="mt-3" color="primary" ref="settings-franchise-cutoff-distance-card-edit-btn" data-cy="truck-utilization-edit-item-btn" @click="setIsEditing(true)" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { putFranchiseCustomerSelfBookingCutoffDto } from "@/api/dtos/JunkDtos"
import { consoleLog } from "@/utils/Logging"
import BaseToolTip from "@/components/BaseToolTip.vue"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsFranchiseCutoffDistanceCard",
  components: { BaseToolTip },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cutOffDistance: 0,
      isEditing: false,
      franchiseCutoffDistanceKey: 0,
      cutOffDistanceRules: v => {
        if (!isNaN(parseFloat(v)) && v >= 20 && v <= 350) return true
        return "Number has to be between 20 and 350"
      }
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["putFranchiseCustomerSelfBookingDistanceCutoff"]),
    async setIsEditing(isEditing) {
      this.isEditing = isEditing
      this.setSelfBookingCutoffDistance(this.getSelfBookingCutoffDistance)
      this.revertCutoffDistance()
    },
    setSelfBookingCutoffDistance(distance) {
      this.cutOffDistance = distance
    },
    revertCutoffDistance() {
      this.franchiseCutoffDistanceKey === 1 ? this.franchiseCutoffDistanceKey = 0 : this.franchiseCutoffDistanceKey = 1
    },
    async save() {
      if (this.$refs["settings-franchise-cutoff-distance-card-cut-off-distance"].validate()) {
        const dto = putFranchiseCustomerSelfBookingCutoffDto(this.getSelectedFranchiseObject.franchiseId, this.cutOffDistance)
        consoleLog("putFranchiseCustomerSelfBookingDistanceCutoff", dto)
        await this.putFranchiseCustomerSelfBookingDistanceCutoff(dto).then(() => {
          this.setIsEditing(false)
        })
      }
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getSelectedFranchiseObject", "getIsLoadingFranchiseDistanceCutoff"]),
    isFieldsDisabled() {
      return this.isActionsDisabled || !this.isEditing
    },
    getSelfBookingCutoffDistance() {
      return this.getSelectedFranchiseObject?.selfBookingCutoffDistance ?? 0
    },
    getIsSelectedFranchiseObjectPopulated() {
      return Object.keys(this.getSelectedFranchiseObject).length !== 0
    }
  }
}
</script>

<style scoped></style>
