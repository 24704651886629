<template>
  <v-card id="app-footer-card" color="secondary" class="pt-0 pb-2 px-2" outlined elevation="24" min-width="325" max-width="375" :max-height="this.determineMaxHeight">
    <v-card-title class="pa-0 ma-0 ms-n2">
      <v-btn
        ref="app-footer-expand-collapse-btn"
        icon
        :disabled="isExpandButtonDisabled"
        elevation="0"
        color="white"
        @click="toggleIsExpanded"
        class="v-data-table__expand-icon"
        :class="{ 'v-data-table__expand-icon--active': expandedFooterState }"
      >
        <v-icon class="pt-1">mdi-chevron-down</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style="background-color: transparent">
      <v-layout row class="my-1">
        <slot name="content"> </slot>
      </v-layout>
      <v-layout row>
        <v-progress-linear ref="app-footer-progress-bar" v-if="isLoading" class="mb-n1" indeterminate color="primary"></v-progress-linear>
        <v-divider ref="app-footer-divider" v-else class="mb-n1 ms-2" :style="{ background: 'var(--v-primary-base)' }"></v-divider>
      </v-layout>
      <v-layout row v-show="isExpanded">
        <slot name="additional-content"></slot>
      </v-layout>
    </v-card-text>
    <v-card-actions class="flex-fill">
      <slot name="actions"></slot>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "AppFooter",
  data() {
    return {
      isExpanded: false
    }
  },
  props: {
    headerValue: {
      type: String,
      default: ""
    },
    headerText: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isExpandButtonDisabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleIsExpanded() {
      this.isExpanded = !this.isExpanded
    }
  },
  computed: {
    expandedFooterState() {
      if (this.isExpandButtonDisabled) return false
      return this.isExpanded
    },
    determineMaxHeight() {
      return this.expandedFooterState ? "950" : "85"
    }
  }
}
</script>

<style scoped>
#app-footer-card {
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: 16px 0 0 0 !important;
  opacity: 0.97;
  z-index: 1;
  border-color: transparent !important;
}
</style>
