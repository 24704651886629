<template>
  <v-card class="elevation-4 pb-1">
    <v-card-title>
      <span class="headline word-wrap">Discounts Displayed on Brand Website</span>
    </v-card-title>
    <v-card-text>
      <v-data-table
          ref="settings-franchise-elected-discounts-for-website-card-table"
          class="flex-fill"
          :headers="franchiseElectedNationalDiscountsTableHeaders"
          :items="getFranchiseElectedDiscountsForWebsite"
          item-key="franchiseElectedDiscountId"
          sort-by="discountName"
          :loading="getIsLoadingFranchiseElectedDiscountsForWebsite"
          loading-text="🔎 Fetching Franchise Elected National Discounts 🔍"
          mobile-breakpoint="840"
          show-expand
          single-expand
          fixed-header
          hide-default-footer
          disable-pagination
      >
        <template v-slot:no-data>
          <v-col class="ma-0 pa-0 align-self-center d-flex">
            <app-not-found title="Ope! No available records :(" content="Looks like you don't have any records, please select a franchise.">
              <template v-slot:actions>
                <v-btn
                    v-if="!getSelectedFranchise"
                    ref="settings-franchise-elected-discounts-for-website-card-selector-button"
                    class="mt-4 primary justify-center align-self-center"
                    @click="$emit('selectFranchiseSelector')"
                    ripple
                    rounded
                    large
                >Select Franchise</v-btn
                >
                <div v-else />
              </template>
            </app-not-found>
          </v-col>
        </template>
        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <td ref="settings-franchise-elected-discounts-for-website-card-expand-cell" v-if="shouldDisplayExpandableIcon(item)" class="text-start">
            <v-btn ref="settings-franchise-elected-discounts-for-website-card-expand-button" icon @click="expand(!isExpanded)" class="v-data-table__expand-icon" :class="{ 'v-data-table__expand-icon--active': isExpanded }">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:item.discountAmount="{ item }">
          <app-number-formatter
              ref="settings-franchise-elected-discounts-for-website-card-rate-formatter"
              class="text-md-body-1 grey--text"
              currency-sign="accounting"
              number-format-style="currency"
              :amount="item.discountAmount"
          />
        </template>
        <template v-slot:item.nationalDiscountBeginDate="{ item }">
          <app-date-time-locale-formatter
              ref="settings-franchise-elected-discounts-for-website-card-begin-date-formatter"
              class="text-md-body-1 grey--text"
              :date-time-value="getBeginDateTimeValue(item)"
          />
        </template>
        <template v-slot:item.nationalDiscountEndDate="{ item }">
          <app-date-time-locale-formatter
              ref="settings-franchise-elected-discounts-for-website-card-end-date-formatter"
              class="text-md-body-1 grey--text"
              :date-time-value="getEndDateTimeValue(item)"
          />
        </template>
        <template ref="settings-franchise-elected-discounts-for-website-card-actions" v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
          <div ref="settings-franchise-elected-discounts-for-website-card-actions-div" v-if="isDateOnOrAfterToday(item.nationalDiscountEndDate)" class="justify-center">
            <v-btn ref="settings-franchise-elected-discounts-for-website-card-actions-button" color="primary" icon @click="setIsCreateElectedBrandWebsiteDiscountDialogVisible(true, item)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td ref="settings-franchise-elected-discounts-for-website-card-expanded-cell" class="pa-0" :colspan="headers.length">
            <v-responsive class="overflow-y-auto" max-height="310">
              <v-data-table
                  class="flex-fill"
                  ref="settings-franchise-elected-discounts-for-website-card-sub-table"
                  :headers="franchiseElectedNationalDiscountsTableSubHeaders"
                  :items="item.franchiseElectedDiscountsForWebsite"
                  sort-by="websiteDisplayBeginDate"
                  :sort-desc="true"
                  item-key="franchiseElectedDiscountForWebsiteId"
                  item-class="backgroundDisabled"
                  no-data-text="There are no past or future elections associated to this discount."
                  mobile-breakpoint="840"
                  disable-pagination
                  fixed-header
                  hide-default-footer
              >
                <template v-slot:item.websiteDisplayBeginDate="{ item }">
                  <app-date-picker-field-selector
                      ref="settings-franchise-elected-discounts-for-website-card-expanded-begin-date-picker"
                      v-if="isItemBeingEdited(item.franchiseElectedDiscountForWebsiteId)"
                      :date-picker-value="formatAsDateOnly(editedItem.websiteDisplayBeginDate)"
                      :minimum-date-value="getMinimumPickerBeginDate(item)"
                      :maximum-date-value="formatAsDateOnly(editedItem.websiteDisplayEndDate)"
                      text-field-format="MMMM D, YYYY"
                      @updatedPickerValue="setEditedItemBeginDate($event)"
                      :text-field-disabled="!isDateAfterToday(editedItem.websiteDisplayBeginDate)"
                      :is-text-field-dense="true"
                  />
                  <app-date-time-locale-formatter ref="settings-franchise-elected-discounts-for-website-card-expanded-begin-date-formatter" v-else class="text-md-body-1 grey--text" :date-time-value="item.websiteDisplayBeginDate"></app-date-time-locale-formatter>
                </template>
                <template v-slot:item.websiteDisplayEndDate="{ item }">
                  <app-date-picker-field-selector
                      ref="settings-franchise-elected-discounts-for-website-card-expanded-end-date-picker"
                      v-if="isItemBeingEdited(item.franchiseElectedDiscountForWebsiteId)"
                      :date-picker-value="formatAsDateOnly(editedItem.websiteDisplayEndDate)"
                      :minimum-date-value="editableItemEndDateMinimumValue"
                      :maximum-date-value="getMaximumPickerEndDate(item)"
                      text-field-format="MMMM D, YYYY"
                      @updatedPickerValue="setEditedItemEndDate($event)"
                      :text-field-disabled="!isDateOnOrAfterToday(editedItem.websiteDisplayEndDate)"
                      :isTextFieldDense="true"
                  ></app-date-picker-field-selector>
                  <app-date-time-locale-formatter ref="settings-franchise-elected-discounts-for-website-card-expanded-end-date-formatter" v-else class="text-md-body-1 grey--text" :date-time-value="item.websiteDisplayEndDate"></app-date-time-locale-formatter>
                </template>
                <template ref="settings-franchise-elected-discounts-for-website-card-actions" v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
                  <div ref="settings-franchise-elected-discounts-for-website-card-actions-edit" class="justify-center" v-if="isItemBeingEdited(item.franchiseElectedDiscountForWebsiteId)">
                    <v-btn color="primary" ref="settings-franchise-elected-discounts-for-website-card-actions-item-save-button" @click="saveEditedItem" icon>
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn color="red" ref="settings-franchise-elected-discounts-for-website-card-actions-item-cancel-button" @click="resetEditedItem" icon>
                      <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                  </div>

                  <div ref="settings-franchise-elected-discounts-for-website-card-actions-editing" v-else-if="isDateOnOrAfterToday(item.websiteDisplayEndDate)" class="justify-center">
                    <v-btn color="primary" ref="settings-franchise-elected-discounts-for-website-card-actions-editing-item-edit-button" @click="setEditedItem(item)" icon>
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="red" ref="settings-franchise-elected-discounts-for-website-card-actions-editing-item-delete-button" @click="deleteItem(item)" icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <div v-else />
                </template>
              </v-data-table>
            </v-responsive>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <settings-create-franchise-elected-discount-for-website-dialog
        ref="settings-franchise-elected-discounts-for-website-card-create-discount-dialog"
        :selected-franchise="getSelectedFranchiseObject"
        :selected-discount="nationalDiscountSelected"
        v-if="isCreateDiscountDialogVisible"
        :is-dialog-visible="isCreateDiscountDialogVisible"
        @confirm-discount="handleCreateDiscount($event)"
        @close-dialog="setIsCreateElectedBrandWebsiteDiscountDialogVisible(false, {})"
    />
  </v-card>
</template>

<script>
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import AppNotFound from "@/components/AppNotFound"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import SettingsCreateFranchiseElectedDiscountForWebsiteDialog from "@/components/SettingsCreateFranchiseElectedDiscountForWebsiteDialog"
import {
  formatAsDateOnly,
  getIsFirstDateAfterSecondDate,
  getIsFirstDateOnOrBeforeSecondDate,
  isDateAfterToday,
  isDateOnOrAfterToday,
  todayAsDate
} from "@/utils/DateTimeFormatters"
import { updateFranchiseElectedDiscountForWebsiteDto } from "@/api/dtos/JunkDtos"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsFranchiseElectedDiscountsForWebsiteCard",
  components: {
    SettingsCreateFranchiseElectedDiscountForWebsiteDialog,
    AppDatePickerFieldSelector,
    AppDateTimeLocaleFormatter,
    AppNumberFormatter,
    AppNotFound
  },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editedItem: {},
      isCreateDiscountDialogVisible: false
    }
  },
  methods: {
    isDateAfterToday,
    ...mapActions(useSettingsStore, ["createElectedDiscountForWebsite", "updateElectedDiscountForWebsite", "deleteElectedDiscountForWebsite"]),
    formatAsDateOnly,
    isDateOnOrAfterToday,
    shouldDisplayExpandableIcon(item) {
      return item.franchiseElectedDiscountsForWebsite?.length > 0
    },
    isItemBeingEdited(itemId) {
      return itemId === this.editedItem?.franchiseElectedDiscountForWebsiteId
    },
    setEditedItem(item) {
      this.editedItem = Object.assign({}, item)
    },
    resetEditedItem() {
      this.setEditedItem({})
    },
    resetEditableItem() {
      this.editedItem = {}
    },
    async saveEditedItem() {
      const updatedFranchiseElectedDiscountForWebsite = new updateFranchiseElectedDiscountForWebsiteDto(
          this.editedItem.franchiseElectedDiscountForWebsiteId,
          this.editedItem.websiteDisplayBeginDate,
          this.editedItem.websiteDisplayEndDate,
          this.$msal.getCurrentUserId()
      )
      await this.updateElectedDiscountForWebsite(updatedFranchiseElectedDiscountForWebsite)
          .then(() => {
            this.resetEditedItem()
          })
    },
    async deleteItem(item) {
      await this.deleteElectedDiscountForWebsite(item).then(() => {
        this.resetEditedItem()
      })
    },
    getBeginDateTimeValue(item) {
      const result = this.getDateSetActiveOrNearestFuture(item)
      return this.getDateSetActiveOrNearestFuture(item)?.websiteDisplayBeginDate ? formatAsDateOnly((result).websiteDisplayBeginDate) : null
    },
    getEndDateTimeValue(item) {
      const result = this.getDateSetActiveOrNearestFuture(item)
      return this.getDateSetActiveOrNearestFuture(item)?.websiteDisplayEndDate ? formatAsDateOnly((result).websiteDisplayEndDate) : null
    },
    getMinimumPickerBeginDate(item) {
      let match = this.getFranchiseElectedDiscountsForWebsite.find(discount => discount.franchiseElectedDiscountId === item.franchiseElectedDiscountId)
      if (match) {
        return isDateOnOrAfterToday(match.nationalDiscountBeginDate) ? formatAsDateOnly(match.nationalDiscountBeginDate) : todayAsDate()
      } else {
        return todayAsDate()
      }
    },
    setEditedItemBeginDate(value) {
      this.editedItem.websiteDisplayBeginDate = value
    },
    setEditedItemEndDate(value) {
      this.editedItem.websiteDisplayEndDate = value
    },
    getMaximumPickerEndDate(item) {
      return this.getFranchiseElectedDiscountsForWebsite.find(discount => discount.franchiseElectedDiscountId === item.franchiseElectedDiscountId)?.nationalDiscountEndDate
    },
    setIsCreateElectedBrandWebsiteDiscountDialogVisible(isVisible, item) {
      this.nationalDiscountSelected = Object.assign({}, item)
      this.isCreateDiscountDialogVisible = isVisible
    },
    async handleCreateDiscount(dto) {
      await this.createElectedDiscountForWebsite(dto).then(() => {
        this.resetIsCreateElectedBrandWebsiteDiscountDialogVisible()
      })
    },
    resetIsCreateElectedBrandWebsiteDiscountDialogVisible() {
      this.setIsCreateElectedBrandWebsiteDiscountDialogVisible(false, {})
    },

    getActiveDateSet(item) {
      let activeRecord = item.franchiseElectedDiscountsForWebsite.find(x => getIsFirstDateOnOrBeforeSecondDate(x.websiteDisplayBeginDate, todayAsDate()) && isDateOnOrAfterToday(x.websiteDisplayEndDate))
      return activeRecord
    },
    getDateSetActiveOrNearestFuture(item) {
      const activeDateSet = this.getActiveDateSet(item)

      if (activeDateSet) {
        return activeDateSet
      }

      let mostRecent = null

      if (isDateOnOrAfterToday(item.nationalDiscountEndDate)) {
        let activeItems = item.franchiseElectedDiscountsForWebsite.filter(discount => isDateOnOrAfterToday(discount.websiteDisplayEndDate))

        activeItems?.forEach((sub) => {
          if (mostRecent === null || getIsFirstDateAfterSecondDate(mostRecent.websiteDisplayBeginDate, sub.websiteDisplayBeginDate)) {
            mostRecent = Object.assign(
                {},
                {
                  websiteDisplayBeginDate: sub.websiteDisplayBeginDate,
                  websiteDisplayEndDate: sub.websiteDisplayEndDate
                }
            )
          }
        })

        return mostRecent
      }
    }
  },
  computed: {
    ...mapState(useSettingsStore, [
      "getSelectedFranchise",
      "getSelectedFranchiseObject",
      "getFranchiseElectedDiscountsForWebsite",
      "getIsLoadingFranchiseElectedDiscountsForWebsite"
    ]),
    editableItemEndDateMinimumValue() {
      return this.editedItem.websiteDisplayBeginDate <= todayAsDate() ? todayAsDate() : formatAsDateOnly(this.editedItem.websiteDisplayBeginDate)
    },
    franchiseElectedNationalDiscountsTableHeaders() {
      let headers = [
        { text: "Discount", value: "discountName", align: "center", sortable: false, width: "10%" },
        { text: "Rate", value: "discountAmount", align: "center", sortable: false },
        { text: "Button Text", value: "defaultWebsitePhrase", align: "center", sortable: false },
        { text: "Begin Date", value: "nationalDiscountBeginDate", align: "center", sortable: false },
        { text: "End Date", value: "nationalDiscountEndDate", align: "center", sortable: false },
        { text: "Actions", value: "actions", align: "end", sortable: false, width: "5%" }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    },
    franchiseElectedNationalDiscountsTableSubHeaders() {
      let headers = [
        { text: "Begin Date", value: "websiteDisplayBeginDate", align: "center", class: "secondaryDark white--text" },
        { text: "End Date", value: "websiteDisplayEndDate", align: "center", class: "secondaryDark white--text" },
        { text: "Actions", value: "actions", align: "end", sortable: false, class: "secondaryDark white--text", width: "10%" }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    }
  }
}
</script>
