<template>
  <v-card ref="job-addresses-card-root" elevation="4" min-height="225" :loading="getIsLoadingJobAddresses">
    <v-card-title ref="job-addresses-card-title" class="headline">Locations</v-card-title>
    <v-card-text>
      <v-responsive class="overflow-y-auto" max-height="800">
        <v-data-table
          ref="job-addresses-card-table"
          :headers="addressHeaders"
          :items="getAddressesOnJob"
          :loading="getIsLoadingJobAddresses"
          loading-text="🔎 Fetching Addresses 🔍"
          style="min-height: 120px"
          loader-height="0"
          mobile-breakpoint="0"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <template v-if="isAddressesBodyVisible" v-slot:body="props">
            <draggable :list="getAddressesOnJob" :disabled="isActionsDisabled" handle=".reorder" :move="isValidMove" @change="draggableClickHandler" tag="tbody">
              <tr v-for="jobAddress in props.items" :key="jobAddress.id" class="text-md-body-1" :class="`${itemRowBackground(jobAddress.stopOrder)}`">
                <td class="py-2 px-0">
                  <v-icon v-if="isFranchiseLocation(jobAddress.stopOrder)" small>mdi-blank</v-icon>
                  <v-icon v-else-if="isReorderIconVisible" class="reorder" color="primary" :disabled="isActionsDisabled" large>mdi-drag-vertical</v-icon>
                </td>
                <td class="text-center text-md-body-1">{{ jobAddress.stopOrder }}</td>
                <td class="text-md-body-1" style="max-width: 500px; min-width: 300px">
                  <v-select
                    v-if="isStartLocation(jobAddress.stopOrder)"
                    class="cursor_pointer"
                    :value="jobAddress.addressId"
                    :disabled="isActionsDisabled"
                    @change="updateStartAddress"
                    :items="getFranchiseDispatchAddresses"
                    :item-text="formatFranchiseAddressItemText"
                    item-value="id"
                    label="Start Address"
                    color="primary"
                    dense
                    solo
                    flat
                    outlined
                    hide-details
                  />
                  <v-select
                    v-else-if="isEndLocation(jobAddress.stopOrder)"
                    class="cursor_pointer"
                    :value="jobAddress.addressId"
                    :disabled="isActionsDisabled"
                    :items="getFranchiseDispatchAddresses"
                    @change="updateEndAddress"
                    :item-text="formatFranchiseAddressItemText"
                    item-value="id"
                    label="Return Address"
                    color="primary"
                    dense
                    solo
                    flat
                    outlined
                    hide-details
                  />
                  <span v-else role="button" ref="job-address-customer-address" @click.stop="copyText(formatCustomerAddressItemText(jobAddress))" class="ps-3 text-md-body-1">{{ formatCustomerAddressItemText(jobAddress) }}
                    <v-btn @click.stop="copyText(formatCustomerAddressItemText(jobAddress))" icon small elevation="0">
                      <v-icon small>mdi-content-copy</v-icon>
                    </v-btn>
                  </span>
                </td>
                <td class="text-center">
                  <span v-if="!isStartLocation(jobAddress.stopOrder)" class="text-md-body-1 grey--text">{{ formatDecimal(jobAddress.segmentMileage) }}</span>
                </td>
                <td class="text-center">
                  <span v-if="!isStartLocation(jobAddress.stopOrder)" class="text-md-body-1 grey--text">{{ formatDecimal(jobAddress.cumulativeMileage) }}</span>
                </td>
                <td class="text-center">
                  <span v-if="!isStartLocation(jobAddress.stopOrder)" class="text-md-body-1 grey--text">{{ formatDecimal(jobAddress.driveTimeHours) }}</span>
                </td>
                <td class="text-center">
                  <v-col class="text-right">
                    <v-icon v-if="isFranchiseLocation(jobAddress.stopOrder)" small>mdi-blank</v-icon>
                    <v-btn v-else ref="job-addresses-card-delete-address-btn" @click="deleteJunkJobAddress(jobAddress.id)" :disabled="isActionsDisabled" icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                    <v-btn ref="job-addresses-card-navigate-to-address-btn" @click="openMapLocation(jobAddress)" icon>
                      <v-icon>mdi-navigation-variant-outline</v-icon>
                    </v-btn>
                  </v-col>
                </td>
              </tr>
            </draggable>
          </template>
        </v-data-table>
      </v-responsive>
    </v-card-text>
  </v-card>
</template>

<script>
import draggable from "vuedraggable"
import snackbarBuilder from "@/utils/SnackbarBuilder"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { openMapLocation } from "@/utils/OpenLocationOnMap"
import { formatAddressText } from "@/utils/FormatAddressText"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job"
import { useSnackbarStore } from "@/stores/Snackbar"

export default {
  name: "JobAddressesCard",
  components: { draggable },
  data() {
    return {
      addressHeaders: [
        { text: "", align: "start", value: "reorder", width: "1%" },
        { text: "Stop Order", align: "center", value: "stopOrder", width: "4%" },
        { text: "Address", align: "start", value: "address" },
        { text: "Segment Mileage", align: "center", value: "segmentMileage" },
        { text: "Cumulative Mileage", align: "center", value: "cumulativeMileage" },
        { text: "Drive Time", align: "center", value: "driveTime" },
        { text: "Actions", align: "center", value: "actions" }
      ]
    }
  },
  methods: {
    ...mapActions(useJobStore, ["deleteJunkJobAddress", "fetchJunkJobAddresses", "updateJunkJobAddressStopOrder", "updateJunkJobStartOrReturnAddress"]),
    ...mapActions(useSnackbarStore, ["addSnackbar"]),
    async copyText(textToCopy) {
      await navigator.clipboard.writeText(textToCopy)
      await this.addSnackbar(snackbarBuilder.successSnackbar("Address copied to clipboard!", 6000))
    },
    itemRowBackground(stopOrder) {
      if (this.isFranchiseLocation(stopOrder)) return "background"
    },
    formatFranchiseAddressItemText(item) {
      return formatAddressText(item)
    },
    formatCustomerAddressItemText(item) {
      return formatAddressText(item)
    },
    openMapLocation(jobAddress) {
      openMapLocation(jobAddress)
    },
    isValidMove(event) {
      const item = event.draggedContext.element
      const futureIndex = event.draggedContext.futureIndex

      return !(this.isFranchiseLocation(item.stopOrder) || this.isFranchiseLocation(this.getAddressesOnJob[futureIndex].stopOrder))
    },
    isFranchiseLocation(stopOrder) {
      return this.isStartLocation(stopOrder) || this.isEndLocation(stopOrder)
    },
    isStartLocation(stopOrder) {
      return stopOrder === this.getStartAddress.stopOrder
    },
    isEndLocation(stopOrder) {
      return stopOrder === this.getEndAddress.stopOrder
    },
    async emptyStateOnClickHandler() {
      await this.fetchJunkJobAddresses(this.getCurrentJob.id)
    },
    async draggableClickHandler(value) {
      const desiredStopOrder = value.moved.newIndex + 1
      if (this.getStartAddress.stopOrder < desiredStopOrder && desiredStopOrder < this.getEndAddress.stopOrder) {
        await this.updateJunkJobAddressStopOrder({ jobAddressId: value.moved.element.id, desiredStopOrder: desiredStopOrder })
      }
    },
    async updateStartAddress(addressId) {
      const jobAddressId = this.getStartAddress?.id
      if (jobAddressId) await this.updateJunkJobStartOrReturnAddress({ jobAddressId: jobAddressId, addressId: addressId })
      else snackbarBuilder.errorSnackbar("Unable to find jobAddressId")
    },
    async updateEndAddress(addressId) {
      const jobAddressId = this.getEndAddress?.id
      if (jobAddressId) await this.updateJunkJobStartOrReturnAddress({ jobAddressId: jobAddressId, addressId: addressId })
      else snackbarBuilder.errorSnackbar("Unable to find jobAddressId")
    },
    formatDecimal(value) {
      return formatDecimalValue(value)
    }
  },
  computed: {
    ...mapState(useJobStore, [
      "getAddressesOnJob",
      "getCurrentJob",
      "getFranchiseDispatchAddresses",
      "getIsLoadingJobAddresses",
      "getIsLoadingJobAddressesReorder",
      "getIsJobFieldsDisabled"
    ]),
    isAddressesBodyVisible() {
      return this.getAddressesOnJob.length > 0
    },
    getStartAddress() {
      return this.getAddressesOnJob.find(jobAddress => jobAddress.stopOrder === 1)
    },
    getEndAddress() {
      return this.getAddressesOnJob.find(jobAddress => jobAddress.stopOrder === this.getAddressesOnJob.length)
    },
    isActionsDisabled() {
      return this.getIsLoadingJobAddressesReorder || this.getIsLoadingJobAddresses || this.getIsJobFieldsDisabled
    },
    isReorderIconVisible() {
      return this.getAddressesOnJob.length > 3
    }
  }
}
</script>

<style scoped>
.reorder {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.reorder:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>
