<template>
  <v-container fluid>
    <v-row class="ma-0 pa-0">
      <v-col cols="auto" class="flex-fill ma-0 pa-0">
        <v-tabs color="primary" fixed-tabs slider-color="primary" background-color="background">
          <v-tab ref="settings-header" ripple href="#operating-unit-settings">Settings</v-tab>
          <v-tab ref="employees-and-roles-header" href="#employees-and-roles" ripple>Employees and Roles</v-tab>
          <v-tab-item ref="settings-tab-item" class="background" value="operating-unit-settings">
            <settings-tab ref="settings-tab" class="background" />
          </v-tab-item>
          <v-tab-item ref="settings-employee-tab-item" class="background" value="employees-and-roles">
            <settings-employee-tab ref="employee-tab" />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsEmployeeTab from "@/components/SettingsEmployeeTab"
import SettingsTab from "@/components/SettingsTab"

export default {
  name: "TempSettings",
  components: {
    SettingsEmployeeTab,
    SettingsTab
  }
}
</script>
