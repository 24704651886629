<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card>
      <v-card-title class="headline">
        <v-row>
          <v-col>
            <span class="headline me-1 word-wrap">Operating Unit Discounts</span>
            <base-tool-tip
              dialog-title="These are Operating Unit Discounts that you can opt a franchise into"
              tooltip-icon="mdi-information-outline"
              icon-color="primary"
              tooltipColor="primary"
              nudge-dialog="0"
              :is-bottom-dialog="true"
            ></base-tool-tip>
          </v-col>
          <v-col>
            <v-switch ref="settings-operating-unit-discounts-card-filter-switch" class="float-end" v-model="showPastElectedDiscounts" label="Include Past Elected Discounts" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          ref="settings-operating-unit-discounts-card-data-table"
          fixed-header
          single-select
          show-expand
          disable-pagination
          hide-default-footer
          item-key="operatingUnitDiscountId"
          style="min-height: 120px"
          :headers="headers"
          :items="getFilteredDiscounts"
          :loading="getIsLoadingDiscounts"
        >
          <template v-slot:no-data>
            <v-col class="ma-0 pa-0 align-self-center d-flex">
              <app-not-found title="No Discounts!" content="There are currently no discounts.">
                <template v-slot:actions>
                  <v-btn
                    class="mt-4 primary justify-center align-self-center"
                    ref="settings-operating-unit-discounts-card-fetch-button"
                    ripple
                    rounded
                    large
                    @click="fetchOperatingUnitDiscountByOperatingUnitId"
                    >Fetch Operating Unit Discounts</v-btn
                  >
                </template>
              </app-not-found>
            </v-col>
          </template>
          <template v-slot:item.amount="{ item }">
            <app-number-formatter
              ref="settings-operating-unit-discounts-card-amount-number-formatter"
              number-format-style="currency"
              currency-sign="accounting"
              :amount="item.amount"
              :is-percentage="item.isPercentage"
            />
          </template>
          <template v-slot:item.promoCode="{ item }">
            <v-text-field
              class="mt-2 mb-n3"
              ref="settings-operating-unit-discounts-card-promo-code-edit"
              v-if="isOperatingUnitDiscountItemBeingEdited(item.operatingUnitDiscountId)"
              :value="editedOperatingUnitDiscountItem.promoCode"
              @input="setEditedDiscountItemPromoCode($event)"
              :rules="editablePromoCodeValidationRules"
              counter="25"
              outlined
              dense
            ></v-text-field>
            <span v-else ref="settings-operating-unit-discounts-card-promo-code">{{ item.promoCode }}</span>
          </template>
          <template v-slot:item.beginDate="{ item }">
            <app-date-picker-field-selector
              ref="settings-operating-unit-discounts-card-begin-date-picker"
              v-if="isOperatingUnitDiscountItemBeingEdited(item.operatingUnitDiscountId)"
              :date-picker-value="formatAsDateOnly(editedOperatingUnitDiscountItem.beginDate)"
              :minimum-date-value="minimumPickerDate"
              :maximum-date-value="formatAsDateOnly(editedOperatingUnitDiscountItem.endDate)"
              text-field-format="MMMM D, YYYY"
              @updatedPickerValue="setEditedOperatingUnitDiscountItemBeginDateTime($event)"
              :text-field-disabled="!isDateAfterToday(editedOperatingUnitDiscountItem.beginDate)"
              :is-text-field-dense="true"
            />
            <app-date-time-locale-formatter
              v-else
              ref="settings-operating-unit-discounts-card-begin-date-formatter"
              :date-time-value="item.beginDate"
            />
          </template>
          <template v-slot:item.endDate="{ item }">
            <app-date-picker-field-selector
              ref="settings-operating-unit-discounts-card-end-date-picker"
              v-if="isOperatingUnitDiscountItemBeingEdited(item.operatingUnitDiscountId)"
              :date-picker-value="formatAsDateOnly(editedOperatingUnitDiscountItem.endDate)"
              :minimum-date-value="editableItemEndDateMinimumValue"
              text-field-format="MMMM D, YYYY"
              @updatedPickerValue="setEditedOperatingUnitDiscountItemEndDateTime($event)"
              :text-field-disabled="!isDateOnOrAfterToday(editedOperatingUnitDiscountItem.endDate)"
              :is-text-field-dense="true"
            />
            <app-date-time-locale-formatter
              v-else
              ref="settings-operating-unit-discounts-card-end-date-formatter"
              :date-time-value="item.endDate"
            />
          </template>
          <template ref="settings-operating-unit-discounts-card-actions-enabled" v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
            <div ref="settings-operating-unit-discounts-card-actions-editing" class="justify-center" v-if="isOperatingUnitDiscountItemBeingEdited(item.operatingUnitDiscountId)">
              <v-btn color="primary" ref="settings-operating-unit-discounts-card-actions-item-save-button" @click="saveEditedOperatingUnitDiscountItem" icon :loading="getIsLoadingDiscounts" :disabled="getIsLoadingDiscounts">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn color="red" ref="settings-operating-unit-discounts-card-actions-item-cancel-button" @click="resetEditedOperatingUnitDiscountItem" icon :disabled="getIsLoadingDiscounts">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </div>
            <div ref="settings-operating-unit-discounts-card-actions-edit" v-else-if="isDateOnOrAfterToday(item.endDate)" class="justify-center">
              <v-btn color="primary" ref="settings-operating-unit-discounts-card-actions-edit-item-edit-button" @click="setEditedOperatingUnitDiscountItem(item)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                ref="settings-operating-unit-discounts-card-actions-edit-save-item-button"
                color="primary"
                icon
                :disabled="getIsLoadingDiscounts"
                @click="setIsEnrollFranchisesInOperatingUnitDiscountDialogVisible(true, item)"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
            <td ref="settings-operating-unit-discounts-card-actions-expand" v-if="getHasFranchiseElectedDiscounts(item)" class="text-start">
              <v-btn ref="settings-operating-unit-discounts-card-actions-expand-button" icon @click="expand(!isExpanded)" class="v-data-table__expand-icon" :class="{ 'v-data-table__expand-icon--active': isExpanded }">
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </td>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td ref="settings-operating-unit-discounts-card-actions-expanded-cell" class="pa-0" :colspan="headers.length">
              <v-responsive class="overflow-y-auto" max-height="350">
                <v-data-table
                  ref="settings-operating-unit-discounts-card-actions-expanded-data-table"
                  :headers="subHeaders"
                  :items="item.franchiseElectedDiscounts"
                  fixed-header
                  :item-class="itemRowBackground"
                  no-data-text="There are no franchises associated with this national discount."
                  mobile-breakpoint="590"
                  item-key="franchiseElectedDiscountId"
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:item.franchiseNumberAndName="{ value }">
                    <span ref="settings-operating-unit-discounts-card-actions-expanded-name" class="text-md-body-1 grey--text">{{ value }}</span>
                  </template>
                  <template v-slot:item.beginDate="{ item }">
                    <app-date-picker-field-selector
                      ref="settings-operating-unit-discounts-card-actions-expanded-begin-date-picker"
                      v-if="isFranchiseElectedDiscountItemBeingEdited(item.franchiseElectedDiscountId)"
                      :date-picker-value="formatAsDateOnly(editedFranchiseElectedDiscountItem.beginDate)"
                      :minimum-date-value="getMinimumPickerDateForElectedDiscountBeginDate(item)"
                      :maximum-date-value="formatAsDateOnly(editedFranchiseElectedDiscountItem.endDate)"
                      text-field-format="MMMM D, YYYY"
                      @updatedPickerValue="setEditedFranchiseElectedDiscountItemBeginDateTime($event)"
                      :text-field-disabled="!isDateAfterToday(editedFranchiseElectedDiscountItem.beginDate)"
                      :is-text-field-dense="true"
                    />
                    <app-date-time-locale-formatter ref="settings-operating-unit-discounts-card-actions-expanded-begin-date-formatter" v-else class="text-md-body-1 grey--text" :date-time-value="item.beginDate" />
                  </template>
                  <template v-slot:item.endDate="{ item }">
                    <app-date-picker-field-selector
                      ref="settings-operating-unit-discounts-card-actions-expanded-end-date-picker"
                      v-if="isFranchiseElectedDiscountItemBeingEdited(item.franchiseElectedDiscountId)"
                      :date-picker-value="formatAsDateOnly(editedFranchiseElectedDiscountItem.endDate)"
                      :minimum-date-value="formatAsDateOnly(editedFranchiseElectedDiscountItem.beginDate)"
                      :maximum-date-value="getMaximumPickerDateForElectedDiscountEndDate(item)"
                      text-field-format="MMMM D, YYYY"
                      @updatedPickerValue="setEditedFranchiseElectedDiscountItemEndDateTime($event)"
                      :text-field-disabled="!isDateOnOrAfterToday(editedFranchiseElectedDiscountItem.endDate)"
                      :isTextFieldDense="true"
                    />
                    <app-date-time-locale-formatter ref="settings-operating-unit-discounts-card-actions-expanded-end-date-formatter" v-else class="text-md-body-1 grey--text" :date-time-value="item.endDate" />
                  </template>
                  <template ref="settings-operating-unit-discounts-card-actions-editing" v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
                    <div ref="settings-operating-unit-discounts-card-actions-editing-div" v-if="isDiscountApplicable(item)">
                      <div ref="settings-operating-unit-discounts-card-actions-editing-item" class="justify-center" v-if="isFranchiseElectedDiscountItemBeingEdited(item.franchiseElectedDiscountId)">
                        <v-btn color="primary" ref="settings-operating-unit-discounts-card-actions-editing-item-save-button" @click="saveEditedFranchiseElectedDiscountItem" :loading="getIsLoadingDiscounts" icon :disabled="getIsLoadingDiscounts">
                          <v-icon>mdi-content-save</v-icon>
                        </v-btn>
                        <v-btn color="red" ref="settings-operating-unit-discounts-card-actions-editing-item-cancel-button" @click="resetEditedFranchiseElectedDiscountItem" icon :disabled="getIsLoadingDiscounts">
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </div>
                      <div ref="settings-operating-unit-discounts-card-actions-edit" v-else-if="isDateOnOrAfterToday(item.endDate)" class="justify-center">
                        <v-btn color="primary" ref="settings-operating-unit-discounts-card-actions-edit-item-edit-button" @click="setEditedFranchiseElectedDiscountItem(item)" icon :disabled="getIsLoadingDiscounts">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="getIsDiscountDeletable(item)" color="error" ref="settings-operating-unit-discounts-card-actions-edit-item-delete-button" @click="deleteFranchiseElectedDiscount(item)" icon :disabled="getIsLoadingDiscounts">
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    <div v-else></div>
                  </template>
                </v-data-table>
              </v-responsive>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <settings-add-franchise-operating-unit-discount-dialog
      ref="settings-operating-unit-discounts-card-add-franchise-dialog"
      v-if="isEnrollFranchiseInOperatingUnitDiscountVisible"
      :is-dialog-visible="isEnrollFranchiseInOperatingUnitDiscountVisible"
      :operating-unit-discount="operatingUnitDiscountSelected"
      @closeDialog="setIsEnrollFranchisesInOperatingUnitDiscountDialogVisible(false, {})"
    />
  </v-container>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip"
import AppNotFound from "@/components/AppNotFound"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import SettingsAddFranchiseOperatingUnitDiscountDialog from "@/components/SettingsAddFranchiseOperatingUnitDiscountDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { useMainStore } from "@/stores/Main"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { formatAsDateOnly, isDateAfterToday, isDateOnOrAfterToday, todayAsDate } from "@/utils/DateTimeFormatters"
import { updateFranchiseElectedDiscountDto, updateOperatingUnitDiscountDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsOperatingUnitDiscountsCard",
  components: { AppDatePickerFieldSelector, SettingsAddFranchiseOperatingUnitDiscountDialog, BaseToolTip, AppNotFound, AppNumberFormatter, AppDateTimeLocaleFormatter },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showPastElectedDiscounts: false,
      isEnrollFranchiseInOperatingUnitDiscountVisible: false,
      operatingUnitDiscountSelected: {},
      minimumPickerDate: todayAsDate(),
      editedOperatingUnitDiscountItem: {},
      editedFranchiseElectedDiscountItem: {},
      editablePromoCodeValidationRules: [value => ((value ?? "").length > 4 && (value ?? "").length <= 25) || "Must be between 5 and 25 characters."]
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, ["getOperatingUnitDiscounts", "getActiveAndFutureOperatingUnitDiscounts", "getIsLoadingDiscounts"]),
    headers() {
      let headers = [
        { text: "Name", align: "start", value: "name" },
        { text: "Discount", align: "start", value: "amount" },
        { text: "Promo Code", align: "start", value: "promoCode" },
        { text: "Begin Date", align: "start", value: "beginDate" },
        { text: "End Date", align: "start", value: "endDate" },
        { text: "Actions", align: "end", value: "actions", sortable: false }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    },
    getFilteredDiscounts() {
      return this.showPastElectedDiscounts ? this.getOperatingUnitDiscounts : this.getActiveAndFutureOperatingUnitDiscounts
    },
    subHeaders() {
      let headers = [
        { text: "Franchise Name", align: "start", value: "franchiseNumberAndName", class: "secondaryDark white--text" },
        { text: "Begin Date", align: "center", value: "beginDate", class: "secondaryDark white--text" },
        { text: "End Date", align: "center", value: "endDate", class: "secondaryDark white--text" },
        { text: "Actions", align: "end", value: "actions", class: "secondaryDark white--text", sortable: false }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    },
    editableItemEndDateMinimumValue() {
      return this.editedOperatingUnitDiscountItem.beginDate <= todayAsDate() ? todayAsDate() : formatAsDateOnly(this.editedOperatingUnitDiscountItem.beginDate)
    }
  },
  methods: {
    isDateAfterToday,
    todayAsDate,
    formatAsDateOnly,
    isDateOnOrAfterToday,
    ...mapActions(useSettingsStore, ["fetchOperatingUnitDiscounts", "updateOperatingUnitDiscount", "updateOperatingUnitFranchiseElectedDiscount", "deleteOperatingUnitFranchiseElectedDiscount"]),
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    },
    getHasFranchiseElectedDiscounts(item) {
      return item.franchiseElectedDiscounts.length > 0
    },
    isDiscountApplicable(discount) {
      return (isDateOnOrAfterToday(discount.endDate))
    },
    getMinimumPickerDateForElectedDiscountBeginDate(item) {
      let match = this.getFilteredDiscounts.find(discount => discount.operatingUnitDiscountId === item.discountId)
      if (match) {
        return isDateOnOrAfterToday(match.beginDate) ? formatAsDateOnly(match.beginDate) : this.minimumPickerDate
      } else {
        return this.minimumPickerDate
      }
    },
    getMaximumPickerDateForElectedDiscountEndDate(item) {
      let match = this.getFilteredDiscounts.find(discount => discount.operatingUnitDiscountId === item.discountId)
      if (match) {
        return formatAsDateOnly(match.endDate)
      }
      return null
    },
    setIsEnrollFranchisesInOperatingUnitDiscountDialogVisible(isVisible, operatingUnitDiscount) {
      this.isEnrollFranchiseInOperatingUnitDiscountVisible = isVisible
      this.operatingUnitDiscountSelected = Object.assign({}, operatingUnitDiscount)
    },
    async fetchOperatingUnitDiscountByOperatingUnitId() {
      await this.fetchOperatingUnitDiscounts(this.getSelectedOperatingUnitId)
    },
    isOperatingUnitDiscountItemBeingEdited(itemId) {
      return itemId === this.editedOperatingUnitDiscountItem?.operatingUnitDiscountId
    },
    setEditedOperatingUnitDiscountItem(item) {
      this.editedOperatingUnitDiscountItem = Object.assign({}, item)
    },
    resetEditedOperatingUnitDiscountItem() {
      this.setEditedOperatingUnitDiscountItem({})
    },
    setEditedDiscountItemPromoCode(promoCode) {
      this.editedOperatingUnitDiscountItem.promoCode = promoCode.toUpperCase()
    },
    setEditedOperatingUnitDiscountItemBeginDateTime(dateEvent) {
      this.editedOperatingUnitDiscountItem.beginDate = dateEvent
    },
    setEditedOperatingUnitDiscountItemEndDateTime(dateEvent) {
      this.editedOperatingUnitDiscountItem.endDate = dateEvent
    },
    async saveEditedOperatingUnitDiscountItem() {
      let dto = updateOperatingUnitDiscountDto(this.editedOperatingUnitDiscountItem.operatingUnitDiscountId, this.editedOperatingUnitDiscountItem.beginDate, this.editedOperatingUnitDiscountItem.endDate, this.editedOperatingUnitDiscountItem.promoCode, this.$msal.getCurrentUserId())
      await this.updateOperatingUnitDiscount(dto).then(() => this.resetEditedOperatingUnitDiscountItem())
    },
    isFranchiseElectedDiscountItemBeingEdited(itemId) {
      return itemId === this.editedFranchiseElectedDiscountItem?.franchiseElectedDiscountId
    },
    setEditedFranchiseElectedDiscountItem(item) {
      this.editedFranchiseElectedDiscountItem = Object.assign({}, item)
    },
    async deleteFranchiseElectedDiscount(item) {
      await this.deleteOperatingUnitFranchiseElectedDiscount(item.franchiseElectedDiscountId)
          .then(() => this.fetchOperatingUnitDiscountByOperatingUnitId())
    },
    resetEditedFranchiseElectedDiscountItem() {
      this.setEditedFranchiseElectedDiscountItem({})
    },
    setEditedFranchiseElectedDiscountItemBeginDateTime(dateEvent) {
      this.editedFranchiseElectedDiscountItem.beginDate = dateEvent
    },
    setEditedFranchiseElectedDiscountItemEndDateTime(dateEvent) {
      this.editedFranchiseElectedDiscountItem.endDate = dateEvent
    },
    getIsDiscountDeletable(discount) {
      return isDateOnOrAfterToday(discount.beginDate)
    },
    async saveEditedFranchiseElectedDiscountItem() {
      let dto = updateFranchiseElectedDiscountDto(this.editedFranchiseElectedDiscountItem?.franchiseElectedDiscountId, this.editedFranchiseElectedDiscountItem.beginDate, this.editedFranchiseElectedDiscountItem.endDate, this.$msal.getCurrentUserId())
      await this.updateOperatingUnitFranchiseElectedDiscount(dto).then(() => this.resetEditedFranchiseElectedDiscountItem())
    }
  },
  async created() {
    await this.fetchOperatingUnitDiscountByOperatingUnitId()
  }
}
</script>
