<template>
  <v-menu ref="app-date-picker-field-selector-root" :close-on-content-click="false" transition="scale-transition" offset-y min-width="min-content">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="date-picker-text-field justify-center d-inline-flex"
        data-cy="date-picker-field-selector-text-field"
        :value="textFieldValue"
        :label="textFieldLabel"
        :prepend-inner-icon="prependInnerIcon"
        :disabled="textFieldDisabled"
        :readonly="true"
        :error-messages="errorMessages"
        :hide-details="errorMessages.length <= 0"
        :dense="isTextFieldDense"
        :append-icon="getClearIcon"
        :menu-props="{ bottom: true, offsetY: true }"
        @click:append="clearDate"
        outlined
        :rounded="rounded"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      class="date-picker"
      ref="date-picker"
      elevation="8"
      color="primary"
      :value="datePickerValue"
      :min="minimumDateValue"
      :max="maximumDateValue"
      :allowed-dates="getAllowedDates"
      show-current
      @input="emitUpdatedPickerValue($event)"
    />
  </v-menu>
</template>

<script>
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"

export default {
  name: "AppDatePickerFieldSelector",
  props: {
    textFieldLabel: {
      type: String,
      default: ""
    },
    textFieldDisabled: {
      type: Boolean,
      default: false
    },
    datePickerValue: {
      type: String,
      default: todayAsDate()
    },
    minimumDateValue: {
      type: String,
      required: false
    },
    maximumDateValue: {
      type: String,
      required: false
    },
    isTextFieldDense: {
      type: Boolean,
      default: false
    },
    textFieldFormat: {
      type: String,
      default: "dddd, MMMM D, YYYY"
    },
    prependInnerIcon: {
      type: String,
      default: undefined
    },
    isClearEnabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    disallowedDates: {
      type: Array,
      required: false,
      default: () => []
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    textFieldValue() {
      if (this.datePickerValue === undefined || this.datePickerValue === null) return this.datePickerValue
      dayjs.extend(localizedFormat)
      return dayjs(this.datePickerValue).format(this.textFieldFormat)
    },
    getClearIcon() {
      return this.isClearEnabled ? "mdi-close-circle" : "mdi-blank"
    }
  },
  methods: {
    clearDate() {
      this.emitUpdatedPickerValue(null)
    },
    emitUpdatedPickerValue(date) {
      this.$emit("updatedPickerValue", formatAsDateOnly(date))
    },
    getAllowedDates(value) {
      return this.disallowedDates.indexOf(value) === -1
    }
  }
}
</script>

<style scoped>
.v-text-field {
  min-width: 175px;
}
</style>
