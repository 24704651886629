<template>
  <v-container fluid>
    <base-dialog ref="job-abandonment-reason-dialog-root" :is-dialog-visible="isDialogVisible" dialog-image="mdi-cancel" title="Abandon Job?" @close-dialog="closeDialog">
      <template v-slot:content>
        <v-form ref="job-abandonment-reason-dialog-form" v-model="isJobAbandonmentReasonDialogFormValid">
          <v-row dense>
            <v-autocomplete
              v-model="selectedAbandonmentReasonId"
              ref="job-abandonment-reason-dialog-selected-reason"
              :items="getAbandonmentReasons"
              label="Selected Reason"
              item-value="id"
              item-text="name"
              :disabled="getIsLoadingJob"
              :rules="abandonmentReasonValidationRules"
              validate-on-blur
              outlined
              flat
              clearable
            >
            </v-autocomplete>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="job-abandonment-reason-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn
          class="pa-4"
          ref="job-abandonment-reason-dialog-confirm-btn"
          color="primary"
          @click="confirmDialog"
          :loading="getIsLoadingJob"
          :disabled="getIsLoadingJob"
          text
          rounded
          ripple
        >
          Confirm</v-btn
        >
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job";

export default {
  name: "JobAbandonmentReasonDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAbandonmentReasonId: undefined,
      isJobAbandonmentReasonDialogFormValid: false,
      abandonmentReasonValidationRules: [value => !!value || "A Abandonment Reason is Required."]
    }
  },
  methods: {
    ...mapActions(useJobStore, ["fetchAbandonmentReasons"]),
    confirmDialog() {
      if (this.$refs["job-abandonment-reason-dialog-form"].validate()) {
        this.$emit("confirmDialog", this.selectedAbandonmentReasonId)
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useJobStore, ["getAbandonmentReasons", "getIsLoadingJob"])
  },
  async created() {
    await this.fetchAbandonmentReasons()
  }
}
</script>

<style scoped></style>
