<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Customer Search
        <div class="ms-1" />
        <base-tool-tip
          class="ps-4 ms-4"
          dialog-title="Customer Search Criteria"
          icon-color="primary"
          tooltip-color="primary"
          tooltip-icon="mdi-information-outline"
          :is-bottom-dialog="true"
          :nudge-dialog="this.$vuetify.breakpoint.mobile ? '80' : '150'"
        >
          <template v-slot:content>
            <div>In order to perform a search, please provide <em>at least one</em> search parameter.</div>
            <div><strong>NOTE: </strong>All searches are contains searches.</div>
            <div><strong>For Job Number Search:</strong></div>
            <div>
              <ul>
                <li>Exact Search Format: <em>FranchiseNumber-JobNumber</em> - Example: <em>0001-1</em></li>
                <li>Contains Search Format: <em>JobNumber</em> - Example: <em>1</em></li>
              </ul>
            </div>
          </template>
        </base-tool-tip>
      </v-card-title>
      <v-card-text>
        <v-form class="mb-8" ref="customer-search-form" v-model="isCustomerSearchFormValid">
          <v-row dense class="justify-space-around">
            <v-col cols="auto" class="flex-fill">
              <v-text-field
                label="Last Name"
                :value="getSearchParamLastName"
                :disabled="getIsLoadingCustomers"
                :rules="lastNameRule"
                @input="setSearchParamLastName"
                @keyup.enter="fetchCustomers"
                clearable
                outlined
              />
            </v-col>
            <v-col cols="auto" class="flex-fill">
              <v-text-field
                label="First Name"
                :value="getSearchParamFirstName"
                :disabled="getIsLoadingCustomers"
                :rules="atLeastOneSearch"
                @input="setSearchParamFirstName"
                @keyup.enter="fetchCustomers"
                clearable
                outlined
              />
            </v-col>
            <v-col cols="auto" class="flex-fill">
              <v-text-field
                label="Business Name"
                :value="getSearchParamBusinessName"
                :disabled="getIsLoadingCustomers"
                :rules="atLeastOneSearch"
                @input="setSearchParamBusinessName"
                @keyup.enter="fetchCustomers"
                clearable
                outlined
              />
            </v-col>
            <v-col cols="auto" class="flex-fill">
              <v-text-field
                label="Email Address"
                :value="getSearchParamEmail"
                :disabled="getIsLoadingCustomers"
                :rules="emailRule"
                @input="setSearchParamEmail"
                @keyup.enter="fetchCustomers"
                clearable
                outlined
              />
            </v-col>
            <v-col cols="auto" class="flex-fill">
              <v-text-field
                label="Phone Number"
                :value="getSearchParamPhone"
                :disabled="getIsLoadingCustomers"
                :rules="phoneRule"
                @input="setSearchParamPhone"
                @keyup.enter="fetchCustomers"
                clearable
                outlined
              />
            </v-col>
            <v-col cols="auto" class="flex-fill">
              <v-text-field
                label="Job Number"
                :value="getSearchParamJobNumber"
                :disabled="getIsLoadingCustomers"
                :rules="atLeastOneSearch"
                @input="setSearchParamJobNumber"
                @keyup.enter="fetchCustomers"
                clearable
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col cols="auto" class="mt-1 flex-column d-flex flex-fill justify-lg-start d-inline-flex">
              <v-btn class="fill-height" color="primary" @click="fetchCustomers" :disabled="getIsLoadingCustomers" large ripple rounded>
                Search
                <v-icon class="ms-2">mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-responsive class="overflow-y-auto" max-height="800" min-height="200">
            <v-data-table
              class="flex-fill row-pointer"
              item-key="id"
              style="max-height: 600px"
              mobile-breakpoint="900"
              :headers="customerSearchHeaders"
              :items="getCustomers"
              :items-per-page="getCustomerPaginationOptions.pageSize"
              :server-items-length="getCustomerPaginationOptions.totalCount"
              :page.sync="getCustomerPaginationOptions.currentPage"
              :loading="getIsLoadingCustomers"
              loading-text="🔎 Fetching Customers 🔍"
              show-expand
              single-expand
              hide-default-footer
            >
              <template v-slot:no-data>
                <v-col class="ma-0 pa-0 align-self-center d-flex">
                  <app-not-found title="No Results" content="Please Specify Your Desired Parameters and Select 'Search'.">
                    <template v-slot:actions><div /></template>
                  </app-not-found>
                </v-col>
              </template>
              <template v-slot:item.primaryContactName="{ value }">
                <span class="text-md-body-1">{{ value }}</span>
              </template>
              <template v-slot:item.businessName="{ value }">
                <span class="text-md-body-1">{{ value }}</span>
              </template>
              <template v-slot:item.phonePrimary="{ item }">
                <span class="text-md-body-1">{{ formatPhoneNumber(item.phonePrimary) }}</span>
              </template>
              <template v-slot:item.email="{ value }">
                <span class="text-md-body-1">{{ value }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="justify-center">
                  <v-btn
                    color="primary"
                    ref="customer-create-job-btn"
                    rounded
                    @click="promptCreateJobFranchiseSelectorDialogOrCreateJob(item.id)"
                    :loading="getIsLoadingCreateJob"
                    :disabled="isCreateJobButtonsDisabled"
                    >Create Job
                    <v-icon class="ps-2">mdi-receipt-text-plus-outline</v-icon>
                  </v-btn>
                </div>
              </template>
              <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                <td v-if="item.jobs.length > 0" class="text-start">
                  <v-btn icon @click="expand(!isExpanded)" class="v-data-table__expand-icon" :class="{ 'v-data-table__expand-icon--active': isExpanded }">
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </td>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td class="pa-0" :colspan="headers.length">
                  <v-responsive class="overflow-y-auto" max-height="350">
                    <v-data-table
                      data-cy="truck-utilization-rates-subtable"
                      no-data-text="There are no jobs associated with this customer."
                      sort-by="jobId"
                      mobile-breakpoint="900"
                      style="width: 85vw"
                      :headers="jobHeaders"
                      :items="item.jobs"
                      :sort-desc="true"
                      :dark="!$vuetify.breakpoint.mobile"
                      :item-class="getItemBackgroundColor"
                      fixed-header
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:item.franchiseName="{ value }">
                        <span class="text-md-body-1 grey--text">{{ value }}</span>
                      </template>
                      <template v-slot:item.businessName="{ item }">
                        <span>{{ item.franchiseName }}</span>
                      </template>
                      <template v-slot:item.scheduledDate="{ value }">
                        <span class="text-md-body-1 grey--text">{{ value }}</span>
                      </template>
                      <template v-slot:item.jobNumber="{ item }">
                        <router-link class="text-md-body-1" :to="{ name: 'Job', params: { jobId: item.jobId } }">
                          {{ item.jobNumber }}
                        </router-link>
                        <v-icon color="primary" class="ms-2" @click="addNewJobTab(item)">
                          mdi-pin-outline
                        </v-icon>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <div class="fill-height" :style="{ backgroundColor: determineBackgroundColor(item.status) }">
                          <v-row class="my-1 px-4 fill-height align-center">
                            <v-col>
                              <span class="text-md-body-1 white--text">{{ item.status }}</span>
                            </v-col>
                          </v-row>
                        </div>
                      </template>
                    </v-data-table>
                  </v-responsive>
                </td>
              </template>
            </v-data-table>
          </v-responsive>
        </v-row>
        <v-row class="mx-4">
          <v-col cols="auto" class="col-lg-2 col-md-3">
            <v-row>
              <v-col class="col-6 col-lg-8 ps-0">
                <v-select
                  ref="job-management-items-per-page-selection"
                  class="ps-0 pt-4 col-6 col-lg-8 cursor_pointer"
                  label="Items Per Page"
                  :items="getItemsPerPageOptions"
                  :value="getCustomerPaginationOptions.pageSize"
                  :disabled="getIsLoadingCustomers"
                  @change="setPageSize"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="justify-center align-content-center text-center align-self-center mb-4">
            <v-row class="justify-center align-content-center text-center align-self-center">
              <div class="text-center">
                <v-pagination
                  class="text-center pt-4 justify-center align-content-center text-center align-self-center"
                  :value="getCustomerPaginationOptions.currentPage"
                  :length="getCustomerPaginationOptions.totalPages"
                  :disabled="getIsLoadingCustomers"
                  @input="triggerPagination"
                />
                <div v-if="isResultRangeTextVisible" ref="customer-search-result-range-text" class="text-md-body-1">{{ getResultRangeText }}</div>
              </div>
            </v-row>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.smAndUp" cols="auto" class="col-lg-2 col-md-3" />
        </v-row>
      </v-card-text>
    </v-card>

    <create-customer-dialog
      v-if="isCreateCustomerDialogVisible"
      :is-dialog-visible="isCreateCustomerDialogVisible"
      @confirm="createCustomerAndJob($event.createCustomerDto, $event.selectedOperatingUnitId)"
      @createJob="createJob($event.customerId, $event.franchiseId)"
      @closeDialog="setIsCreateCustomerDialogVisible(false)"
    />

    <app-create-job-franchise-selector
      v-if="isCreateJobFranchiseSelectorDialogVisible"
      :is-dialog-visible="isCreateJobFranchiseSelectorDialogVisible"
      :is-loading="isLoadingAppCreateJobFranchiseSelector"
      :customer-id="selectedCustomerId"
      @save="createJob(selectedCustomerId, $event.franchiseId)"
      @closeDialog="setIsCreateJobFranchiseSelectorDialogVisible(false, null)"
    />

    <base-fab ref="customer-fab" :is-visible="true" :fab-options="fabOptions" />
  </v-container>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip"
import AppNotFound from "@/components/AppNotFound"
import CreateCustomerDialog from "@/components/CreateCustomerDialog"
import AppCreateJobFranchiseSelector from "@/components/AppCreateJobFranchiseSelector"
import BaseFab from "@/components/BaseFab"
import phoneNumberFormatter from "@/utils/PhoneNumberFormatter"
import { JobTab } from "@/models/JobTab"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { mapState, mapActions } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useCustomerStore } from "@/stores/Customer"

export default {
  name: "Customer",
  components: { BaseToolTip, AppNotFound, CreateCustomerDialog, AppCreateJobFranchiseSelector, BaseFab },
  data() {
    return {
      isCustomerSearchFormValid: false,
      isCreateCustomerDialogVisible: false,
      isCreateJobFranchiseSelectorDialogVisible: false,
      selectedCustomerId: null,
      customerSearchHeaders: [
        { text: "Customer Name", align: "start", value: "primaryContactName" },
        { text: "Business Name", align: "start", value: "businessName" },
        { text: "Phone Number", align: "start", value: "phonePrimary" },
        { text: "Email Address", align: "start", value: "email" },
        { text: "Actions", align: "center", value: "actions", width: "5%" }
      ],
      jobHeaders: [
        { text: "Franchise", align: "start", value: "franchiseName", class: "secondaryDark white--text" },
        { text: "Scheduled Date", align: "start", value: "scheduledDate", class: "secondaryDark white--text" },
        { text: "Job Number", align: "start", value: "jobNumber", class: "secondaryDark white--text" },
        { text: "Job Status", align: "start", value: "status", class: "secondaryDark white--text" }
      ],
      phoneRule: [],
      emailRule: [],
      lastNameRule: [],
      atLeastOneSearch: []
    }
  },
  computed: {
    ...mapState(useMainStore, ["getIsLoadingUserActiveFranchises", "getUserActiveFranchises"]),
    ...mapState(useCustomerStore, [
      "getCustomers",
      "getIsLoadingCustomers",
      "getIsLoadingCreateJob",
      "getCustomerPaginationOptions",
      "getItemsPerPageOptions",
      "getSearchParamFirstName",
      "getSearchParamLastName",
      "getSearchParamPhone",
      "getSearchParamEmail",
      "getSearchParamBusinessName",
      "getSearchParamJobNumber"
    ]),
    fabOptions() {
      return [
        {
          icon: "mdi-account-plus-outline",
          onClickAction: () => this.setIsCreateCustomerDialogVisible(true),
          text: "Create Customer",
          isFabDisabled: this.getIsLoadingCustomers
        }
      ]
    },
    isLoadingAppCreateJobFranchiseSelector() {
      return this.getIsLoadingUserActiveFranchises || this.getIsLoadingCreateJob
    },
    isResultRangeTextVisible() {
      return this.getCustomers?.length > 0
    },
    getResultRangeText() {
      return `Displaying ${this.lowerLimitText} - ${this.upperLimitText} of ${this.getCustomerPaginationOptions.totalCount}`
    },
    lowerLimitText() {
      return (this.getCustomerPaginationOptions.currentPage - 1) * this.getCustomerPaginationOptions.pageSize + 1
    },
    upperLimitText() {
      let currentPageTimesPageSize = this.getCustomerPaginationOptions.currentPage * this.getCustomerPaginationOptions.pageSize
      return currentPageTimesPageSize > this.getCustomerPaginationOptions.totalCount ? this.getCustomerPaginationOptions.totalCount : currentPageTimesPageSize
    },
    isCreateJobButtonsDisabled() {
      return this.getIsLoadingCustomers || this.getIsLoadingCreateJob
    }
  },
  methods: {
    ...mapActions(useMainStore, ["fetchActiveFranchisesForUserByOperatingUnitIds", "addJobTab"]),
    ...mapActions(useCustomerStore, [
      "getCustomerById",
      "searchCustomers",
      "createJunkJob",
      "createCustomer",
      "setSearchParamFirstName",
      "setSearchParamLastName",
      "setSearchParamPhone",
      "setSearchParamEmail",
      "setSearchParamBusinessName",
      "setSearchParamJobNumber"
    ]),
    promptCreateJobFranchiseSelectorDialogOrCreateJob(customerId) {
      if (this.getUserActiveFranchises.length === 1) {
        this.createJob(customerId, this.getUserActiveFranchises[0].franchiseId)
      } else {
        this.setIsCreateJobFranchiseSelectorDialogVisible(true, customerId)
      }
    },
    async addNewJobTab(item) {
      let customer = this.getCustomerById(item.customerId)
      let tab = new JobTab(item.jobId, item.jobNumber, item.customerId, customer.primaryContactLastName, customer.businessName)
      await this.addJobTab(tab)
    },
    setIsCreateJobFranchiseSelectorDialogVisible(isVisible, customerId) {
      this.selectedCustomerId = customerId
      this.isCreateJobFranchiseSelectorDialogVisible = isVisible
    },
    determineBackgroundColor(statusName) {
      return getJobStatusColorByStatusName(statusName)
    },
    getItemBackgroundColor() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    },
    formatPhoneNumber(phoneNumber) {
      return phoneNumberFormatter.formatPhoneNumber(phoneNumber)
    },
    setIsCreateCustomerDialogVisible(isVisible) {
      this.isCreateCustomerDialogVisible = isVisible
    },
    setSearchRules() {
      this.atLeastOneSearch = [
        () =>
          this.getSearchParamFirstName?.trim()?.length >= 1 ||
          this.getSearchParamLastName?.trim()?.length >= 1 ||
          this.getSearchParamBusinessName?.trim()?.length >= 1 ||
          this.getSearchParamEmail?.trim()?.length >= 1 ||
          this.getSearchParamPhone?.trim()?.length >= 1 ||
          this.getSearchParamJobNumber?.trim()?.length >= 1 ||
          "One field required to search."
      ]

      this.lastNameRule = this.atLeastOneSearch.slice()
      if ((this.getSearchParamFirstName ?? "").trim() !== "") {
        const firstNameRequiresLastNameRule = value => (value ?? "").trim().length >= 2 || "Searching by first name requires last name with two characters."
        this.lastNameRule.push(firstNameRequiresLastNameRule)
      }

      if ((this.getSearchParamLastName ?? "").trim() !== "") {
        const lastNameRequiresTwoCharactersRule = value => (value ?? "").trim().length >= 2 || "Searching by last name requires at least two characters."
        this.lastNameRule.push(lastNameRequiresTwoCharactersRule)
      }

      this.phoneRule = this.atLeastOneSearch.slice()
      if ((this.getSearchParamPhone ?? "").trim() !== "") {
        const fourNumberPhoneRule = value => value.length >= 4 || "Phone search requires at least 4 digits."
        this.phoneRule.push(fourNumberPhoneRule)
      }

      this.emailRule = this.atLeastOneSearch.slice()
      if ((this.getSearchParamEmail ?? "").trim() !== "") {
        const fourCharacterEmailRule = value => value.length >= 4 || "Email search requires at least 4 characters."
        this.emailRule.push(fourCharacterEmailRule)
      }
    },
    async fetchCustomers() {
      this.setSearchRules()
      await this.$nextTick(async () => {
        if (this.$refs["customer-search-form"].validate()) {
          await this.searchCustomers({ pageNumber: 1, pageSize: this.getCustomerPaginationOptions.pageSize }).then(() => {
            this.$refs["customer-search-form"].resetValidation()
          })
        }
      })
    },
    async triggerPagination(pageNumber) {
      this.setSearchRules()
      await this.$nextTick(async () => {
        if (this.$refs["customer-search-form"].validate()) {
          await this.searchCustomers({ pageNumber: pageNumber, pageSize: this.getCustomerPaginationOptions.pageSize }).then(() => {
            this.$refs["customer-search-form"].resetValidation()
          })
        }
      })
    },
    async setPageSize(pageSize) {
      this.setSearchRules()
      await this.$nextTick(async () => {
        if (this.$refs["customer-search-form"].validate()) {
          await this.searchCustomers({ pageNumber: 1, pageSize: pageSize }).then(() => {
            this.$refs["customer-search-form"].resetValidation()
          })
        }
      })
    },
    async createJob(customerId, franchiseId) {
      await this.createJunkJob({
        customerId: customerId,
        franchiseId: franchiseId,
        createdBy: this.$msal.getCurrentUserId(),
        scheduledDate: todayAsDate(),
        jobCreationOriginId: this.getJobCreationOriginId()
      }).then(data => {
        this.setIsCreateJobFranchiseSelectorDialogVisible(false, null)
        this.setIsCreateCustomerDialogVisible(false)
        this.navigateToJobView(data.id)
      })
    },
    getJobCreationOriginId() {
      if (this.$msal.getActiveUserRoles().includes(JobCreationOrigin.SALES_SUPPORT.adName)) {
        return JobCreationOrigin.SALES_SUPPORT.id
      } else if (this.$msal.getActiveUserRoles().includes(JobCreationOrigin.CSR.adName)) {
        return JobCreationOrigin.CSR.id
      } else if (this.$msal.getActiveUserRoles().includes(JobCreationOrigin.HAULER.adName)) {
        return JobCreationOrigin.HAULER.id
      } else if (this.$msal.getActiveUserRoles().includes(JobCreationOrigin.FRANCHISE_OWNER.adName)) {
        return JobCreationOrigin.FRANCHISE_OWNER.id
      } else if (this.$msal.getActiveUserRoles().includes(JobCreationOrigin.JUNK_MANAGER.adName)) {
        return JobCreationOrigin.JUNK_MANAGER.id
      } else if (this.$msal.getActiveUserRoles().includes(JobCreationOrigin.CORPORATE_ADMINISTRATOR.adName)) {
        return JobCreationOrigin.CORPORATE_ADMINISTRATOR.id
      } else {
        return -1
      }
    },
    async createCustomerAndJob(createCustomerDto) {
      createCustomerDto.jobCreationOriginId = this.getJobCreationOriginId()
      await this.createCustomer(createCustomerDto).then(jobId => {
        this.setIsCreateCustomerDialogVisible(false)
        this.navigateToJobView(jobId)
      })
    },
    navigateToJobView(jobId) {
      this.$router.push({ name: "Job", params: { jobId: jobId } })
    }
  },
  async created() {
    if (this.getUserActiveFranchises.length === 0) await this.fetchActiveFranchisesForUserByOperatingUnitIds()
  }
}
</script>
