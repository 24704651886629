<template>
  <v-layout>
    <v-card class="flex d-flex flex-column flex-fill fill-height elevation-4" :loading="isLoadingCustomerCommunicationsCard" style="min-width: 150px; min-height: 200px">
      <v-card-title class="headline">Communications</v-card-title>
      <v-col>
        <v-card-text class="my-0 py-0 align-end">
          <v-row class="justify-start">
            <v-col ref="job-customer-communications-card-send-booking-confirmation" v-if="isSendBookingConfirmationAvailable" cols="auto">
              <v-btn
                ref="job-customer-communications-card-send-booking-confirmation-button"
                :disabled="isSendBookingConfirmationButtonDisabled"
                @click="handleSend(customerCommunications.BOOKING_CONFIRMATION.id)"
              >
                Confirmation
                <v-icon class="ms-2">mdi-calendar-check</v-icon>
              </v-btn>
            </v-col>
            <v-col ref="job-customer-communications-card-send-driver-eta" v-if="isCustomerTextAvailable" cols="auto">
              <v-btn
                ref="job-customer-communications-card-send-driver-eta-button"
                :disabled="isSendDriverEtaButtonDisabled"
                @click="handleSend(customerCommunications.DRIVER_ETA.id)"
              >
                On The Way
                <v-icon class="ms-2">mdi-clock-time-three-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                ref="job-customer-communications-card-send-job-statement-button"
                :disabled="isSendJobStatementButtonDisabled"
                @click="handleSend(customerCommunications.JOB_STATEMENT.id)"
              >
                Statement
                <v-icon class="ms-2">mdi-file-document-plus-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row ref="job-customer-communications-card-initial-contact" v-if="shouldDisplayHandleLeadContactMarker">
            <v-col>
              <v-checkbox ref="job-customer-communications-card-initial-contact-checkbox" label="Initial Contact" :key="leadHandledKey" @click="handleLeadContactMarked" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-card>
  </v-layout>
</template>

<script>
import { CustomerCommunications } from "@/enums/CustomerCommunications"
import { mapState } from "pinia"
import { useJobStore } from "@/stores/Job"
import { JobCreationOrigin } from "@/enums/JobCreationOrigin"

export default {
  name: "JobCustomerCommunicationsCard",
  props: {
    leadHandledKey: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleSend(communicationType) {
      this.$emit("handle-send", communicationType)
    },
    handleLeadContactMarked() {
      this.$emit("handle-lead-contact-marked")
    }
  },
  computed: {
    customerCommunications() {
      return CustomerCommunications
    },
    ...mapState(useJobStore, [
      "getIsJobStatusScheduled",
      "getIsJobStatusClaimed",
      "getPrimaryContactDetails",
      "getSelectedFranchise",
      "getJobDocumentReceiptInfo",
      "getIsLoadingCustomerCommunication",
      "getIsLoadingJobDocuments",
      "getCurrentJob"
    ]),
    isLoadingCustomerCommunicationsCard() {
      return this.getIsLoadingCustomerCommunication || this.getIsLoadingJobDocuments
    },
    hasReceipt() {
      return Object.keys(this.getJobDocumentReceiptInfo).length !== 0
    },
    isSendBookingConfirmationButtonDisabled() {
      return this.getIsLoadingCustomerCommunication || this.getIsLoadingJobDocuments || (!this.getIsJobStatusScheduled && !this.getIsJobStatusClaimed) || this.hasReceipt
    },
    isSendDriverEtaButtonDisabled() {
      return this.getIsLoadingCustomerCommunication || this.getIsLoadingJobDocuments || !this.getIsJobStatusClaimed || this.hasReceipt
    },
    isSendJobStatementButtonDisabled() {
      return this.getIsLoadingCustomerCommunication || this.getIsLoadingJobDocuments || !this.getJobDocumentReceiptInfo.isGenerated
    },
    isCustomerTextAvailable() {
      return this.getSelectedFranchise.isDialpadEnabled && this.getPrimaryContactDetails.isPrimaryPhoneMobile && this.getPrimaryContactDetails.isPrimaryPhoneValid
    },
    isCustomerEmailAvailable() {
      return (
        this.getPrimaryContactDetails.emailPrimary !== "" &&
        this.getPrimaryContactDetails.emailPrimary !== null &&
        this.getPrimaryContactDetails.emailPrimary !== undefined &&
        this.getPrimaryContactDetails.refusedToProvideEmail === false
      )
    },
    isSendBookingConfirmationAvailable() {
      return this.isCustomerEmailAvailable || this.isCustomerTextAvailable
    },
    shouldDisplayHandleLeadContactMarker() {
      return this.getCurrentJob.jobCreationOriginId === JobCreationOrigin.CUSTOMER.id && !this.getCurrentJob.isLeadHandled && this.getIsJobStatusScheduled
    }
  }
}
</script>
