import { defineStore } from "pinia"

export const useSnackbarStore = defineStore("snackbar", {
  state: () => ({
    snackbars: []
  }),
  getters: {
    getSnackbars() {
      return this.snackbars
    }
  },
  actions: {
    addSnackbar(snackbar) {
      this.snackbars.push(snackbar)
    },
    removeSnackbar(id) {
      this.snackbars = this.snackbars.filter(s => {
        return s.id !== id
      })
    }
  }
})
