<template>
  <base-dialog ref="settings-create-tax-dialog-root" :is-dialog-visible="isDialogVisible" title="Create Tax" dialog-image="mdi-cash-plus" @close-dialog="closeDialog">
    <template v-slot:content>
      <v-form ref="settings-create-tax-dialog-form" v-model="isFormValid">
        <v-layout row>
          <v-text-field ref="settings-create-tax-dialog-name" outlined label="Name" counter="50" v-model="name" :rules="isRequiredRules" />
        </v-layout>
        <v-layout row>
          <v-select
            ref="settings-create-tax-dialog-franchise-selector"
            label="Selected Franchises"
            v-model="selectedFranchises"
            :items="getFranchisesInOperatingUnit"
            item-text="franchiseNameAndNumber"
            item-value="franchiseId"
            :rules="isRequiredSelectorRules"
            multiple
            outlined
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-layout>
        <v-layout row>
          <v-text-field ref="settings-create-tax-dialog-rate" outlined label="Rate" counter="50" type="number" suffix="%" :rules="rateRules" v-model="rate" />
        </v-layout>
        <v-layout row>
          <app-date-picker-field-selector
            ref="settings-create-tax-dialog-begin-date-selector"
            :minimum-date-value="minimumDate"
            :date-picker-value="beginDateTime"
            @updatedPickerValue="setBeginDateTime($event)"
            text-field-label="Begin Date"
            :text-field-disabled="false"
          />
        </v-layout>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" color="primaryText" ref="settings-create-tax-dialog-cancel-btn" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" color="primary" ref="settings-create-tax-dialog-confirm-btn" @click="confirm" :disabled="getIsLoadingFranchiseTax" :loading="getIsLoadingFranchiseTax" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { createFranchiseTaxDto } from "@/api/dtos/JunkDtos"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsCreateTaxDialog",
  components: { AppDatePickerFieldSelector, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: false,
      minimumDate: todayAsDate(),
      beginDateTime: todayAsDate(),
      name: "",
      rate: 0,
      selectedFranchises: [],
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."],
      rateRules: [
        value => !!value || "Rate is required.",
        value => value >= 0 || "Rate must be positive.",
        value => value <= 100.00 || "Rate must be less than or equal to 100.00."
      ]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["createFranchiseTax"]),
    setBeginDateTime(event) {
      this.beginDateTime = event
    },
    async confirm() {
      if (this.$refs["settings-create-tax-dialog-form"].validate()) {
        const franchiseTaxDto = createFranchiseTaxDto(this.selectedFranchises, this.name, this.rate, this.beginDateTime, this.$msal.getCurrentUserId())
        await this.createFranchiseTax(franchiseTaxDto).then(() => {
          this.closeDialog()
        })
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getFranchisesInOperatingUnit", "getIsLoadingFranchiseTax"])
  }
}
</script>

<style scoped></style>
