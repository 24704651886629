<template>
  <base-dialog ref="app-edit-customer-dialog-root" title="Edit Customer" :is-dialog-visible="isDialogVisible" dialog-image="mdi-account-plus-outline">
    <template v-slot:content>
      <v-form ref="app-edit-customer-dialog-form" v-model="isFormValid" lazy-validation>
        <v-layout row>
          <v-select
            class="cursor_pointer"
            ref="app-edit-customer-dialog-customer-type-selector"
            label="Customer Type *"
            item-text="name"
            item-value="id"
            :items="getCustomerTypes"
            v-model="mutableCustomerType"
            outlined
            :disabled="isCustomerTypeDisabled"
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-layout>
        <v-layout v-if="isBusinessesSelected" row>
          <v-text-field v-model="mutableBusinessName" ref="app-edit-customer-dialog-business-name" label="Business Name" outlined counter="100" :rules="businessNameRule" />
        </v-layout>
        <v-row v-if="isBusinessesSelected">
          <v-col cols="auto" class="ms-0 ps-0 mb-n4">
            <v-checkbox class="mt-n2" ref="app-edit-contact-dialog-tax-exempt-checkbox" :disabled="isTaxExemptDisabled" v-model="mutableIsTaxExempt" label="Tax Exempt" />
          </v-col>
        </v-row>
        <v-row v-if="isBusinessesSelected" class="align-baseline">
          <v-col class="ms-0 ps-0">
            <v-text-field
              ref="app-edit-contact-dialog-ein-text"
              placeholder="XX-XXXXXXX"
              :value="mutableEin"
              @input="formatEin"
              :rules="einRule"
              validate-on-blur
              label="Employer Identification Number"
              counter="10"
              outlined
            />
          </v-col>
        </v-row>
        <v-row v-if="isBusinessesSelected" class="ms-n6 mt-n8">
          <v-col>
            <v-col cols="auto" class="ms-0 ps-0">
              <v-btn ref="app-edit-contact-dialog-validate-ein-btn" elevation="1" color="primary" outlined :disabled="isCharityPreflightButtonDisabled" @click="validateEinWrapper">Validate Charity</v-btn>
              <v-icon ref="app-edit-contact-dialog-validate-charity-icon" class="ms-4" :color="charityIconColor">mdi-charity</v-icon>
            </v-col>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="app-edit-customer-dialog-cancel-btn" color="primaryText" @click="closeDialog" :disabled="false" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="app-edit-customer-dialog-confirm-btn" color="primary" @click="confirm" :loading="getIsLoadingUpdateCustomerDetails" :disabled="isConfirmDisabled" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { CustomerType } from "@/enums/CustomerTypes"
import { putCustomerDetailsDto } from "@/api/dtos/JunkDtos"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job"
import { useMainStore } from "@/stores/Main"

export default {
  name: "AppEditCustomerDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFormValid: false,
      businessNameRule: [value => (!!value && !!value.trim()) || "Is required."],
      isRequiredSelectorRules: [value => (!!value && value.length > 0) || "Is required."],
      mutableCustomerType: CustomerType.HOME.id,
      mutableBusinessName: "",
      mutableIsTaxExempt: false,
      mutableEin: "",
      originalEin: "",
      preflightEin: "",
      isCharityPreflightValidated: false,
      einRule: [v => this.mutableEin.trim().length < 1 || /^\d{2}-\d{7}$/.test((v ?? "").trim()) || "EIN must be in the format XX-XXXXXXX"]
    }
  },
  methods: {
    ...mapActions(useMainStore, ["validateEmployerIdentificationNumber"]),
    ...mapActions(useJobStore, ["fetchJunkJobByJobId", "updateCustomerDetails"]),
    initProperties() {
      this.mutableCustomerType = this.getCustomerContactsWithAddresses.isBusiness ? CustomerType.BUSINESS.id : CustomerType.HOME.id
      this.mutableBusinessName = this.getCustomerContactsWithAddresses.businessName
      this.mutableIsTaxExempt = this.getCustomerContactsWithAddresses.isTaxExempt
      let ein = this.getCustomerContactsWithAddresses.employerIdentificationNumber ?? ""
      this.originalEin = ein
      this.mutableEin = ein
    },
    formatEin(value) {
      let numericValue = value.replace(/\D/g, "") // Remove all non-numeric characters
      if (numericValue.length > 9) {
        numericValue = numericValue.slice(0, 9) // Ensure max length of 9 digits
      }
      if (numericValue.length >= 2) {
        this.mutableEin = numericValue.slice(0, 2) + "-" + numericValue.slice(2)
      } else {
        this.mutableEin = numericValue
      }
    },
    async validateEinWrapper() {
      this.preflightEin = this.mutableEin
      await this.validateEmployerIdentificationNumber(this.mutableEin).then(isCharity => {
        this.isCharityPreflightValidated = isCharity
        this.mutableIsTaxExempt = isCharity
      })
    },
    async confirm() {
      if (this.$refs["app-edit-customer-dialog-form"].validate()) {
        let originalTaxExemptionStatus = this.getCustomerContactsWithAddresses.isTaxExempt
        let cleanBusinessName = this.mutableCustomerType === CustomerType.BUSINESS.id ? this.mutableBusinessName?.trim() : null
        let cleanIsTaxExempt = this.mutableCustomerType === CustomerType.BUSINESS.id ? this.mutableIsTaxExempt : false
        const dto = putCustomerDetailsDto(this.getCustomerContactsWithAddresses.customerId, this.mutableCustomerType, cleanBusinessName, cleanIsTaxExempt, this.mutableEin, this.$msal.getCurrentUserId())

        await this.updateCustomerDetails(dto).then(customerDetails => {
          if (originalTaxExemptionStatus !== customerDetails.isTaxExempt) { // avoid extra round trip, if not needed
            this.fetchJunkJobByJobId(this.getCurrentJob.id)
          }
          this.closeDialog()
        })
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useJobStore, ["getCurrentJob", "getCustomerContactsWithAddresses", "getIsLoadingUpdateCustomerDetails"]),
    ...mapState(useMainStore, ["getCustomerTypes", "getIsLoadingValidateEin"]),
    hasEinChanged() {
      return this.originalEin !== this.mutableEin
    },
    isCharity() {
      return this.hasEinChanged ? this.isPreflightCharity : this.getCustomerContactsWithAddresses.isCharity
    },
    isPreflightCharity() {
      return this.preflightEin !== this.mutableEin ? false : this.isCharityPreflightValidated
    },
    charityIconColor() {
      return this.isCharity ? "primary" : "background"
    },
    isBusinessesSelected() {
      return this.mutableCustomerType === CustomerType.BUSINESS.id
    },
    isCustomerTypeDisabled() {
      return !this.getCustomerContactsWithAddresses.isCustomerTypeEditable
    },
    isCharityPreflightButtonDisabled() {
      return this.originalEin === this.mutableEin || this.getIsLoadingValidateEin === true || !/^\d{2}-\d{7}$/.test(this.mutableEin)
    },
    isTaxExemptDisabled() {
      return !!this.mutableEin || this.getIsLoadingValidateEin
    },
    hasChangedValues() {
      return (
        this.mutableCustomerType !== (this.getCustomerContactsWithAddresses.isBusiness ? CustomerType.BUSINESS.id : CustomerType.HOME.id) ||
        this.mutableBusinessName !== this.getCustomerContactsWithAddresses.businessName ||
        this.mutableIsTaxExempt !== this.getCustomerContactsWithAddresses.isTaxExempt ||
        this.mutableEin !== (this.getCustomerContactsWithAddresses.employerIdentificationNumber ?? "")
      )
    },
    isConfirmDisabled() {
      return !this.hasChangedValues || this.getIsLoadingUpdateCustomerDetails
    }
  },
  created() {
    this.initProperties()
  }
}
</script>
