<template>
  <v-card ref="settings-franchise-tip-options-card" elevation="4" :loading="getIsLoadingTipConfiguration">
    <v-card-title>
      <v-row>
        <v-col>
          <span class="headline word-wrap me-2">Franchise Tip Options</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider class="ma-4" />
    <div class="pa-4 ma-4">
      <v-card-text ref="settings-franchise-tip-options-card-select-franchise-prompt" v-if="!getSelectedFranchise">
        <v-row>
          <v-col class="align-self-center d-flex justify-center">
            <v-card-title class="text-h6 active">
              Select a franchise to configure tip options.
            </v-card-title>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="align-self-center d-flex justify-center">
            <v-btn
              ref="settings-franchise-tip-options-card-franchise-selector-button"
              class="mt-4 primary justify-center align-self-center"
              ripple
              rounded
              large
              @click="emitSelectFranchiseSelector"
            >
              Select Franchise
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text ref="settings-franchise-tip-options-card-container" v-else>
        <v-form class="ma-0 pa-0 mb-8" ref="settings-franchise-tip-options-card-form" v-model="isValid" v-on:submit.prevent="handleApply">
          <v-row class="mt-n12">
            <v-col>
              <div class="checkbox-wrapper">
                <v-checkbox
                    ref="settings-franchise-tip-options-card-tips-enabled-checkbox"
                    class="me-2"
                    label="Enable Customer-Facing Tip Screen"
                    v-model="getTipConfiguration.isEnabled"
                    :disabled="getIsLoadingTipConfiguration"
                    @change="emitIsEnabled"
                >
                  <template ref="settings-franchise-tip-options-card-enabled-slot-label" v-slot:label>
                    <div class="pe-2">
                      Enable Customer-Facing Screen
                    </div>
                    <base-tool-tip
                        ref="settings-franchise-tip-options-card-enabled-tooltip"
                        dialog-title="Display a tip card to customers as part of the job."
                        icon-color="primary"
                        tooltip-icon="mdi-information-outline"
                        tooltip-color="primary"
                        nudge-dialog="0"
                        :is-top-dialog="true"
                    />
                  </template>
                </v-checkbox>
              </div>
              <div ref="settings-franchise-tip-options-card-tips-not-enabled-message" v-if="!getTipConfiguration.isEnabled">
                <v-card-text class="text-h6 active text-center">
                  Tips are not enabled for this franchise.  Customers will not be shown a tip card during the job.
                </v-card-text>
                <v-card-text class="active text-center">
                  Check the box above to enable tips for this franchise.
                </v-card-text>
              </div>
              <v-checkbox
                ref="settings-franchise-tip-options-card-tip-options-enabled-checkbox"
                class="me-4"
                label="Offer Pre-Defined Tip Options"
                v-if="getTipConfiguration.isEnabled"
                v-model="getTipConfiguration.isTipOptionsEnabled"
                :disabled="getIsLoadingTipConfiguration"
                @change="emitIsEnabledTipOptions"
              >
                <template ref="settings-franchise-tip-options-card-options-slot-label" v-slot:label>
                  <div class="pe-2">
                    Offer Pre-Defined Options
                  </div>
                  <base-tool-tip
                      ref="settings-franchise-tip-options-card-options-tooltip"
                      dialog-title="Present pre-defined tip options to customers on the tip card."
                      icon-color="primary"
                      tooltip-icon="mdi-information-outline"
                      tooltip-color="primary"
                      nudge-dialog="0"
                      :is-top-dialog="true"
                  />
                </template>
              </v-checkbox>
              <div ref="settings-franchise-tip-options-card-no-options-message" v-if="isTipsEnabledWithoutOptions">
                <v-card-text class="text-h6 active text-center">
                  Pre-defined tip options are not configured for this franchise.  Customers will only be shown a single tip entry during the job.
                </v-card-text>
                <v-card-text class="active text-center">
                  Check the box above to configure pre-defined tip options for this franchise.
                </v-card-text>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="isTipOptionsVisible" class="flex-fill">
            <v-col>
              <div
                  class="mt-n2 mb-n2 text-sm-caption"
                  :class="{ active: !getIsLoadingTipConfiguration, inActive: getIsLoadingTipConfiguration }">
                Unit *
              </div>
              <v-chip-group
                ref="settings-franchise-tip-options-card-is-percentage-chip-group"
                class="mt-1 fill-height flex-fill"
                color="primary"
                mandatory
                :value="getTipConfiguration.isTipOptionsPercentage"
                @change="emitIsPercentage"
              >
                <v-chip
                  ref="settings-franchise-tip-options-card-is-percentage-chip"
                  label
                  v-for="item in tipUnitChips"
                  outlined
                  :disabled="getIsLoadingTipConfiguration"
                  :value="item.value"
                  :key="item.value"
                  :aria-label="item.label"
                >
                  <v-icon
                      ref="settings-franchise-tip-options-card-is-percentage-chip-icon"
                      class="text-h4">{{ item.icon }}
                  </v-icon>
                </v-chip>
              </v-chip-group>
            </v-col>
            <v-col>
              <v-text-field
                ref="settings-franchise-tip-options-card-tip-option-one-text-input"
                label="Low *"
                type="number"
                class="text-h6 text-center"
                style="min-width: 128px"
                outlined
                v-if="isTipOptionsVisible"
                :value="getTipConfiguration.tipOptionOneAmount"
                :error-messages="getTipOptionOneErrorMessage"
                :prefix="getAmountPrefix"
                :append-icon="getAmountAppend"
                :disabled="getIsLoadingTipConfiguration"
                @focus="selectAllContent"
                @input="emitTipOne"
              />
            </v-col>
            <v-col></v-col>
            <v-col>
              <v-text-field
                ref="settings-franchise-tip-options-card-tip-option-one-text-input"
                label="Mid *"
                type="number"
                class="text-h6 text-center"
                style="min-width: 128px"
                outlined
                v-if="isTipOptionsVisible"
                :value="getTipConfiguration.tipOptionTwoAmount"
                :error-messages="getTipOptionTwoErrorMessage"
                :prefix="getAmountPrefix"
                :append-icon="getAmountAppend"
                :disabled="getIsLoadingTipConfiguration"
                @focus="selectAllContent"
                @input="emitTipTwo"
              />
            </v-col>
            <v-col></v-col>
            <v-col>
              <v-text-field
                ref="settings-franchise-tip-options-card-tip-option-one-text-input"
                label="High *"
                type="number"
                class="text-h6 text-center"
                style="min-width: 128px"
                outlined
                v-if="isTipOptionsVisible"
                :value="getTipConfiguration.tipOptionThreeAmount"
                :error-messages="getTipOptionThreeErrorMessage"
                :prefix="getAmountPrefix"
                :append-icon="getAmountAppend"
                :disabled="getIsLoadingTipConfiguration"
                @focus="selectAllContent"
                @input="emitTipThree"
              />
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-btn
            ref="settings-franchise-tip-options-card-save-button"
            class="pa-4 mb-16 float-right"
            color="primary"
            :disabled="isSaveButtonDisabled"
            @click="saveTipConfiguration"
          >
            Save
          </v-btn>
        </v-form>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import { upsertTipConfigurationDto } from "@/api/dtos/JunkDtos"
import BaseToolTip from "@/components/BaseToolTip"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsFranchiseTipOptionsCard",
  components: { BaseToolTip },
  props: {
    tipConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tipUnitChips: [
        { label: "Dollar", icon: "mdi-currency-usd", value: false },
        { label: "Percentage", icon: "mdi-percent-outline", value: true }
      ],
      valuesChanged: false,
      isValid: false
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["upsertTipConfiguration"]),
    emitSelectFranchiseSelector() {
      this.valuesChanged = false
      this.$emit("selectFranchiseSelector")
    },
    emitIsEnabled(event) {
      this.valuesChanged = true
      this.$emit("update:tipConfig", { isEnabled: event })
      this.$emit("update:tipConfig", { tipOptionOneAmount: parseFloat(15) })
      this.$emit("update:tipConfig", { tipOptionTwoAmount: parseFloat(18) })
      this.$emit("update:tipConfig", { tipOptionThreeAmount: parseFloat(20) })
      this.$emit("update:tipConfig", { isTipOptionsPercentage: event })
    },
    emitIsEnabledTipOptions(event) {
      this.valuesChanged = true
      this.$emit("update:tipConfig", { isTipOptionsEnabled: event })
    },
    emitIsPercentage(event) {
      this.valuesChanged = true
      this.$emit("update:tipConfig", { tipOptionOneAmount: parseFloat(this.getTipConfiguration.tipOptionOneAmount) })
      this.$emit("update:tipConfig", { tipOptionTwoAmount: parseFloat(this.getTipConfiguration.tipOptionTwoAmount) })
      this.$emit("update:tipConfig", { tipOptionThreeAmount: parseFloat(this.getTipConfiguration.tipOptionThreeAmount) })
      this.$emit("update:tipConfig", { isTipOptionsPercentage: event })
    },
    emitTipOne(event) {
      this.valuesChanged = true
      this.$emit("update:tipConfig", { tipOptionOneAmount: parseFloat(event) })
    },
    emitTipTwo(event) {
      this.valuesChanged = true
      this.$emit("update:tipConfig", { tipOptionTwoAmount: parseFloat(event) })
    },
    emitTipThree(event) {
      this.valuesChanged = true
      this.$emit("update:tipConfig", { tipOptionThreeAmount: parseFloat(event) })
    },
    selectAllContent(event) {
      event.target.select()
    },
    async handleApply() {
      await this.saveTipConfiguration()
    },
    async saveTipConfiguration() {
      if (this.$refs["settings-franchise-tip-options-card-form"].validate()) {
        const upsertDto = new upsertTipConfigurationDto(
          this.getSelectedFranchise,
          this.getTipConfiguration.isEnabled,
          this.getTipConfiguration.isTipOptionsEnabled,
          this.getTipConfiguration.isTipOptionsPercentage,
            parseFloat(this.getTipConfiguration.tipOptionOneAmount) ?? 0,
            parseFloat(this.getTipConfiguration.tipOptionTwoAmount) ?? 0,
            parseFloat(this.getTipConfiguration.tipOptionThreeAmount) ?? 0,
          this.$msal.getCurrentUserId()
        )

        await this.upsertTipConfiguration(upsertDto)
            .then(() => {
              this.valuesChanged = false
            })
      }
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getSelectedFranchise", "getTipConfiguration", "getIsLoadingTipConfiguration"]),
    isTipOptionsVisible() {
      return this.getTipConfiguration.isEnabled && this.getTipConfiguration.isTipOptionsEnabled
    },
    isTipsEnabledWithoutOptions() {
      return this.getTipConfiguration.isEnabled && !this.getTipConfiguration.isTipOptionsEnabled
    },
    getAmountPrefix() {
      return this.getTipConfiguration.isTipOptionsPercentage ? "" : "$"
    },
    getAmountAppend() {
      return this.getTipConfiguration.isTipOptionsPercentage ? 'mdi-percent' : ''
    },
    getTipOptionOneFormattedAmount() {
      return this.getTipConfiguration.isTipOptionsPercentage ? this.getTipConfiguration.tipOptionOneAmount * 100 : this.getTipConfiguration.tipOptionOneAmount
    },
    getTipOptionTwoFormattedAmount() {
      return this.getTipConfiguration.isTipOptionsPercentage ? this.getTipConfiguration.tipOptionTwoAmount * 100 : this.getTipConfiguration.tipOptionTwoAmount
    },
    getTipOptionThreeFormattedAmount() {
      return this.getTipConfiguration.isTipOptionsPercentage ? this.getTipConfiguration.tipOptionThreeAmount * 100 : this.getTipConfiguration.tipOptionThreeAmount
    },
    getTipOptionOneErrorMessage() {
      if (!this.getTipConfiguration.tipOptionOneAmount > 0) return "Amount must be positive."
      if (this.getTipConfiguration.isTipOptionsPercentage && this.getTipConfiguration.tipOptionOneAmount > 100) return "Percentage cannot be greater than 100."
      return this.getTipConfiguration.tipOptionOneAmount > 0 ? "" : "Positive value required."
    },
    getAmountRulesTipOptionTwo() {
      return this.getTipConfiguration.tipOptionTwoAmount > this.getTipConfiguration.tipOptionOneAmount
    },
    getTipOptionTwoErrorMessage() {
      if (!this.getTipConfiguration.tipOptionTwoAmount > 0) return "Amount must be positive."
      if (this.getTipConfiguration.isTipOptionsPercentage && this.getTipConfiguration.tipOptionTwoAmount > 100) return "Percentage cannot be greater than 100."
      return this.getAmountRulesTipOptionTwo ? "" : "Amount must be greater than tip option one."
    },
    getAmountRulesTipOptionThree() {
      return this.getAmountRulesTipOptionTwo && this.getTipConfiguration.tipOptionThreeAmount > this.getTipConfiguration.tipOptionTwoAmount
    },
    getTipOptionThreeErrorMessage() {
      if (!this.getTipConfiguration.tipOptionThreeAmount > 0) return "Amount must be positive."
      if (this.getTipConfiguration.isTipOptionsPercentage && this.getTipConfiguration.tipOptionThreeAmount > 100) return "Percentage cannot be greater than 100."
      return this.getAmountRulesTipOptionThree ? "" : "Amount must be greater than tip option two."
    },
    isProgressiveTipOptions() {
      return (
        parseFloat(this.getTipConfiguration.tipOptionOneAmount) < parseFloat(this.getTipConfiguration.tipOptionTwoAmount) &&
        parseFloat(this.getTipConfiguration.tipOptionTwoAmount) < parseFloat(this.getTipConfiguration.tipOptionThreeAmount)
      )
    },
    isValidTipOptions() {
      return (
        !this.isTipOptionsVisible
          || ((this.isTipOptionsVisible)
                  && this.isProgressiveTipOptions
                  && this.getTipConfiguration.tipOptionOneAmount > 0
                  && this.getTipConfiguration.tipOptionTwoAmount > 0
                  && this.getTipConfiguration.tipOptionThreeAmount > 0)
              && this.arePercentagesValid
      )
    },
    arePercentagesValid() {
      return (
          !this.getTipConfiguration.isTipOptionsPercentage
          || (this.getTipConfiguration.isTipOptionsPercentage
              && (this.getTipConfiguration.tipOptionOneAmount <= 100
              && this.getTipConfiguration.tipOptionTwoAmount <= 100
              && this.getTipConfiguration.tipOptionThreeAmount <= 100))
          )
    },
    isSaveButtonDisabled() {
      return !this.valuesChanged || !this.isValidTipOptions || this.getIsLoadingTipConfiguration
    }
  }
}
</script>

<style>
.checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
}
.active {
  color: #777777;
}
.inActive {
  color: #AAAAAA;
}
</style>
