<template>
  <v-container>
    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
      <template v-slot:activator="{ on }">
        <v-text-field
          class="cursor_pointer"
          style="min-width: 200px"
          :value="textFieldDisplayValue"
          :disabled="isDisabled"
          :label="textFieldLabel"
          prepend-inner-icon="mdi-clock-time-four-outline"
          @click:prepend-inner="setMenuVisibility(true)"
          :rules="validationRules"
          outlined
          :hide-details="isHideDetailsEnabled"
          readonly
          v-on="on"
        />
      </template>
      <v-time-picker
        v-if="menu"
        full-width
        elevation="8"
        color="primary"
        :max="maxValue"
        :min="minValue"
        @click:minute="$refs.menu.save(timeValue)"
        :allowed-minutes="allowedMinutes"
        @change="updateTimeEvent"
      />
    </v-menu>
  </v-container>
</template>

<script>
import { hoursAndMinutesAsTwelveHour } from "@/utils/DateTimeFormatters"

export default {
  name: "AppTimePickerMenu",
  props: {
    textFieldLabel: {
      type: String,
      default: ""
    },
    timeValue: {
      type: [String, Object],
      default: undefined
    },
    maxValue: {
      type: String,
      default: undefined
    },
    minValue: {
      type: String,
      default: undefined
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isHideDetailsEnabled: {
      type: Boolean,
      default: false
    },
    validationRules: {
      type: Array
    },
    allowedMinutes: Function
  },
  data: () => ({
    menu: false
  }),
  computed: {
    textFieldDisplayValue() {
      if (this.timeValue === null || this.timeValue === undefined) return ""
      const [hours, minutes] = this.timeValue?.split(":")
      return hoursAndMinutesAsTwelveHour(hours, minutes)
    }
  },
  methods: {
    updateTimeEvent(time) {
      this.$emit("updateTimeValue", time)
    },
    setMenuVisibility(isVisible) {
      this.menu = isVisible
    }
  }
}
</script>

<style scoped>
.v-text-field {
  min-width: 200px;
}
</style>
