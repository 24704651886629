<template>
  <v-container fluid>
    <base-dialog ref="job-cancellation-reason-dialog-root" :is-dialog-visible="isDialogVisible" dialog-image="mdi-cancel" title="Cancel Job?" @close-dialog="closeDialog">
      <template v-slot:content>
        <v-form ref="job-cancellation-reason-dialog-form" v-model="isJobCancellationReasonDialogFormValid">
          <v-row dense>
            <v-autocomplete
              v-model="selectedCancellationReasonId"
              ref="job-cancellation-reason-dialog-selected-reason"
              :items="getCancellationReasons"
              label="Selected Reason"
              item-value="id"
              item-text="name"
              :disabled="getIsLoadingJob"
              :rules="cancellationReasonValidationRules"
              validate-on-blur
              outlined
              flat
              clearable
            ></v-autocomplete>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="job-cancellation-reason-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn
          class="pa-4"
          ref="job-cancellation-reason-dialog-confirm-btn"
          color="primary"
          @click="confirmDialog"
          :loading="getIsLoadingJob"
          :disabled="getIsLoadingJob"
          text
          rounded
          ripple
          >Confirm</v-btn
        >
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job"

export default {
  name: "JobCancellationReasonDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCancellationReasonId: undefined,
      isJobCancellationReasonDialogFormValid: false,
      cancellationReasonValidationRules: [value => !!value || "A Cancellation Reason is Required."]
    }
  },
  methods: {
    ...mapActions(useJobStore, ["fetchCancellationReasons"]),
    confirmDialog() {
      if (this.$refs["job-cancellation-reason-dialog-form"].validate()) {
        this.$emit("confirmDialog", this.selectedCancellationReasonId)
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useJobStore, ["getCancellationReasons", "getIsLoadingJob"])
  },
  async created() {
    await this.fetchCancellationReasons()
  }
}
</script>

<style scoped></style>
