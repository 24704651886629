<template>
  <v-container fluid>
    <base-dialog
      ref="settings-delete-helper-employee-dialog-root"
      :is-dialog-visible="isDialogVisible"
      title="Deactivate Helper Employee"
      dialog-image="mdi-account-minus-outline"
      @close-dialog="closeDialog"
    >
      <template v-slot:content>
        <v-form ref="settings-delete-helper-employee-dialog-form">
          <v-row dense class="my-4">
            <span class="text-h6">
              Deactivate<strong> {{ firstName }} {{ lastName }} </strong>from the following operating units?
            </span>
          </v-row>
          <v-row dense>
            <v-autocomplete
              ref="settings-delete-helper-employee-dialog-selected-role"
              label="Assigned Operating Units"
              item-text="displayName"
              item-value="id"
              v-model="mutableUnassignedOperatingUnits"
              :items="mutableAssignedOperatingUnits"
              :disabled="getIsLoadingEmployees"
              outlined
              multiple
              chips
              flat
              solo
              clearable
              deletable-chips
            />
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" color="primaryText" ref="settings-delete-helper-employee-dialog-cancel-button" @click="closeDialog" text rounded ripple>Cancel </v-btn>
        <v-btn
          class="pa-4"
          color="primary"
          ref="settings-delete-helper-employee-dialog-confirm-button"
          :loading="getIsLoadingEmployees"
          :disabled="isSaveButtonDisabled"
          @click="handleDelete"
          text
          rounded
          ripple
        >
          Deactivate
        </v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { useMainStore } from "@/stores/Main"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { updateHelperEmployeeDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsDeleteHelperEmployeeDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    firstName: {
      type: String,
      default: ""
    },
    lastName: {
      type: String,
      default: ""
    },
    assignedOperatingUnits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      mutableAssignedOperatingUnits: [],
      mutableUnassignedOperatingUnits: []
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["updateHelperEmployee"]),
    async handleDelete() {
      const dto = updateHelperEmployeeDto(this.id, this.firstName, this.lastName, [], this.inactive)
      await this.updateHelperEmployee(dto).then(() => {
        this.closeDialog()
      })
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useMainStore, ["getOperatingUnitMetadataList"]),
    ...mapState(useSettingsStore, ["getIsLoadingEmployees"]),
    inactive() {
      return this.assignedOperatingUnits.slice().filter(unit => this.mutableUnassignedOperatingUnits.includes(unit))
    },
    isSaveButtonDisabled() {
      return this.getIsLoadingEmployees || this.inactive.length === 0
    }
  },
  created() {
    this.mutableAssignedOperatingUnits = this.getOperatingUnitMetadataList.filter(ou => this.assignedOperatingUnits.includes(ou.id))
  }
}
</script>
