<template>
  <v-container fluid>
    <mgt-get
      ref="data-fix-mgt-get"
      v-if="hasJobDataFixDetails"
      scopes="User.Read.All,Group.Read.All"
      version="v1.0"
      cache-enabled="true"
      :resource="getUsersInOperatingUnitQuery"
      @dataChange="setAvailableRequesters($event)"
    />
    <v-card>
      <v-card-title>Data Fix Admin Tool</v-card-title>
      <v-card-subtitle>
        This tool can be used to perform data fixes on certain job attributes.
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form>
              <v-row class="align-start mt-1">
                <v-col cols="12" sm="6" md="4">
                  <v-text-field ref="data-fix-job-number-input" label="Search by Job Number" v-model.trim="jobNumber" outlined @keydown.enter="fetchJobDataFixDetailsForEdit" />
                </v-col>
                <v-col cols="auto" class="align-baseline">
                  <v-btn
                    class="mt-2"
                    ref="data-fix-job-number-button"
                    color="primary"
                    @click="fetchJobDataFixDetailsForEdit"
                    :disabled="getIsLoadingJobDataFixDetails || !jobNumber"
                    :loading="getIsLoadingJobDataFixDetails"
                    >Fetch
                  </v-btn>
                </v-col>
                <v-col class="ma-0 mt-n6 align-start text-start justify-start align-content-start float-start" v-if="isCustomerDetailsAvailable">
                  <v-card flat>
                    <v-card-title class="subtitle-2 font-weight-bold">Customer Details</v-card-title>
                    <v-card-subtitle>
                      <div class="ms-4" v-if="getCustomerDetails.isBusiness"><strong>Business Name:</strong> {{ getCustomerDetails.existingBusinessName }}</div>
                      <div class="ms-4"><strong>First Name:</strong> {{ getCustomerDetails.existingPrimaryContactFirstName }}</div>
                      <div class="ms-4"><strong>Last Name:</strong> {{ getCustomerDetails.existingPrimaryContactLastName }}</div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
              </v-row>
              <v-divider ref="data-fix-job-data-divider" v-if="hasJobDataFixDetails" class="my-4" />
              <v-row ref="data-fix-job-edit-details-card" v-if="isEditing">
                <v-col>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-card-title>Job Details</v-card-title>
                          <v-card-subtitle ref="data-fix-job-details-caption-card" v-html="getJobDetailsCaptionMessage" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-select
                            ref="data-fix-requesting-user-select"
                            label="Requester"
                            item-text="displayName"
                            item-value="id"
                            return-object
                            outlined
                            :items="getSortedRequesters"
                            :value="currentRequester"
                            @change="setCurrentRequester"
                          >
                            <template v-slot:append-item>
                              <div class="text-center">
                                <v-btn
                                  ref="data-fix-add-temporary-requester-button"
                                  class="add-requester-button"
                                  color="primary"
                                  @click="setIsAddTemporaryRequesterDialogVisible(true)"
                                >
                                  <v-icon>mdi-plus</v-icon>
                                  ADD REQUESTER
                                </v-btn>
                              </div>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-divider ref="data-fix-job-data-divider" v-if="getHasCurrentRequester" class="my-4" />
                  <v-row ref="data-fix-job-data-card" v-if="getHasCurrentRequester" class="ma-4">
                    <v-col>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-card-title>
                              Status
                            </v-card-title>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-card-text ref="data-fix-current-status-card">
                              {{ getCurrentStatusForDisplay }}
                            </v-card-text>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-checkbox
                              ref="data-fix-updated-status-checkbox"
                              label="Cancel Job"
                              class="mt-0"
                              v-model="isUpdatedStatusCancelled"
                              :disabled="getIsLoadingJobDataFixDetails"
                              @change="setStatusToUpdate"
                            />
                          </v-row>
                          <v-row>
                            <v-select
                              ref="data-fix-updated-status-select"
                              label="Cancellation Reason"
                              item-text="name"
                              item-value="id"
                              v-if="isUpdatedStatusCancelled"
                              :items="getCancellationReasons"
                              :value="cancellationReason"
                              :disabled="getIsLoadingJobDataFixDetails"
                              :loading="getIsLoadingJobDataFixDetails"
                              clearable
                              outlined
                              return-object
                              @change="setCancellationReason"
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-card-title>
                              Price
                            </v-card-title>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-card-text>
                              <app-number-formatter ref="data-fix-current-price-formatter" :amount="currentPrice" currencySign="accounting" numberFormatStyle="currency" />
                            </v-card-text>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-text-field
                              ref="data-fix-updated-price-select"
                              label="Updated Price"
                              outlined
                              prefix="$"
                              :value="priceToUpdate"
                              type="number"
                              :disabled="getIsLoadingJobDataFixDetails"
                              :loading="getIsLoadingJobDataFixDetails"
                              @focus="selectAllContent"
                              @change="setPriceToUpdate"
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-card-title>
                              Taxes
                            </v-card-title>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-card-text ref="data-fix-current-tax-card">
                              {{ getCurrentTaxForDisplay }} -
                              <app-number-formatter
                                ref="data-fix-current-tax-formatter"
                                :amount="jobDataFixDetails.totalTax"
                                currencySign="accounting"
                                numberFormatStyle="currency"
                              />
                            </v-card-text>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-select
                              ref="data-fix-updated-tax-select"
                              label="Updated Tax"
                              v-if="getHasAvailableFranchiseTaxes"
                              clearable
                              outlined
                              return-object
                              item-value="franchiseTaxId"
                              :item-text="getTaxToUpdateForDisplay"
                              :items="getFilteredAvailableFranchiseTaxes"
                              :value="taxToUpdate"
                              :disabled="getIsLoadingJobDataFixDetails"
                              :loading="getIsLoadingJobDataFixDetails"
                              @change="setTaxToUpdate"
                            />
                            <v-card-text ref="data-fix-no-available-tax-card" class="bold" v-else>
                              No applicable taxes available to update for this franchise.
                            </v-card-text>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-row>
                            <v-card-title>
                              Capacity
                            </v-card-title>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-card-text ref="data-fix-current-capacity-card">
                              {{ getCurrentCapacityForDisplay }}
                            </v-card-text>
                          </v-row>
                        </v-col>
                        <v-col>
                          <v-row>
                            <v-select
                              ref="data-fix-updated-capacity-select"
                              label="Updated Capacity"
                              clearable
                              outlined
                              return-object
                              item-text="name"
                              item-value="id"
                              :items="getFilteredCapacities"
                              :value="capacityToUpdate"
                              :disabled="getIsLoadingJobDataFixDetails"
                              :loading="getIsLoadingJobDataFixDetails"
                              @change="setCapacityToUpdate"
                            />
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row ref="data-fix-preview-control" v-if="getHasCurrentRequester">
                        <v-col></v-col>
                        <v-col cols="12" sm="6" md="4" class="text-right">
                          <v-btn
                            ref="data-fix-preview-button"
                            :loading="getIsLoadingJobDataFixDetails"
                            :disabled="getIsPreviewDisabled || getIsLoadingJobDataFixDetails"
                            color="primary"
                            @click="fetchJobDataFixDetailsForPreview"
                          >
                            Preview
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-col ref="data-fix-job-preview-card" v-if="isPreviewing">
                <v-row>
                  <v-col>
                    <v-card-title>Preview</v-card-title>
                    <v-card-subtitle ref="data-fix-job-preview-caption-card" v-html="getJobDetailsCaptionMessage" />
                    <v-card-text>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-card-subtitle></v-card-subtitle>
                          </v-col>
                          <v-col>
                            <v-card-subtitle class="text-h6 text-right">
                              Current
                            </v-card-subtitle>
                          </v-col>
                          <v-col>
                            <v-card-subtitle class="text-h6 text-right">
                              Updated
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap">Status</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-current-status-preview-card">
                              {{ previewJobDataFixDetails.existingJobStatusName }}
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-updated-status-preview-card" :class="`${getFormattedClass(previewJobDataFixDetails.jobStatusChanged)}`">
                              {{ previewJobDataFixDetails.jobStatusName }}
                              <div v-if="previewJobDataFixDetails.jobStatusChanged">
                                -
                                {{ previewJobDataFixDetails.cancellationReasonName }}
                              </div>
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap">Price</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle>
                              <app-number-formatter
                                ref="data-fix-current-price-preview-formatter"
                                :amount="previewJobDataFixCalculations.existingPrice"
                                currencySign="accounting"
                                numberFormatStyle="currency"
                              />
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-updated-price-preview-wrapper" :class="`${getFormattedClass(previewJobDataFixCalculations.priceChanged)}`">
                              <app-number-formatter
                                ref="data-fix-updated-price-preview-formatter"
                                :amount="previewJobDataFixCalculations.price"
                                currencySign="accounting"
                                numberFormatStyle="currency"
                              />
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap">Discount</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-current-discount-preview-card">
                              {{ formatDiscount(previewJobDataFixCalculations.existingDiscountName, previewJobDataFixCalculations.existingDiscountAmount) }}
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-updated-discount-preview-card" :class="`${getFormattedClass(previewJobDataFixCalculations.discountChanged)}`">
                              {{ formatDiscount(previewJobDataFixCalculations.discountName, previewJobDataFixCalculations.discountAmount) }}
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap">Tax</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-current-tax-preview-card">
                              {{
                                formatTaxNameAndPercentage(
                                  previewJobDataFixCalculations.existingFranchiseTaxName,
                                  previewJobDataFixCalculations.existingTotalTaxPercentage,
                                  previewJobDataFixCalculations.existingTotalTax
                                )
                              }}
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-updated-tax-preview-card" :class="`${getFormattedClass(previewJobDataFixCalculations.totalTaxChanged)}`">
                              {{
                                formatTaxNameAndPercentage(
                                  previewJobDataFixCalculations.franchiseTaxName,
                                  previewJobDataFixCalculations.totalTaxPercentage,
                                  previewJobDataFixCalculations.totalTax
                                )
                              }}
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap">Capacity</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-current-capacity-preview-card">
                              {{ previewJobDataFixCalculations.existingFranchiseCapacityName }}
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle ref="data-fix-updated-capacity-preview-card" :class="`${getFormattedClass(previewJobDataFixCalculations.franchiseCapacityChanged)}`">
                              {{ previewJobDataFixCalculations.franchiseCapacityName }}
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap bold">Tips</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle>
                              <app-number-formatter ref="data-fix-current-tip-preview-card" :amount="previewJobDataFixCalculations.existingTotalTip" currencySign="accounting" numberFormatStyle="currency" />
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle>
                              <app-number-formatter ref="data-fix-updated-tip-preview-card" :amount="previewJobDataFixCalculations.totalTip" currencySign="accounting" numberFormatStyle="currency" />
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-divider class="my-4" />
                        <v-row>
                          <v-col>
                            <v-card-title class="text-no-wrap">Grand Total</v-card-title>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle class="text-right text-h6">
                              <app-number-formatter
                                ref="data-fix-current-total-preview-card"
                                :amount="previewJobDataFixCalculations.existingTotalPrice"
                                currencySign="accounting"
                                numberFormatStyle="currency"
                              />
                            </v-card-subtitle>
                          </v-col>
                          <v-col class="text-right">
                            <v-card-subtitle
                              ref="data-fix-updated-total-preview-card"
                              :class="`${getFormattedClass(previewJobDataFixCalculations.totalPriceChanged)}`"
                              class="text-h6"
                            >
                              <app-number-formatter
                                ref="data-fix-updated-total-preview-formatter"
                                :amount="previewJobDataFixCalculations.totalPrice"
                                currencySign="accounting"
                                numberFormatStyle="currency"
                              />
                            </v-card-subtitle>
                          </v-col>
                        </v-row>
                        <v-row class="text-right">
                          <v-col></v-col>
                          <v-col>
                            <v-btn
                                ref="data-fix-edit-data-fix-button"
                                color="secondary"
                                :disabled="getIsLoadingJobDataFixDetails"
                                :loading="getIsLoadingJobDataFixDetails"
                                @click="editJobDataFixDetails"
                            >Return to Edit
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                                ref="data-fix-submit-data-fix-button"
                                color="primary"
                                :disabled="getIsLoadingJobDataFixDetails"
                                :loading="getIsLoadingJobDataFixDetails"
                                @click="submitJobDataFixDetails"
                            >Submit Data Fix
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider ref="data-fix-response-card-divider" v-if="isResponseCardVisible" class="my-4" />
      <v-row ref="data-fix-response-card" v-if="isResponseCardVisible">
        <v-col>
          <v-card-subtitle class="text-h6">
            The data fix has been successfully applied.
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-card>
    <data-fix-add-temporary-requester-dialog
      ref="data-fix-add-temporary-requester-dialog"
      v-if="isAddTemporaryRequesterDialogVisible"
      :is-dialog-visible="isAddTemporaryRequesterDialogVisible"
      @on-cancel-add-temporary-requester="setIsAddTemporaryRequesterDialogVisible(false)"
      @on-add-temporary-requester="addTemporaryAvailableRequester"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState } from "pinia"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import DataFixAddTemporaryRequesterDialog from "@/components/DataFixAddTemporaryRequesterDialog"
import { EnvironmentVariant } from "@/enums/Environments"
import { JobStatus } from "@/enums/JobStatus"
import { useDataFixStore } from "@/stores/DataFix"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { formatDecimalValue } from "@/utils/FormatDecimalValue"
import { previewJobDataFixDto } from "@/api/dtos/JunkDtos"
import { isObjectEmpty } from "@/utils/ObjectHelpers"
import { useMainStore } from "@/stores/Main"

export default {
  name: "DataFix",
  components: { AppNumberFormatter, DataFixAddTemporaryRequesterDialog },
  data() {
    return {
      isEditing: false,
      isPreviewing: false,
      jobNumber: "",
      operatingUnitId: "",
      hasJobDataFixDetails: false,
      jobDataFixDetails: {},
      previewJobDataFixDetails: {},
      previewJobDataFixCalculations: {},
      availableRequesters: [],
      currentRequester: {},
      isAddTemporaryRequesterDialogVisible: false,
      isUpdatedStatusCancelled: false,
      cancellationReason: {},
      currentStatus: {},
      statusToUpdate: {},
      currentPrice: 0.0,
      priceToUpdate: 0.0,
      currentTax: {},
      currentTaxAmount: 0.0,
      taxToUpdate: {},
      currentCapacity: {},
      capacityToUpdate: {},
      isResponseCardVisible: false
    }
  },
  computed: {
    ...mapState(useMainStore, ["getJobStatusesSortedByName", "getTruckCapacities"]),
    ...mapState(useDataFixStore, ["getIsLoadingJobDataFixDetails", "getCancellationReasons", "getActiveFranchiseTaxes", "getCustomerDetails"]),
    getUsersInOperatingUnitQuery() {
      return `/groups/${this.operatingUnitId}/members/microsoft.graph.user?$expand=memberOf&$count=true`
    },
    getJobDetailsCaptionMessage() {
      if (!this.getHasCurrentRequester) {
        return `Select a requester to begin editing job details.`
      }
      const action = this.isPreviewing ? "Previewing" : "Editing"
      const jobNumber = this.jobNumber ? `<span class="bold">${this.jobNumber}</span>` : `[job number unavailable]`
      const requester = this.currentRequester.displayName ? `<span class="bold">${this.currentRequester.displayName}</span>` : `[requester name unavailable]`
      return `${action} job details for ${jobNumber} on behalf of ${requester}.`
    },
    getSortedRequesters() {
      return this.availableRequesters.slice().sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1
        }
        if (a.displayName > b.displayName) {
          return 1
        }
        return 0
      })
    },
    getHasCurrentRequester() {
      return this.currentRequester && Object.keys(this.currentRequester).length > 0
    },
    getCurrentStatusForDisplay() {
      return this.currentStatus.name ?? "[Status Unavailable]"
    },
    getHasAvailableFranchiseTaxes() {
      return this.getFilteredAvailableFranchiseTaxes.length > 0 ?? false
    },
    getFilteredAvailableFranchiseTaxes() {
      return this.getActiveFranchiseTaxes.filter(tax => tax.franchiseTaxId !== this.jobDataFixDetails?.franchiseTaxId) ?? []
    },
    getFilteredCapacities() {
      return this.getTruckCapacities.filter(capacity => capacity.id !== this.currentCapacity.id) ?? []
    },
    getCurrentCapacityForDisplay() {
      return this.currentCapacity.name ?? "[Capacity Unavailable]"
    },
    getIsPreviewDisabled() {
      const hasCancellationStatusToUpdate = this.isUpdatedStatusCancelled
      const hasCancellationReason = !(this.cancellationReason === null || Object.keys(this.cancellationReason).length === 0)
      const hasPriceToUpdate = Number(this.priceToUpdate) !== this.currentPrice
      const hasTaxToUpdate = !(this.taxToUpdate === null || Object.keys(this.taxToUpdate).length === 0)
      const hasCapacityToUpdate = !(this.capacityToUpdate === null || Object.keys(this.capacityToUpdate).length === 0)
      if (hasCancellationStatusToUpdate) {
        return !hasCancellationReason
      }
      return !(hasPriceToUpdate || hasTaxToUpdate || hasCapacityToUpdate)
    },
    getCurrentTaxForDisplay() {
      let taxName = this.currentTax?.taxName ?? "tax name unavailable"
      let taxRate = this.currentTax?.taxRate ?? "[tax rate unavailable]"
      return Object.keys(this.currentTax).length > 0 ? `${taxName} [${taxRate}]` : "(No Tax)"
    },
    isCustomerDetailsAvailable() {
      return !isObjectEmpty(this.getCustomerDetails)
    }
  },
  methods: {
    todayAsDate,
    ...mapActions(useDataFixStore, ["fetchCancellationReasons", "fetchActiveFranchiseTaxes", "fetchJobDataFixDetails", "postJobDataFixForPreview", "postJobDataFixForApply", "clearActiveFranchiseTaxes", "fetchJobDataFixCustomerDetails", "resetJobDataFixCustomerDetails"]),
    formatTaxNameAndPercentage(name, rate, amount) {
      return `${name} - [${formatDecimalValue(rate * 100)}%]: $${formatDecimalValue(amount)}`
    },
    formatDiscount(name, amount) {
      return `${name} - $${formatDecimalValue(amount)}`
    },
    setIsEditing(isEditing) {
      this.isEditing = isEditing
    },
    setIsPreviewing(isPreviewing) {
      this.isPreviewing = isPreviewing
    },
    setCurrentJobNumber(jobNumber) {
      this.jobNumber = jobNumber
    },
    setCurrentOperatingUnitId(operatingUnitId) {
      this.operatingUnitId = operatingUnitId
    },
    setHasJobDataFixDetails(hasJobDataFixDetails) {
      this.hasJobDataFixDetails = hasJobDataFixDetails
    },
    setCurrentJobDataFixDetails(jobDataFixDetails) {
      this.jobDataFixDetails = Object.assign({}, jobDataFixDetails)
    },
    setPreviewJobDataFixResponse(previewJobDataFixResponse) {
      this.previewJobDataFixDetails = Object.assign({}, previewJobDataFixResponse)
    },
    setPreviewJobDataFixCalculationsResponse(previewJobDataFixCalculationsResponse) {
      this.previewJobDataFixCalculations = Object.assign({}, previewJobDataFixCalculationsResponse)
    },
    setAvailableRequesters(event) {
      this.availableRequesters = event?.detail?.response?.value ?? []
    },
    setCurrentRequester(user) {
      this.currentRequester = Object.assign({}, user)
    },
    setIsAddTemporaryRequesterDialogVisible(isVisible) {
      this.isAddTemporaryRequesterDialogVisible = isVisible
    },
    addTemporaryAvailableRequester(event) {
      this.setIsAddTemporaryRequesterDialogVisible(false)
      if (event) {
        const temporaryAvailableRequester = {
          id: event.employeeId,
          givenName: event.firstName,
          surname: event.lastName,
          displayName: event.firstName + " " + event.lastName
        }
        this.availableRequesters.push(temporaryAvailableRequester)
        this.setCurrentRequester(temporaryAvailableRequester)
      }
    },
    setIsStatusCancelled(isCancelled) {
      this.isUpdatedStatusCancelled = isCancelled
    },
    setCurrentStatus(status) {
      this.currentStatus = Object.assign({}, status)
    },
    setStatusToUpdate(event) {
      this.statusToUpdate = event ? Object.assign({}, JobStatus.CANCELLED) : Object.assign({}, undefined)
      this.setCancellationReason({})
    },
    setCancellationReason(reason) {
      this.cancellationReason = Object.assign({}, reason)
    },
    setCurrentPrice(price) {
      this.currentPrice = price
    },
    setPriceToUpdate(price) {
      this.priceToUpdate = price
    },
    setCurrentTax(tax) {
      this.currentTax = Object.assign({}, tax)
    },
    setTaxToUpdate(tax) {
      this.taxToUpdate = Object.assign({}, tax)
    },
    getTaxToUpdateForDisplay(item) {
      return `${item.taxName} [${item.taxRate}]`
    },
    selectAllContent(event) {
      event.target.select()
    },
    setCurrentCapacity(capacity) {
      this.currentCapacity = Object.assign({}, capacity)
    },
    setCapacityToUpdate(capacity) {
      this.capacityToUpdate = Object.assign({}, capacity)
    },
    async fetchJobDataFixDetailsForEdit(event) {
      event.preventDefault()
      await this.clearStatePreFetch()
      await this.fetchJobDataFixCustomerDetails(this.jobNumber)
      await this.fetchJobDataFixDetails(this.jobNumber).then(response => {
        this.setIsEditing(true)
        this.setCurrentOperatingUnitId(response.operatingUnitId)
        this.setHasJobDataFixDetails(true)
        this.setCurrentJobDataFixDetails(response)
        this.setAvailableRequesters()
      })
      await this.fetchCancellationReasons()
      this.setCurrentStatus(this.getJobStatusesSortedByName.find(status => status.id === this.jobDataFixDetails.jobStatusId))
      this.setCurrentPrice(this.jobDataFixDetails.price)
      this.setPriceToUpdate(this.jobDataFixDetails.price)
      const getActiveFranchiseTaxesDto = {
        franchiseId: this.jobDataFixDetails.franchiseId,
        date: todayAsDate()
      }
      await this.fetchActiveFranchiseTaxes(getActiveFranchiseTaxesDto).then(() => {
        this.setCurrentTax(this.getActiveFranchiseTaxes.find(tax => tax.franchiseTaxId === this.jobDataFixDetails.franchiseTaxId))
      })
      this.setCurrentCapacity(this.getTruckCapacities.find(capacity => capacity.id === this.jobDataFixDetails.capacityId))
    },
    async fetchJobDataFixDetailsForPreview() {
      this.setPreviewJobDataFixResponse({})
      this.setPreviewJobDataFixCalculationsResponse({})
      let thePrice = this.priceToUpdate !== "" && this.priceToUpdate !== this.currentPrice ? this.priceToUpdate : null
      const previewDto = new previewJobDataFixDto(
        this.jobDataFixDetails.jobId,
        this.taxToUpdate.franchiseTaxId,
        this.statusToUpdate.id,
        this.cancellationReason.id,
        thePrice,
        this.capacityToUpdate.id,
        this.$msal.getCurrentUserId(),
        this.currentRequester
      )
      await this.postJobDataFixForPreview(previewDto).then(response => {
        this.setPreviewJobDataFixResponse(response.previewReturnJobStatusDto)
        this.setPreviewJobDataFixCalculationsResponse(response.previewReturnJunkJobCalculationsDto)
        this.setIsEditing(false)
        this.setIsPreviewing(true)
      })
    },
    getFormattedClass(isChanged) {
      return isChanged ? "primary--text bold" : "primaryText--text"
    },
    async editJobDataFixDetails() {
      this.setIsEditing(true)
      this.setIsPreviewing(false)
    },
    async submitJobDataFixDetails() {
      const applyDataFixDto = {
        jobId: this.jobDataFixDetails.jobId,
        employeeId: this.$msal.getCurrentUserId()
      }
      await this.postJobDataFixForApply(applyDataFixDto).then(() => {
        this.clearStatePostFetch()
        this.setResponseCardVisible(true)
      })
    },
    setResponseCardVisible(isVisible) {
      this.isResponseCardVisible = isVisible
    },
    async onEmployeeDataChanged(event) {
      let data = event?.detail?.response?.value ?? []
      let rolesToRemove = ["corporate admin", "sales support"]
      if (this.getAppEnvironment !== EnvironmentVariant.PRODUCTION) {
        rolesToRemove = rolesToRemove.map(role => `${role} (${this.getAppEnvironment})`)
      }
      data = data.filter(item => !item.memberOf.some(group => rolesToRemove.some(role => group?.displayName?.toLowerCase() === role)))
      await this.setEmployeesInJobOperatingUnit(data)
    },
    async clearStatePreFetch() {
      this.setIsEditing(false)
      this.setIsPreviewing(false)
      this.setCurrentOperatingUnitId("")
      this.setHasJobDataFixDetails(false)
      this.setCurrentJobDataFixDetails({})
      this.setPreviewJobDataFixResponse({})
      this.setPreviewJobDataFixCalculationsResponse({})
      this.setAvailableRequesters([])
      this.setCurrentRequester({})
      this.setIsStatusCancelled(false)
      this.setCurrentStatus({})
      this.setStatusToUpdate(false)
      this.setCancellationReason({})
      this.setCurrentPrice(0.0)
      this.setPriceToUpdate(0.0)
      this.setCurrentTax({})
      this.setTaxToUpdate({})
      this.setCurrentCapacity({})
      this.setCapacityToUpdate({})
      await this.clearActiveFranchiseTaxes()
      this.setResponseCardVisible(false)
      await this.resetJobDataFixCustomerDetails()
    },
    async clearStatePostFetch() {
      this.setCurrentJobNumber("")
      await this.clearStatePreFetch()
    }
  }
}
</script>

<style>
.add-requester-button {
  margin: 4px;
  width: 90%;
}

.bold {
  font-weight: bold;
}
</style>
