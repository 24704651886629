import { render, staticRenderFns } from "./SettingsFranchiseSlotSummaryCard.vue?vue&type=template&id=5e2d7358&scoped=true"
import script from "./SettingsFranchiseSlotSummaryCard.vue?vue&type=script&lang=js"
export * from "./SettingsFranchiseSlotSummaryCard.vue?vue&type=script&lang=js"
import style0 from "./SettingsFranchiseSlotSummaryCard.vue?vue&type=style&index=0&id=5e2d7358&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2d7358",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VCardTitle,VDataTable})
