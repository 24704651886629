<template>
  <base-dialog ref="settings-create-franchise-special-hours-confirmation-dialog-root" title="Create Special Hours" :is-dialog-visible="isDialogVisible" dialog-image="mdi-calendar-clock-outline">
    <template class="flex-fill" v-slot:content>
      <v-form ref="settings-create-franchise-special-hours-dialog-form">
        <v-row>
          <v-col>
            <v-select
              class="text-md-body-1 cursor_pointer"
              ref="settings-create-special-hours-franchise-selector"
              disabled
              :loading="isLoading"
              :value="getSelectedFranchise"
              :items="getFranchisesInOperatingUnit"
              item-text="franchiseName"
              item-value="franchiseId"
              color="primary"
              label="Selected Franchise"
              data-cy="settings-selected-franchise"
              outlined
              hide-details
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <app-date-picker-field-selector
              ref="settings-franchise-special-hours-card-date-selector"
              :minimum-date-value="minimumDate"
              :date-picker-value="date"
              @updatedPickerValue="setDate($event)"
              text-field-label="Selected Date *"
              :disallowed-dates="getDisallowedDates"
            />
          </v-col>
          <v-col>
            <v-text-field ref="settings-franchise-special-hours-card-description" class="no-gutters" label="Description *" v-model="description" :rules="descriptionValidationRules" outlined counter="50"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-0 mb-n6">
          <v-col cols="2">
            <v-checkbox ref="settings-franchise-special-hours-card-is-office-closed" label="Office Closed" v-model="isOfficeClosed" />
          </v-col>
          <v-col cols="5">
            <app-time-picker-menu
              text-field-label="Office Open"
              ref="settings-create-franchise-special-hours-card-office-open-time-picker"
              :time-value="officeOpenTime"
              :is-disabled="isOfficeClosed"
              :max-value="officeCloseTime"
              :allowed-minutes="allowedMinutes"
              @updateTimeValue="setOfficeOpenTime($event)"
              dense
            ></app-time-picker-menu>
          </v-col>
          <v-col cols="5">
            <app-time-picker-menu
              ref="settings-create-franchise-special-hours-card-office-close-time-picker"
              text-field-label="Office Close"
              :time-value="officeCloseTime"
              :is-disabled="isOfficeClosed"
              :min-value="officeOpenTime"
              :allowed-minutes="allowedMinutes"
              v-model="officeCloseTime"
              @updateTimeValue="setOfficeCloseTime($event)"
              dense
            ></app-time-picker-menu>
          </v-col>
        </v-row>
        <v-divider class="mt-0 secondaryLight"></v-divider>
        <v-row class="mt-2">
          <v-col cols="2">
            <v-checkbox ref="settings-franchise-special-hours-hauling-closed-checkbox" label="Hauling Closed" v-model="isHaulingClosed" />
          </v-col>
          <v-col cols="5">
            <app-time-picker-menu
              text-field-label="Self-Booking AM Cutoff"
              ref="settings-create-franchise-special-hours-card-office-open-time-picker"
              :time-value="customerBookingAmCutoffTime"
              :is-disabled="isHaulingClosed"
              :allowed-minutes="allowedMinutes"
              v-model="customerBookingAmCutoffTime"
              @updateTimeValue="setCustomerBookingAmCutoffTime($event)"
              dense
            ></app-time-picker-menu>
          </v-col>
          <v-col cols="5">
            <app-time-picker-menu
              ref="settings-create-franchise-special-hours-card-office-open-time-picker"
              text-field-label="Self-Booking PM Cutoff"
              :time-value="customerBookingPmCutoffTime"
              :is-disabled="isHaulingClosed"
              :min-value="customerBookingAmCutoffTime"
              :allowed-minutes="allowedMinutes"
              @updateTimeValue="setCustomerBookingPmCutoffTime($event)"
              v-model="customerBookingPmCutoffTime"
              dense
            ></app-time-picker-menu>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="settings-create-franchise-special-hours-card-confirmation-dialog-cancel-btn" color="primaryText" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn class="pa-4" ref="settings-create-franchise-special-hours-card-confirmation-dialog-confirm-btn" color="primary" @click="save" :loading="isLoading" :disabled="isLoading" text rounded ripple>Confirm</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"
import dayjs from "dayjs"
import AppTimePickerMenu from "@/components/AppTimePickerMenu.vue"
import { postFranchiseSpecialHoursDto } from "@/api/dtos/JunkDtos"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsCreateFranchiseSpecialHoursDialog",
  components: { AppTimePickerMenu, AppDatePickerFieldSelector, BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minimumDate: todayAsDate(),
      date: todayAsDate(),
      description: "",
      isLoading: false,
      selectedDurationId: 3,
      descriptionValidationRules: [value => (!!value && !!value.trim()) || "Description is required.", value => value?.length > 0 || "Description must contain a character.", value => value.length <= 50 || "Description must be 50 characters or less."],
      selectedFranchiseRules: [value => Object.keys(value).length !== 0 || "Is required."],
      isOfficeClosed: false,
      isHaulingClosed: false,
      officeOpenTime: "00:00:00",
      officeCloseTime: "00:00:00",
      customerBookingAmCutoffTime: "00:00:00",
      customerBookingPmCutoffTime: "00:00:00"
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["createFranchiseSpecialHour"]),
    allowedMinutes: m => m % 15 === 0,
    generatePostFranchiseSpecialHoursDto() {
      return postFranchiseSpecialHoursDto(
        this.getSelectedFranchise,
        this.description,
        this.date,
        this.isOfficeClosed,
        this.isHaulingClosed,
        this.officeOpenTime,
        this.officeCloseTime,
        this.customerBookingAmCutoffTime,
        this.customerBookingPmCutoffTime
      )
    },
    setOfficeCloseTime(time) {
      this.officeCloseTime = `${time}:00`
    },
    setOfficeOpenTime(time) {
      this.officeOpenTime = `${time}:00`
    },
    setCustomerBookingAmCutoffTime(time) {
      this.customerBookingAmCutoffTime = `${time}:00`
    },
    setCustomerBookingPmCutoffTime(time) {
      this.customerBookingPmCutoffTime = `${time}:00`
    },
    async save() {
      const dto = this.generatePostFranchiseSpecialHoursDto()
      console.log("DTO123 =", dto)
      await this.createFranchiseSpecialHour(dto).then(() => this.closeDialog())
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setDate(selectedDate) {
      const dayOfWeekId = new dayjs(selectedDate).day()
      const day = this.getBusinessHourByFranchiseIdAndDayOfWeekId(dayOfWeekId)
      this.setInitialValuesForDay(day)
      this.date = formatAsDateOnly(selectedDate)
    },
    setInitialValuesForDay(day) {
      this.officeOpenTime = day?.officeOpenTime ?? "08:00:00"
      this.officeCloseTime = day?.officeCloseTime ?? "17:00:00"
      this.customerBookingAmCutoffTime = day?.customerBookingAmCutoffTime ?? "10:00:00"
      this.customerBookingPmCutoffTime = day?.customerBookingPmCutoffTime ?? "14:00:00"
    },
    getBusinessHourByFranchiseIdAndDayOfWeekId(dayOfWeekId) {
      const days = this.getFranchiseBusinessHours
      const copy = JSON.parse(JSON.stringify(days))
      return copy.find(fbh => fbh.franchiseId === this.getSelectedFranchise && fbh.dayOfWeekId === dayOfWeekId)
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getFranchisesInOperatingUnit", "getSelectedFranchise", "getFranchiseBusinessHours", "getFranchiseSpecialHours"]),
    getDisallowedDates() {
      const dates = this.getFranchiseSpecialHours.map(c => c.overriddenDate)
      return dates
    }
  },
  created() {
    this.setDate(todayAsDate())
  }
}
</script>

<style scoped></style>
