<template>
  <v-card ref="administrator-operating-unit-tree-root" class="mx-auto" :loading="getIsLoadingOperatingUnitFranchisesAndSatellites" min-height="300">
    <v-sheet class="pa-4 primary">
      <v-text-field
        ref="administrator-operating-unit-tree-search"
        type="search"
        autocomplete="off"
        prepend-icon="mdi-magnify"
        clear-icon="mdi-close-circle-outline"
        label="Search Company Directory for Existing Operating Units, Franchises, and Satellite Offices"
        v-model="search"
        dark
        flat
        solo-inverted
        hide-details
        clearable
      />
    </v-sheet>

    <v-card-text>
      <v-row>
        <v-responsive ref="administrator-operating-unit-tree-container" class="overflow-y-auto" :max-height="getMaxHeightForOperatingUnitList">
          <v-col>
            <v-treeview
              ref="administrator-operating-unit-tree"
              item-key="treeViewKey"
              item-text="displayName"
              :items="getOperatingUnitFranchisesAndSatellites"
              :search="search"
              :open.sync="open"
              @update:active="setSelectedItems"
              rounded
              return-object
              activatable
            >
              <template v-slot:prepend="{ item }">
                <v-icon ref="administrator-operating-unit-tree-icon" v-text="getIcon(item)"></v-icon>
              </template>
            </v-treeview>
          </v-col>
        </v-responsive>
        <v-divider ref="administrator-operating-unit-tree-divider" v-show="isVerticalDividerVisible" vertical />
        <v-col>
          <administrator-edit-operating-unit
            ref="administrator-operating-unit-tree-ou"
            v-if="selectedItem[0].type === 'OperatingUnit'"
            :key="selectedItem[0].treeViewKey"
            :operating-unit-initial="selectedItem[0]"
          />
          <administrator-edit-franchise
            ref="administrator-operating-unit-tree-franchise"
            v-if="selectedItem[0].type === 'Franchise'"
            :key="selectedItem[0].treeViewKey"
            :franchise-initial="selectedItem[0]"
          />
          <administrator-edit-satellite-office
            ref="administrator-operating-unit-tree-satellite-office"
            v-if="selectedItem[0].type === 'SatelliteOffice'"
            :key="selectedItem[0].treeViewKey"
            :satellite-office-initial="selectedItem[0]"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AdministratorEditOperatingUnit from "@/components/AdministratorEditOperatingUnit"
import AdministratorEditFranchise from "@/components/AdministratorEditFranchise"
import AdministratorEditSatelliteOffice from "@/components/AdministratorEditSatelliteOffice"
import { useMainStore } from "@/stores/Main"
import { useAdministratorStore } from "@/stores/Administrator"
import { mapActions, mapState } from "pinia"

export default {
  name: "AdministratorOperatingUnitTree",
  components: { AdministratorEditSatelliteOffice, AdministratorEditFranchise, AdministratorEditOperatingUnit },
  data: () => ({
    selectedItem: [{ type: undefined }],
    open: [],
    search: null
  }),
  methods: {
    ...mapActions(useMainStore, ["setIsLoadingAppFullScreen"]),
    ...mapActions(useAdministratorStore, ["fetchAllOperatingUnitFranchisesAndSatellites"]),
    setSelectedItems(item) {
      this.selectedItem = item
    },
    getIcon(item) {
      const itemLowercase = item.type?.toLowerCase() ?? ""
      if (itemLowercase === "operatingunit") return "mdi-domain"
      else if (itemLowercase === "franchise") return "mdi-office-building"
      else if (itemLowercase === "satelliteoffice") return "mdi-satellite-uplink"
      else return "mdi-blank"
    }
  },
  computed: {
    ...mapState(useAdministratorStore, ["getIsLoadingOperatingUnitFranchisesAndSatellites", "getOperatingUnitFranchisesAndSatellites"]),
    getMaxHeightForOperatingUnitList() {
      return this.$vuetify.breakpoint.smAndDown ? "300" : "1000"
    },
    isVerticalDividerVisible() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  async created() {
    await this.setIsLoadingAppFullScreen({ isLoading: true, loadingText: "Fetching All Operating Units, Franchises, and Satellite Offices" })
    await this.fetchAllOperatingUnitFranchisesAndSatellites().finally(async () => {
      await this.setIsLoadingAppFullScreen({ isLoading: false, loadingText: "" })
    })
  }
}
</script>
