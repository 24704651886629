import * as msal from "@azure/msal-browser"
import Vue from "vue"
import { consoleLog, consoleLogCircularRef } from "@/utils/Logging"
import { Permissions } from "@/enums/Permissions"
import { EventType, InteractionRequiredAuthError } from "@azure/msal-browser"
import jwtDecode from "jwt-decode"
import { Providers } from "@microsoft/mgt-element"
import getEnv from "@/utils/Env"
import { azureauth } from "../../appsettings.json"
import { upsertEmployeeDetailsDto } from "@/api/dtos/JunkDtos"
import { AdRoles } from "@/enums/AdRoles"
import { datadogRum } from "@datadog/browser-rum"
import { useMainStore } from "@/stores/Main"

// from this: https://dev.to/425show/secure-your-vue-js-apis-with-azure-ad-b2c-42j6

/**
 * In summary, is the MSAL instance used for authentication.
 * This variable is an instance of the `PublicClientApplication` class from the `@azure/msal-browser` package. It is created using the new keyword and the msalConfig object.
 * This instance represents the MSAL (Microsoft Authentication Library) instance used for handling authentication and authorization in the application.
 * It is assigned a new instance of PublicClientApplication in the initialize method of the MsalPlugin class.
 */
export let msalInstance // exported to be leveraged in main by Providers.global

/**
 * In summary, is the instance of the custom plugin that wraps the MSAL functionality for integration with Vue.js.
 * This variable is an instance of the MsalPlugin class, which is a custom plugin created for integrating MSAL functionality into a Vue.js application.
 * It is assigned the instance of MsalPlugin in the `install` method of the `MsalPlugin` class. This instance holds the configuration options and state related to MSAL authentication in the Vue.js application.
 */
export let msalPluginInstance

export class MsalPlugin {
  pluginOptions = {
    clientId: "",
    authority: "",
    redirectUri: ""
    // loginAuthority: "",
    // passwordAuthority: "",
    // knownAuthority: ""
  }

  currentAccount = {} // here temporarily, should probably just be the currentActiveAccount
  isAuthenticated = false

  install(vue, options) {
    if (!options) {
      throw new Error("MsalPluginOptions must be specified")
    }

    this.pluginOptions = options
    this.initialize(options)
    msalPluginInstance = this
    consoleLogCircularRef("msalPluginInstance = ", msalPluginInstance)
    vue.prototype.$msal = Vue.observable(msalPluginInstance)
  }

  initialize(options) {
    msalInstance = new msal.PublicClientApplication(options)
    this.isAuthenticated = this.getIsUserLoggedIn()
    this.currentAccount = Object.assign({}, this.getCurrentAccount())

    msalInstance.addEventCallback((event) => {
        // set active account after redirect
        console.log("msalInstance.addEventCallback = ", JSON.stringify(event, null, 4))
        console.log("Event Type = ", event.eventType)

        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account
          msalInstance.setActiveAccount(account)

          // Set DataDog RUM user session data
          datadogRum.setUser({
            id: account.localAccountId,
            name: account.name,
            email: account.username
          })

          // let dto = createOrUpdateEmployeeDetailsDto()
          let dto = upsertEmployeeDetailsDto(account.localAccountId, account.idTokenClaims.given_name, account.idTokenClaims.family_name, account.idTokenClaims.preferred_username ?? "")
          const mainStore = useMainStore()
          mainStore.createOrUpdateEmployeeDetails(dto)
          consoleLog("addEventCallback.currentAccounts = ", account)
          consoleLog("event.payload = ", event.payload)
          this.isAuthenticated = true
          this.currentAccount = Object.assign({}, account) // NOTE: this might have to be event.payload.account.idTokenClaims.roles
          // this.roles = event.payload.idTokenClaims.roles // this is great on initial, however, after page re-load we lose date
          // this.roles = currentAccount[0].idTokenClaims.roles // this is great on initial, however, after page re-load we lose date
          consoleLog("EventType.LOGIN_SUCCESS.account = ", account)
          // let token = msalInstance.acquireToken()
          // consoleLog("tokenzzz = ", token)
          // msalInstance.setActiveAccount(account)
        }
      },
      error => {
        console.log("error", error)
      }
    )
  }

  getIsUserLoggedIn() {
    consoleLog("getIsUserLoggedIn? = ", msalInstance.getActiveAccount() != null)
    return msalInstance.getActiveAccount() != null
  }

  getCurrentUsername() {
    return msalInstance.getActiveAccount().username
  }

  getCurrentUserId() {
    return msalInstance.getActiveAccount().localAccountId
  }

  getCurrentUserGivenName() {
    return msalInstance.getActiveAccount().idTokenClaims.given_name
  }

  getCurrentUserFamilyName() {
    return msalInstance.getActiveAccount().idTokenClaims.family_name
  }

  getActiveUserRoles() {
    console.log("msalInstance.getActiveAccount().idTokenClaims.roles = ", msalInstance?.getActiveAccount()?.idTokenClaims?.roles)
    console.log("this.currentAccount?.idTokenClaims?.roles = ", this.currentAccount?.idTokenClaims?.roles)
    return this.currentAccount?.idTokenClaims?.roles ?? []
    // return msalInstance.getActiveAccount()?.idTokenClaims?.roles ?? [] // before
  }

  getUserHasCorporateAdministratorRole() {
    return this.getActiveUserRoles().includes(AdRoles.CORPORATE_ADMINISTRATOR.adName)
  }

  getUserHasJunkManagerRole() {
    return this.getActiveUserRoles().includes(AdRoles.JUNK_MANAGER.adName)
  }

  getUserHasFranchiseOwnerRole() {
    return this.getActiveUserRoles().includes(AdRoles.FRANCHISE_OWNER.adName)
  }

  getUserHasElevatedRole() {
    const elevatedRoles = [AdRoles.CORPORATE_ADMINISTRATOR.adName, AdRoles.JUNK_MANAGER.adName, AdRoles.FRANCHISE_OWNER.adName]
    return this.getActiveUserRoles().some(role => elevatedRoles.includes(role))
  }

  getUserHasCSRRole() {
    return this.getActiveUserRoles().includes(AdRoles.CSR.adName)
  }

  getUserHasSalesSupportRole() {
    return this.getActiveUserRoles().includes(AdRoles.SALES_SUPPORT.adName)
  }

  getUserHasReadJobPermission() {
    return this.getActiveUserRoles().includes(Permissions.READ_JOB)
  }

  getUserHasWriteJobPermission() {
    return this.getActiveUserRoles().includes(Permissions.WRITE_JOB)
  }

  getUserHasWriteCloseJobPermission() {
    return this.getActiveUserRoles().includes(Permissions.WRITE_CLOSE_JOB)
  }

  getUserHasWriteUncompleteJobPermission() {
    return this.getActiveUserRoles().includes(Permissions.WRITE_UNCOMPLETE_JOB)
  }

  getUserHasReadSettingsPermission() {
    consoleLog("0getUserHasReadSettingsPermission getActiveUserRoles() = ", this.getActiveUserRoles())
    console.log("msalInstance.currentAccount?.idTokenClaims?.roles.includes(Permissions.READ_SETTINGS) = ", this.currentAccount?.idTokenClaims?.roles)
    return this.getActiveUserRoles().includes(Permissions.READ_SETTINGS)
  }

  getUserHasWriteSettingsPermission() {
    consoleLog("0getUserHasReadSettingsPermission getActiveUserRoles() = ", this.getActiveUserRoles())
    console.log("msalInstance.currentAccount?.idTokenClaims?.roles.includes(Permissions.READ_SETTINGS) = ", this.currentAccount?.idTokenClaims?.roles)
    return this.getActiveUserRoles().includes(Permissions.WRITE_SETTINGS)
  }

  getUserHasOnboardFranchisePermission() {
    return this.getActiveUserRoles()?.includes(Permissions.WRITE_FRANCHISE_ONBOARDING) ?? false
  }

  getUserHasReadQueueNotifications() {
    console.log("msalInstance.currentAccount = ", msalInstance.currentAccount)
    return this.getActiveUserRoles().includes(Permissions.READ_QUEUE_NOTIFICATIONS) ?? false
  }

  getTokenSilent() {
    console.log("getTokenSilent.called!")
    // const account = msalInstance.getAllAccounts()[0]
    const account = msalInstance.getActiveAccount()
    const customApiScopes = getEnv("VUE_APP_AZURE_API_SCOPES_GUID") || azureauth.VUE_APP_AZURE_API_SCOPES_GUID

    const accessTokenRequest = {
      // TODO: Extract to app configuration.
      scopes: [`${customApiScopes}/.default`],
      account: account
    }

    return msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then(async function(accessTokenResponse) {
        consoleLog("getTokenSilent.accessTokenResponse1: ", accessTokenResponse)

        let accessToken = accessTokenResponse.accessToken
        consoleLog("getTokenSilent.response = ", accessToken)
        consoleLog("route.getTokenSilent.responseDecoded = ", jwtDecode(accessToken))

        return Promise.resolve(accessToken)
      })
      .catch(async function(error) {
        //Acquire token silent failure, and send an interactive request
        console.log(error)
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenRedirect(accessTokenRequest)
        }
      })
  }

  getRoutingTokenSilent() {
    console.log("getTokenSilent.called!")
    // const account = msalInstance.getAllAccounts()[0]
    const account = msalInstance.getActiveAccount()
    const customApiScopes = getEnv("VUE_APP_AZURE_API_ROUTING_SCOPES_GUID") || azureauth.VUE_APP_AZURE_API_ROUTING_SCOPES_GUID

    const accessTokenRequest = {
      // TODO: Extract to app configuration.
      scopes: [`${customApiScopes}/.default`],
      account: account
    }

    return msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then(async function(accessTokenResponse) {
        consoleLog("getTokenSilent.accessTokenResponse1: ", accessTokenResponse)

        let accessToken = accessTokenResponse.accessToken
        consoleLog("getTokenSilent.response = ", accessToken)
        consoleLog("route.getTokenSilent.responseDecoded = ", jwtDecode(accessToken))

        return Promise.resolve(accessToken)
      })
      .catch(async function(error) {
        //Acquire token silent failure, and send an interactive request
        console.log(error)
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenRedirect(accessTokenRequest)
        }
      })
  }

  // todo: merge this with getRoutingTokenSilent, above
  getDocumentServiceTokenSilent() {
    console.log("getTokenSilent.called!")
    // const account = msalInstance.getAllAccounts()[0]
    const account = msalInstance.getActiveAccount()
    const customApiScopes = getEnv("VUE_APP_AZURE_API_DOCUMENT_SCOPES") || azureauth.VUE_APP_AZURE_API_DOCUMENT_SCOPES

    const accessTokenRequest = {
      // TODO: Extract to app configuration.
      scopes: [`${customApiScopes}/.default`],
      account: account
    }

    return msalInstance
      .acquireTokenSilent(accessTokenRequest)
      .then(async function(accessTokenResponse) {
        consoleLog("getDocumentServiceTokenSilent.getTokenSilent.accessTokenResponse1: ", accessTokenResponse)

        let accessToken = accessTokenResponse.accessToken
        consoleLog("getDocumentServiceTokenSilent.getTokenSilent.response = ", accessToken)
        consoleLog("getDocumentServiceTokenSilent.responseDecoded = ", jwtDecode(accessToken))

        return Promise.resolve(accessToken)
      })
      .catch(async function(error) {
        //Acquire token silent failure, and send an interactive request
        console.log(error)
        if (error instanceof InteractionRequiredAuthError) {
          return await msalInstance.acquireTokenRedirect(accessTokenRequest)
        }
      })
  }

  async handleRedirectPromise() {
    await msalInstance.handleRedirectPromise()
  }

  /*async handleResponse(response) { // carried over from auth/msal
    consoleLog("handleResponse = ", response)
    if (response !== null) {
      // username = response.account.username
      console.log("TAGAROO.authRedirect.response = ", response)
      // showWelcomeMessage(username);
      // console.log("un = ", username)
    } else {
      consoleLog("handleResponse ELSE! = ")
    }
  }*/

  async signIn(loginRequest) {
    try {
      console.log("Sign-in called")
      let loginResponse
      try {
        loginResponse = await msalInstance
          .handleRedirectPromise()
          .then(async (tokenResponse) => {
            consoleLog("tokenResponse = ", tokenResponse)
            const account = msalInstance.getActiveAccount()
            if (!account) { // logic is different, we don't check for an account before the re-direct
              // redirect anonymous user to login page
              msalInstance.loginRedirect(loginRequest)
              // await Providers.globalProvider.login()
            }
          })
          .catch(err => console.error(err))
        consoleLog("loginResponse = ", loginResponse)
      } catch (e) {
        consoleLog("msalInstance.handleRedirectPromise() error!", e)
      }

      this.isAuthenticated = !!loginResponse?.account
    } catch (err) {
      // handle error
      console.error("Sign in error occurred", err)
      const customApiScopes = getEnv("VUE_APP_AZURE_API_SCOPES_GUID") || azureauth.VUE_APP_AZURE_API_SCOPES_GUID
      if (err.errorMessage && err.errorMessage?.indexOf("AADB2C90118") > -1) {
        try {
          const passwordResetResponse = await msalInstance.loginRedirect({
            scopes: ["openid", "profile", `${customApiScopes}/.default`],
            authority: this.pluginOptions.authority
          })

          this.isAuthenticated = !!passwordResetResponse?.account
        } catch (passwordResetError) {
          console.error(passwordResetError)
        }
      } else {
        this.isAuthenticated = false
      }
    }
  }

  async signOut() {
    await Providers.globalProvider.logout().then(async () => {
      this.isAuthenticated = false
      this.currentAccount = Object.assign({}, {})
      const mainStore = useMainStore()
      await mainStore.resetSelectedOperatingUnitMetadata()
    })
    // wrap this - so we log out of the provider (clearing the component cache). We also want to remove all session data, as well as, reset the state object.
    //await Providers.globalProvider.logout()
    // const logoutRequest = {
    //   account: msalInstance.getAccountByUsername(this.currentAccount.username),
    //   postLogoutRedirectUri: msalConfig.auth.redirectUri
    // }
    //
    // try {
    //   let logoutResponse
    //   try {
    //     logoutResponse = await msalInstance.handleRedirectPromise().then(async () => {
    //       await msalInstance.logoutRedirect(logoutRequest)
    //       await mainStore.dispatch("resetSelectedOperatingUnitMetadata", undefined)
    //       this.isAuthenticated = false
    //       this.currentAccount = Object.assign({}, {})
    //     })
    //   } catch (e) {
    //     consoleLog("ERROR OCCURRED LOGGING OUT!", e)
    //   }
    //   consoleLog("loginResponse = ", logoutResponse)
    // } catch (e) {
    //   consoleLog("ERROR OCCURRED LOGGING OUT (outer)!", e)
    // }
    // await msalInstance.handleRedirectPromise()

    // // await msalInstance.handleRedirectPromise().then(async () => {
    // //   await Providers.globalProvider.logout()
    // // })
    // await msalInstance
    //   .handleRedirectPromise()
    //   .then(() => {
    //     msalInstance.logoutRedirect(logoutRequest)
    //     this.isAuthenticated = false
    //     this.currentAccount = Object.assign({}, {})
    //     // this.roles = []
    //     mainStore.dispatch("resetSelectedOperatingUnitMetadata", undefined)
    //     // const account = msalInstance.getActiveAccount()
    //     // if (!account) {
    //     //   // redirect anonymous user to login page
    //     //   msalInstance.logoutRedirect()
    //     // }
    //   })
    //   .catch(err => console.error(err))
  }

  getCurrentAccount() {
    return msalInstance.getActiveAccount()
    // const currentAccounts = msalInstance.getAllAccounts()
    // consoleLog("currentAccounts = ", currentAccounts)
    //
    // if (currentAccounts.length === 0) {
    //   return "None"
    // } else if (currentAccounts.length > 1) {
    //   // Add choose account code here
    //   console.warn("Multiple accounts detected.")
    // } else if (currentAccounts.length === 1) {
    //   return currentAccounts[0]
    // }
  }
}
