import { defineStore } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { customerSearchDto } from "@/api/dtos/JunkDtos"
import { errorSnackbar } from "@/utils/SnackbarBuilder"

export const useCustomerStore = defineStore("customer", {
  state: () => ({
    customers: [],
    isLoadingCustomers: false,
    isLoadingCreateJob: false,
    itemsPerPageOptions: [10, 25, 50, 100],
    customerPaginationOptions: {
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      totalCount: 0,
      hasPrevious: false,
      hasNext: false
    },
    searchFirstName: "",
    searchLastName: "",
    searchPhone: "",
    searchEmail: "",
    searchBusinessName: "",
    searchJobNumber: ""
  }),
  getters: {
    getSearchParamFirstName() {
      return this.searchFirstName ?? ""
    },
    getSearchParamLastName() {
      return this.searchLastName ?? ""
    },
    getSearchParamPhone() {
      return this.searchPhone ?? ""
    },
    getSearchParamEmail() {
      return this.searchEmail ?? ""
    },
    getSearchParamBusinessName() {
      return this.searchBusinessName ?? ""
    },
    getSearchParamJobNumber() {
      return this.searchJobNumber ?? ""
    },
    getCustomers() {
      return this.customers?.slice() ?? []
    },
    getCustomerPaginationOptions() {
      return this.customerPaginationOptions
    },
    getItemsPerPageOptions() {
      return this.itemsPerPageOptions
    },
    getIsLoadingCustomers() {
      return this.isLoadingCustomers
    },
    getIsLoadingCreateJob() {
      return this.isLoadingCreateJob
    }
  },
  actions: {
    getCustomerById(customerId) {
      return this.customers?.slice()?.find(c => c.id === customerId) ?? {}
    },
    async setSearchParamFirstName(firstName) {
      this.searchFirstName = firstName
    },
    async setSearchParamLastName(lastName) {
      this.searchLastName = lastName
    },
    async setSearchParamPhone(phone) {
      this.searchPhone = phone
    },
    async setSearchParamEmail(email) {
      this.searchEmail = email
    },
    async setSearchParamBusinessName(businessName) {
      this.searchBusinessName = businessName
    },
    async setSearchParamJobNumber(jobNumber) {
      this.searchJobNumber = jobNumber
    },
    async createCustomer(dto) {
      this.isLoadingCustomers = true
      return await junkApi
        .createCustomer(dto)
        .then(data => {
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = errorSnackbar(errorMessageHandler(error))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingCustomers = false
        })
    },
    async createJunkJob(dto) {
      this.isLoadingCreateJob = true
      return await junkApi
        .createJunkJob(dto)
        .then(data => {
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = errorSnackbar(errorMessageHandler(error))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingCreateJob = false
        })
    },
    setCustomers(customers) {
      customers = customers.map(c => {
        let result = {
          ...c
        }
        c.jobs?.forEach(j => j.customerId = c.id)
        return result
      })
      this.customers = customers
    },
    async searchCustomers({ pageNumber, pageSize }) {
      this.isLoadingCustomers = true
      let dto = customerSearchDto(
        this.getSearchParamFirstName,
        this.getSearchParamLastName,
        this.getSearchParamPhone,
        this.getSearchParamEmail,
        this.getSearchParamBusinessName,
        this.getSearchParamJobNumber,
        pageNumber,
        pageSize,
        useMainStore().getUserOperatingUnitIds
      )
      return await junkApi
        .fetchCustomers(dto)
        .then(data => {
          this.customerPaginationOptions = {
            currentPage: data.currentPage,
            totalPages: data.totalPages,
            pageSize: data.pageSize,
            totalCount: data.totalCount,
            hasPrevious: data.hasPrevious,
            hasNext: data.hasNext
          }
          this.setCustomers(data.items)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = errorSnackbar(errorMessageHandler(error))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingCustomers = false
        })
    }
  }
})
