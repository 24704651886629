import { defineStore } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import documentApi from "@/api/Document"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    isLoadingDashboard: false,
    isLoadingDashboardSlotAllocation: false,
    operatingUnitSlotAllocations: [],
    isLoadingDashboardRevenueByDriverReport: false,
    revenueByDriverReportData: 0.0,
    isLoadingDashboardRevenueByTruckReport: false,
    revenueByTruckReportData: 0.0,
    isLoadingOperatingUnitRevenue: false,
    operatingUnitRevenueData: [
      {
        dateDisplay: "",
        confirmedRevenue: 0,
        pendingRevenue: 0,
        cancelledCount: 0
      }
    ],
    confirmedRevenue: 0,
    pendingRevenue: 0,
    cancelledCount: 0,
    averageJobLifecycleTimeInHours: 0,
    isLoadingDashboardScheduledConversionReport: false,
    scheduledConversionReportData: {},
    isLoadingDashboardClosedConversionReport: false,
    closedConversionReportData: {},
    isLoadingDashboardSelfScheduledJobs: false,
    selfScheduledJobs: [],
    isLoadingDashboardCloseOutReminderJobs: false,
    closeOutReminderJobs: [],
    isLoadingReceiptsNotSent: false,
    receiptsNotSent: [],
    activeFranchiseIdsInOperatingUnit: [],
    franchisesInOperatingUnit: []
  }),
  getters: {
    getIsLoadingDashboard() {
      return this.isLoadingDashboard
    },
    getIsLoadingDashboardSlotAllocation() {
      return this.isLoadingDashboardSlotAllocation
    },
    getOperatingUnitSlotAllocations() {
      return this.operatingUnitSlotAllocations?.slice()?.reduce((result, item) => {
        const scheduledDate = item.scheduledDate

        if (!result[scheduledDate]) {
          result[scheduledDate] = []
        }

        result[scheduledDate].push(item)
        return result
      }, {})
    },
    getOperatingUnitSlotAllocationsOperatingUnit() {
      let deepClone = JSON.parse(JSON.stringify(this.getOperatingUnitSlotAllocations))
      let summationsByDate = {}

      Object.keys(deepClone).forEach(key => {
        summationsByDate[key] = deepClone[key].reduce(
          (acc, item) => {
            acc.totalSlotsRemaining += item.totalSlotsRemaining
            acc.firstSlotsScheduled += item.firstSlotsScheduled
            acc.amSlotsScheduled += item.amSlotsScheduled
            acc.pmSlotsScheduled += item.pmSlotsScheduled
            acc.noPreferenceSlotsScheduled += item.noPreferenceSlotsScheduled
            acc.totalSlotsScheduled += item.totalSlotsScheduled
            return acc
          },
          {
            totalSlotsRemaining: 0,
            firstSlotsScheduled: 0,
            amSlotsScheduled: 0,
            pmSlotsScheduled: 0,
            noPreferenceSlotsScheduled: 0,
            totalSlotsScheduled: 0
          }
        )
      })
      return summationsByDate
    },
    getIsLoadingReceiptsNotSent() {
      return this.isLoadingReceiptsNotSent
    },
    getReceiptsNotSent() {
      return this.receiptsNotSent.slice() ?? []
    },
    getIsLoadingDashboardRevenueByDriverReport() {
      return this.isLoadingDashboardRevenueByDriverReport
    },
    getRevenueByDriverReportData() {
      return this.revenueByDriverReportData
    },
    getIsLoadingDashboardRevenueByTruckReport() {
      return this.isLoadingDashboardRevenueByTruckReport
    },
    getRevenueByTruckReportData() {
      return this.revenueByTruckReportData
    },
    getIsLoadingOperatingUnitRevenue() {
      return this.isLoadingOperatingUnitRevenue
    },
    getOperatingUnitRevenueData() {
      return this.operatingUnitRevenueData
    },
    getAverageJobLifecycleTimeInHours() {
      return this.averageJobLifecycleTimeInHours
    },
    getConfirmedRevenue() {
      return this.confirmedRevenue
    },
    getPendingRevenue() {
      return this.pendingRevenue
    },
    getCancelledCount() {
      return this.cancelledCount
    },
    getIsLoadingDashboardScheduledConversionReport() {
      return this.isLoadingDashboardScheduledConversionReport
    },
    getScheduledConversionReportData() {
      return this.scheduledConversionReportData
    },
    getIsLoadingDashboardClosedConversionReport() {
      return this.isLoadingDashboardClosedConversionReport
    },
    getClosedConversionReportData() {
      return this.closedConversionReportData
    },
    getIsLoadingDashboardSelfScheduledJobs() {
      return this.isLoadingDashboardSelfScheduledJobs
    },
    getSelfScheduledJobs() {
      return this.selfScheduledJobs.slice() ?? []
    },
    getIsLoadingDashboardCloseOutReminderJobs() {
      return this.isLoadingDashboardCloseOutReminderJobs
    },
    getCloseOutReminderJobs() {
      return this.closeOutReminderJobs.slice() ?? []
    },
    getFranchisesInOperatingUnit() {
      return this.franchisesInOperatingUnit.slice() ?? []
    }
  },
  actions: {
    async fetchFranchisesInOperatingUnit() {
      this.franchisesInOperatingUnit = []
      this.isLoadingReceiptsNotSent = true

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchActiveFranchisesInOperatingUnit(selectedOperatingUnitId)
        .then(response => {
          this.franchisesInOperatingUnit = response
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises in Operating Unit! ${errorMessage}`, "Retry", () => {
            this.fetchFranchisesInOperatingUnit(selectedOperatingUnitId)
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingReceiptsNotSent = false
        })
    },
    async fetchReceiptsNotSent() {
      this.isLoadingDashboard = true
      this.isLoadingReceiptsNotSent = true
      this.receiptsNotSent = []

      const franchiseIds = this.franchisesInOperatingUnit.map(f => f.franchiseId)

      return await documentApi
        .fetchReceiptsNotSent(franchiseIds)
        .then(response => {
          this.receiptsNotSent = response
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchReceiptsNotSent()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingReceiptsNotSent = false
          this.isLoadingDashboard = false
        })
    },
    async fetchCloseOutReminderJobs() {
      this.isLoadingDashboard = true
      this.isLoadingDashboardCloseOutReminderJobs = true
      this.closeOutReminderJobs = []

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId
      return await junkApi
        .fetchCloseOutReminderJobs(selectedOperatingUnitId)
        .then(response => {
          this.closeOutReminderJobs = response
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchCloseOutReminderJobs()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardCloseOutReminderJobs = false
          this.isLoadingDashboard = false
        })
    },
    async createJobLead(dto) {
      this.isLoadingDashboardSelfScheduledJobs = true

      return await junkApi
        .createJobLead(dto)
        .then(response => {
          this.selfScheduledJobs = this.selfScheduledJobs.map(j =>
            j.jobId === response.jobId
              ? {
                  ...j,
                  isLeadHandled: response.isHandled,
                  leadHandledBy: response.handledBy
                }
              : j
          )
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.createJobLead(dto)
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardSelfScheduledJobs = false
        })
    },
    async updateJobLead(dto) {
      this.isLoadingDashboardSelfScheduledJobs = true

      return await junkApi
        .updateJobLead(dto)
        .then(response => {
          this.selfScheduledJobs = this.selfScheduledJobs.map(j =>
            j.jobId === response.jobId
              ? {
                  ...j,
                  isLeadHandled: response.isHandled,
                  leadHandledBy: response.handledBy
                }
              : j
          )
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.updateJobLead(dto)
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardSelfScheduledJobs = false
        })
    },
    async fetchSelfScheduledJobs() {
      this.isLoadingDashboard = true
      this.isLoadingDashboardSelfScheduledJobs = true
      this.selfScheduledJobs = []

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchSelfScheduledJobs(selectedOperatingUnitId)
        .then(response => {
          this.selfScheduledJobs = response
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchSelfScheduledJobs()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardSelfScheduledJobs = false
          this.isLoadingDashboard = false
        })
    },
    async fetchOperatingUnitSlotAllocation() {
      this.isLoadingDashboard = true
      this.isLoadingDashboardSlotAllocation = true

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchOperatingUnitSlotAllocation(selectedOperatingUnitId)
        .then(response => {
          this.operatingUnitSlotAllocations = response
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchOperatingUnitSlotAllocation()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardSlotAllocation = false
          this.isLoadingDashboard = false
        })
    },
    async fetchRevenueForTruckAndDriver() {
      this.isLoadingDashboard = true
      this.isLoadingDashboardRevenueByDriverReport = true
      this.isLoadingDashboardRevenueByTruckReport = true

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchRevenueForTruckAndDriver(selectedOperatingUnitId)
        .then(response => {
          this.revenueByDriverReportData = response.driverRevenue
          this.revenueByTruckReportData = response.truckRevenue
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchRevenueForTruckAndDriver()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardRevenueByTruckReport = false
          this.isLoadingDashboardRevenueByDriverReport = false
          this.isLoadingDashboard = false
        })
    },
    async fetchOperatingUnitRevenue() {
      this.isLoadingDashboard = true
      this.isLoadingOperatingUnitRevenue = true

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchOperatingUnitRevenueReport(selectedOperatingUnitId)
        .then(response => {
          this.operatingUnitRevenueData = response.revenueChartCardData
          this.confirmedRevenue = response.confirmedRevenue
          this.pendingRevenue = response.pendingRevenue
          this.cancelledCount = response.cancelledCount
          this.averageJobLifecycleTimeInHours = response.averageJobLifecycleTimeInHours
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchOperatingUnitRevenue()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingOperatingUnitRevenue = false
          this.isLoadingDashboard = false
        })
    },
    async fetchScheduledConversionReport() {
      this.isLoadingDashboard = true
      this.isLoadingDashboardScheduledConversionReport = true

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchScheduledConversionReport(selectedOperatingUnitId)
        .then(response => {
          this.scheduledConversionReportData = Object.assign({}, response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchScheduledConversionReport()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardScheduledConversionReport = false
          this.isLoadingDashboard = false
        })
    },
    async fetchClosedConversionReport() {
      this.isLoadingDashboard = true
      this.isLoadingDashboardClosedConversionReport = true

      const selectedOperatingUnitId = useMainStore().getSelectedOperatingUnitId

      return await junkApi
        .fetchClosedConversionReport(selectedOperatingUnitId)
        .then(response => {
          this.closedConversionReportData = Object.assign({}, response)
          return Promise.resolve(response)
        })
        .catch(error => {
          const errorMessage = errorMessageHandler(error)
          const errorSnackbarItem = persistentErrorSnackbarWithPositiveAction(errorMessage, "Retry", () => {
            this.fetchClosedConversionReport()
          })
          useSnackbarStore().addSnackbar(errorSnackbarItem)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingDashboardClosedConversionReport = false
          this.isLoadingDashboard = false
        })
    }
  }
})
