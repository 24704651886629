<template>
  <v-card class="card_right_radius">
    <v-navigation-drawer
      class="elevation-24"
      ref="app-nav-drawer-root"
      color="navigationDrawerBackground"
      :expand-on-hover="isExpandOnHoverEnabled"
      :mini-variant="isExpandOnHoverEnabled"
      app
      permanent
      stateless
    >
      <v-list :dense="$vuetify.breakpoint.mobile">
        <v-list-item class="text-end align-self-end text-end justify-end align-end align-content-end">
          <v-col class="ma-0 pa-0" />
          <v-spacer></v-spacer>
          <v-col class="ma-0 pa-0 mb-1 secondaryLight d-inline-flex" style="border-radius: 16px">
            <v-btn aria-label="Theme Toggle Button" elevation="0" class="mx-1" icon ripple :disabled="isThemeToggleDisabled" @click="this.toggleTheme">
              <v-icon v-if="this.$vuetify.theme.dark" color="primary">mdi-white-balance-sunny</v-icon>
              <v-icon class="transparent" v-else>mdi-moon-waning-crescent</v-icon>
            </v-btn>
            <v-divider vertical class="my-1 secondaryDark" style="opacity: 50%"></v-divider>
            <v-btn elevation="0" class="mx-1" :color="this.getPinState.color" ripple icon @click="this.toggleIsNavDrawerPinned">
              <v-icon>{{ this.getPinState.icon }}</v-icon>
            </v-btn>
          </v-col>
        </v-list-item>

        <v-list-item class="mx-n2">
          <v-list-item-content>
            <mgt-person :user-id="getMgtPersonQuery" view="threeLines" />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <mgt-get :resource="getUserMetadataQuery" scopes="User.Read.All,Group.Read.All" version="v1.0" cache-enabled="true" @dataChange="onDataChanged($event)"> </mgt-get>

      <v-list-item class="my-n4 me-n8" :dense="$vuetify.breakpoint.mobile">
        <v-col class="mx-n4 me-n8 flex-fill align-content-stretch align-self-stretch">
          <v-autocomplete
            ref="app-nav-drawer-operating-unit-selector"
            class="mt-2 mb-n1"
            :dense="$vuetify.breakpoint.mobile"
            color="primaryLight"
            dark
            label="Selected Operating Unit"
            :items="getOperatingUnitMetadataList"
            :value="getSelectedOperatingUnit"
            item-text="displayName"
            :key="operatingUnitSelectorKey"
            @focusin="focusOnOperatingUnitSelector"
            @change="setSelectedOperatingUnitMetadataWrapper"
            :disabled="isOperatingUnitSelectorDisabled"
            :menu-props="{ bottom: true, offsetY: true }"
            return-object
            flat
          ></v-autocomplete>
        </v-col>
      </v-list-item>

      <v-divider class="ms-2 me-2 mt-n1"></v-divider>

      <v-list nav shaped :dense="$vuetify.breakpoint.mobile">
        <v-list-item data-cy="app-nav-drawer-dashboard" ripple style="color: var(--v-primary-base)" :to="{ name: 'Dashboard' }">
          <v-list-item-icon color="white">
            <v-icon color="white">mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title ref="app-nav-drawer-dashboard-title" style="color: white">Dashboard</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="reportsVisible"
          data-cy="app-nav-drawer-reports"
          ripple
          style="color: var(--v-primary-base)"
          href="https://app.powerbi.com/groups/me/apps/0d314469-913b-4c3f-ac06-d25e4c28daf5/reports/07265a06-a49b-419b-86de-919cda309762/ReportSection545c41577b0a327c4aae?ctid=6ba5406c-948c-40f7-9856-4f6aeb07913f&experience=power-bi"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-list-item-icon color="white">
            <v-icon color="white">mdi-finance</v-icon>
          </v-list-item-icon>
          <v-list-item-title ref="app-nav-drawer-reports-title" style="color: white">Reports</v-list-item-title>
        </v-list-item>

        <v-list-item data-cy="app-nav-drawer-customer" ripple style="color: var(--v-primary-base)" :to="{ name: 'Customer' }">
          <v-list-item-icon color="white">
            <v-icon color="white">mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title ref="app-nav-drawer-customer-title" style="color: white">Customer</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="app-nav-drawer-job-management" ripple style="color: var(--v-primary-base)" :to="{ name: 'JobManagement' }">
          <v-list-item-icon color="white">
            <v-icon color="white">mdi-chair-rolling</v-icon>
          </v-list-item-icon>
          <v-list-item-title ref="app-nav-drawer-job-management-title" style="color: white">Job Management</v-list-item-title>
        </v-list-item>
        <v-list-item data-cy="app-nav-drawer-job-queue" ripple style="color: var(--v-primary-base)" :to="{ name: 'JobQueue' }">
          <v-list-item-icon>
            <v-icon color="white">mdi-clipboard-list-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title ref="app-nav-drawer-job-queue-title" style="color: white">Job Queue</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAdminItemVisible" data-cy="app-nav-drawer-data-fix" ripple style="color: var(--v-primary-base)" :to="{ name: 'DataFix' }">
          <v-list-item-icon>
            <v-icon color="white">mdi-database-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title ref="app-nav-drawer-data-fix-title" style="color: white">Data Fix</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider class="ms-2 me-2"></v-divider>

      <template v-slot:append>
        <v-divider class="ms-2 me-2"></v-divider>
        <v-list nav shaped :dense="$vuetify.breakpoint.mobile">
          <v-list-item data-cy="app-nav-drawer-hub" ripple style="color: var(--v-primary-base)" href="https://twomenandajunktruck.sharepoint.com/sites/thehub" target="_blank" rel="noopener noreferrer">
            <v-list-item-icon color="white">
              <v-icon color="white">mdi-hub-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title ref="app-nav-drawer-hub-title" style="color: white">Hub</v-list-item-title>
          </v-list-item>
          <v-divider v-if="isAdminItemVisible"></v-divider>
          <v-list-item v-if="isAdminItemVisible" class="mt-2" data-cy="app-nav-drawer-admin-settings" ripple style="color: var(--v-primary-base)" :to="{ name: 'AdministratorSettings' }">
            <v-list-item-icon>
              <v-icon color="white">mdi-security</v-icon>
            </v-list-item-icon>
            <v-list-item-title ref="app-nav-drawer-admin-settings-title" style="color: white">Administrator</v-list-item-title>
          </v-list-item>
          <v-divider v-if="isSettingsItemVisible"></v-divider>
          <v-list-item v-if="isSettingsItemVisible" class="mt-2" data-cy="app-nav-drawer-settings" ripple style="color: var(--v-primary-base)" :to="{ name: 'Settings' }">
            <v-list-item-icon>
              <v-icon color="white">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title ref="app-nav-drawer-settings-title" style="color: white">Settings</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item id="log-out" class="mt-2" data-cy="app-nav-drawer-log-out-item" @click="logout" style="color: var(--v-primary-base)" ripple>
            <v-list-item-icon>
              <v-icon color="white">mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title ref="app-nav-drawer-log-out-title" style="color: white">Log Out</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item id="environment-item" class="mb-n2" data-cy="environment-item" inactive dense style="color: var(--v-primary-base)">
            <v-list-item-icon>
              <v-icon id="environment-item-icon" :color="getSignalRColor">mdi-radiobox-marked</v-icon>
            </v-list-item-icon>
            <v-list-item-title :style="{ color: getEnvironmentColor }">{{ getEnvironment.toUpperCase() }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import applicationColors from "@/utils/ApplicationColors"
import { mapActions, mapState } from "pinia"
import { consoleLog } from "@/utils/Logging"
import getEnv from "@/utils/Env"
import { azureauth } from "../../appsettings.json"
import { app } from "../../appsettings.json"
import { EnvironmentVariant } from "@/enums/Environments"
import { useMainStore } from "@/stores/Main"
import { useSnackbarStore } from "@/stores/Snackbar"

export default {
  name: "AppNavDrawer",
  data() {
    return {
      isNavDrawerPinned: true,
      operatingUnitSelectorKey: 0,
      selectedOperatingUnitMetadata: {},
      isThemeToggleDisabled: true
    }
  },
  methods: {
    ...mapActions(useMainStore, ["setIsLoadingAppFullScreen", "resetIsLoadingAppFullScreen", "setOperatingUnitMetadataList", "setSelectedOperatingUnitMetadata", "fetchNonAdEmployeesByOperatingUnitId"]),
    ...mapActions(useSnackbarStore, ["addSnackbar"]),
    async onDataChanged(data) {
      console.log("myFunc = ", data.detail.response.value)
      let metadataListOriginal = data?.detail?.response?.value ?? []
      console.log("taggy.metadataList = ", metadataListOriginal)
      let metadataList = this.formatAndOrderOperatingUnitMetadataList(metadataListOriginal)

      await this.setOperatingUnitMetadataList(metadataList)
      consoleLog("taggy.setOperatingUnitMetadataList = ", metadataList)

      // if we don't have a value for _selectedOperatingUnitId, we need to set it to the first value in the metadataList
      console.log("taggy.this.getSelectedOperatingUnitId === null", this.getSelectedOperatingUnitId === null)
      console.log("taggy.this.getSelectedOperatingUnitId === undefined", this.getSelectedOperatingUnitId === undefined)

      if (this.getSelectedOperatingUnitId === null || this.getSelectedOperatingUnitId === undefined) {
        await this.setSelectedOperatingUnitMetadataWrapper(metadataList[0])
        this.toggleOperatingUnitSelectorKey()
        console.log("taggy.Entered if Block")
      } else {
        consoleLog("onDataChanged in ELSE")
        let selectedOperatingUnit = JSON.parse(sessionStorage.getItem("selectedOperatingUnit"))
        // await this.setSelectedOperatingUnitMetadata(selectedOperatingUnit)
        await this.setSelectedOperatingUnitMetadataWrapper(selectedOperatingUnit)
      }

      await this.fetchNonAdEmployeesByOperatingUnitId(this.getSelectedOperatingUnitId)
    },
    formatAndOrderOperatingUnitMetadataList(operatingUnitMetadataList) {
      return operatingUnitMetadataList
        .map(ou => {
          const parts = ou.displayName.split("-")
          const startIndex = this.getEnvironment.toLowerCase() === EnvironmentVariant.PRODUCTION ? 0 : 1
          let numberPart = parts[startIndex]

          const labelPart = parts
            .slice(startIndex + 1)
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
          const outputString = `${numberPart} - ${labelPart}`

          return { id: ou.id, displayName: outputString }
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
    },
    async logout() {
      await this.$msal.signOut()
    },
    setIsNavDrawerPinned(isNavDrawerPinned) {
      this.isNavDrawerPinned = isNavDrawerPinned
      localStorage.setItem("junkIsNavDrawerPinned", this.isNavDrawerPinned)
    },
    async focusOnOperatingUnitSelector() {
      if (!this.isNavDrawerPinned) this.setIsNavDrawerPinned(true)
      this.$nextTick(() => {
        this.$refs["app-nav-drawer-operating-unit-selector"].focus()
        this.$refs["app-nav-drawer-operating-unit-selector"].isMenuActive = true
      })
    },
    toggleIsNavDrawerPinned() {
      window.dispatchEvent(new Event("resize"))
      this.setIsNavDrawerPinned(!this.isNavDrawerPinned)
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem("junkIsDarkThemeEnabled", this.$vuetify.theme.dark.toString())
    },
    toggleOperatingUnitSelectorKey() {
      this.operatingUnitSelectorKey === 1 ? (this.operatingUnitSelectorKey = 0) : (this.operatingUnitSelectorKey = 1)
    },
    async setSelectedOperatingUnitMetadataWrapper(operatingUnitMetadataObj) {
      if (operatingUnitMetadataObj !== null && operatingUnitMetadataObj !== undefined) {
        consoleLog("operatingUnitMetadataObj = ", operatingUnitMetadataObj)
        this.$refs["app-nav-drawer-operating-unit-selector"].blur()
        consoleLog("setSelectedOperatingUnitMetadata.operatingUnitMetadataObj", operatingUnitMetadataObj)
        await this.setSelectedOperatingUnitMetadata(operatingUnitMetadataObj)
        this.$eventBus.$emit("operating-unit-updated", operatingUnitMetadataObj)
      }
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnit", "getOperatingUnitMetadataList", "getSelectedOperatingUnitId", "getIsSignalrConnected"]),
    getUserMetadataQuery() {
      const typeKey = getEnv("AZURE_DIRECTORY_TYPE_EXTENSION_KEY") || azureauth.VUE_APP_AZURE_DIRECTORY_TYPE_EXTENSION_KEY
      const ouEnvironmentKey = getEnv("AZURE_DIRECTORY_ENVIRONMENT_EXTENSION_KEY") || azureauth.VUE_APP_AZURE_DIRECTORY_ENVIRONMENT_EXTENSION_KEY
      const environment = getEnv("VUE_APP_ENVIRONMENT") || app.VUE_APP_ENVIRONMENT
      console.log("77-ouEnvironmentKey = ", ouEnvironmentKey)
      console.log("77-Env = ", environment)

      let getUserId = this.$msal?.currentAccount?.localAccountId ?? ""
      consoleLog("getUserId - ", getUserId)
      return `/users/${getUserId}/transitiveMemberOf/microsoft.graph.group?$filter=(${typeKey} eq 'operatingUnit' and ${ouEnvironmentKey} eq '${environment}')&$count=true&$select=id,displayName`
    },
    getSignalRColor() {
      return this.getIsSignalrConnected ? "green" : "red"
    },
    getMgtPersonQuery() {
      let getUserId = this.$msal?.currentAccount?.localAccountId ?? ""
      consoleLog("getUserId - ", getUserId)
      return getUserId
    },
    getPinState() {
      return this.isNavDrawerPinned === true ? { icon: "mdi-pin-off", color: "primary" } : { icon: "mdi-pin", color: applicationColors.tmtGray.medium }
    },
    isExpandOnHoverEnabled() {
      return !this.isNavDrawerPinned || this.$vuetify.breakpoint.smAndDown
    },
    isOperatingUnitSelectorDisabled() {
      return this.getOperatingUnitMetadataList?.length <= 1
    },
    getEnvironment() {
      return getEnv("VUE_APP_ENVIRONMENT") || app.VUE_APP_ENVIRONMENT
    },
    getEnvironmentColor() {
      return this.getEnvironment.toLowerCase() === EnvironmentVariant.PRODUCTION ? "orange" : "var(--v-primary-base)"
    },
    isSettingsItemVisible() {
      return this.$msal.getUserHasReadSettingsPermission()
    },
    isAdminItemVisible() {
      consoleLog("this.$msal?.currentAccount?.idTokenClaims?.roles? = ", this.$msal)
      return this.$msal.getUserHasOnboardFranchisePermission()
    },
    reportsVisible() {
      return this.$msal.getUserHasCorporateAdministratorRole() ||
        this.$msal.getUserHasJunkManagerRole() ||
        this.$msal.getUserHasFranchiseOwnerRole() ||
        this.$msal.getUserHasCSRRole() ||
        this.$msal.getUserHasSalesSupportRole()
    }
  },
  async created() {
    const environment = getEnv("VUE_APP_ENVIRONMENT") || app.VUE_APP_ENVIRONMENT
    console.log("ENVIRONMENT! = ", environment)
    this.isThemeToggleDisabled = environment === EnvironmentVariant.STAGE

    this.isNavDrawerPinned = localStorage.getItem("junkIsNavDrawerPinned")?.toLowerCase() === "true" ?? false
    try {
      let selectedOperatingUnit = JSON.parse(sessionStorage.getItem("selectedOperatingUnit"))
      consoleLog("AppNavDrawer.created.selectedOperatingUnitId = ", selectedOperatingUnit)
      await this.setSelectedOperatingUnitMetadata(selectedOperatingUnit)
    } catch (e) {
      console.error("Unable to parse JSON")
    }
  }
}
</script>

<style scoped>
#user-name {
  color: white;
}

#user-email {
  color: white;
}

.v-divider {
  border-width: thin;
}

.v-input {
  font-size: 0.95em;
}

mgt-person {
  --color: white;
  --avatar-size: 36px;
  --line2-color: white;
  --line3-color: white;
}
</style>
