<template>
  <v-container fluid class="ma-0 pa-0">
    <v-card :loading="getIsLoadingDiscounts">
      <v-card-title class="headline">
        <v-row>
          <v-col>
            <span class="headline me-1 word-wrap">National Discounts</span>
            <base-tool-tip
              dialog-title="By opting in, you acknowledge you will be participating for the duration of the national discount."
              tooltip-icon="mdi-information-outline"
              icon-color="primary"
              tooltipColor="primary"
              nudge-dialog="50"
              is-bottom-dialog
            ></base-tool-tip>
          </v-col>
          <v-col>
            <v-switch ref="settings-national-discounts-card-filter-switch" class="float-end" v-model="showPastElectedDiscounts" label="Include Past Elected Discounts" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-responsive class="overflow-y-auto" max-height="800">
          <v-data-table
            ref="settings-national-discounts-card-data-table"
            :headers="headers"
            :items="getFilteredDiscounts"
            :loading="getIsLoadingDiscounts"
            item-key="nationalDiscountId"
            sort-by="name"
            fixed-header
            show-expand
            single-expand
            single-select
            disable-pagination
            hide-default-footer
            style="min-height: 120px"
          >
            <template v-slot:no-data>
              <v-col class="ma-0 pa-0 align-self-center d-flex">
                <app-not-found
                  title="No National Discounts!"
                  content="There are currently no active national discounts."
                >
                  <template v-slot:actions>
                    <v-btn
                      class="mt-4 primary justify-center align-self-center"
                      ref="settings-national-discounts-card-fetch-button"
                      ripple
                      rounded
                      large
                      @click="fetchNationalDiscounts"
                      >Fetch National Discounts</v-btn
                    >
                  </template>
                </app-not-found>
              </v-col>
            </template>
            <template v-slot:item.amount="{ item }">
              <app-number-formatter
                ref="settings-national-discounts-card-amount-number-formatter"
                :amount="item.amount"
                :is-percentage="item.isPercentage"
                number-format-style="currency"
                currency-sign="accounting"
              />
            </template>
            <template v-slot:item.beginDate="{ item }">
              <app-date-time-locale-formatter
                ref="settings-national-discounts-card-begin-date-formatter"
                :date-time-value="item.beginDate"
              />
            </template>
            <template v-slot:item.endDate="{ item }">
              <app-date-time-locale-formatter
                ref="settings-national-discounts-card-end-date-formatter"
                :date-time-value="item.endDate"
              />
            </template>
            <template v-slot:item.actions="{ item }">
              <div ref="settings-national-discounts-card-actions" v-if="isDiscountApplicable(item)">
                <v-btn
                  ref="settings-national-discounts-card-actions-save-item-button"
                  color="primary"
                  icon
                  @click="setIsAddFranchisesInNationalDiscountDialogVisible(true, item)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </div>
              <div v-else />
            </template>
            <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
              <td ref="settings-national-discounts-card-franchise-elected-expand-cell" v-if="item.franchiseElectedDiscounts.length > 0" class="text-start">
                <v-btn
                  ref="settings-national-discounts-card-franchise-elected-expand-button"
                  icon
                  @click="expand(!isExpanded)"
                  class="v-data-table__expand-icon"
                  :class="{ 'v-data-table__expand-icon--active': isExpanded }">
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td ref="settings-national-discounts-card-franchise-elected-expanded" class="pa-0" :colspan="headers.length">
                <v-responsive class="overflow-y-auto" max-height="350">
                  <v-data-table
                    ref="settings-national-discounts-card-franchise-elected-data-table-expanded"
                    :headers="subHeaders"
                    :items="item.franchiseElectedDiscounts"
                    :item-class="itemRowBackground"
                    item-key="discountId"
                    no-data-text="There are no franchises enrolled in this national discount."
                    fixed-header
                    mobile-breakpoint="590"
                    disable-pagination
                    hide-default-footer
                  >
                    <template v-slot:item.franchiseNumberAndName="{ value }">
                      <span ref="settings-national-discounts-card-franchise-elected-name-value-expanded" class="text-md-body-1 grey--text">{{ value }}</span>
                    </template>
                    <template v-slot:item.beginDate="{ value }">
                      <app-date-time-locale-formatter ref="settings-national-discounts-card-franchise-elected-begin-date-formatter-expanded" :date-time-value="value" />
                    </template>
                    <template v-slot:item.endDate="{ value }">
                      <app-date-time-locale-formatter ref="settings-national-discounts-card-franchise-elected-end-date-formatter-expanded" :date-time-value="value" />
                    </template>
                  </v-data-table>
                </v-responsive>
              </td>
            </template>
          </v-data-table>
        </v-responsive>
      </v-card-text>
    </v-card>
    <settings-add-franchise-national-discount-dialog
      ref="settings-national-discounts-card-add-franchise-dialog"
      v-if="isAddFranchiseNationalDiscountDialogVisible"
      :is-dialog-visible="isAddFranchiseNationalDiscountDialogVisible"
      :national-discount="nationalDiscountSelected"
      @closeDialog="setIsAddFranchisesInNationalDiscountDialogVisible(false, {})"
    ></settings-add-franchise-national-discount-dialog>
  </v-container>
</template>

<script>
import BaseToolTip from "@/components/BaseToolTip"
import AppNotFound from "@/components/AppNotFound"
import AppNumberFormatter from "@/components/AppNumberFormatter"
import AppDateTimeLocaleFormatter from "@/components/AppDateTimeLocaleFormatter"
import SettingsAddFranchiseNationalDiscountDialog from "@/components/SettingsAddFranchiseNationalDiscountDialog"
import { formatAsDateOnly, isDateOnOrAfterToday, todayAsDate } from "@/utils/DateTimeFormatters"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"

export default {
  name: "SettingsNationalDiscountsCard",
  components: { BaseToolTip, AppNotFound, AppNumberFormatter, AppDateTimeLocaleFormatter, SettingsAddFranchiseNationalDiscountDialog },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showPastElectedDiscounts: false,
      isAddFranchiseNationalDiscountDialogVisible: false,
      nationalDiscountSelected: {}
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnitId"]),
    ...mapState(useSettingsStore, ["getIsLoadingDiscounts", "getNationalDiscounts", "getActiveAndFutureNationalDiscounts"]),
    headers() {
      let headers = [
        { text: "Name", align: "start", value: "name" },
        { text: "Discount", align: "start", value: "amount" },
        { text: "Promo Code", align: "start", value: "promoCode" },
        { text: "Begin Date", align: "start", value: "beginDate" },
        { text: "End Date", align: "start", value: "endDate" },
        { text: "Actions", align: "end", value: "actions", sortable: false }
      ]

      return this.isActionsDisabled ? headers.slice().filter(header => header.text !== "Actions") : headers
    },
    getFilteredDiscounts() {
      return this.showPastElectedDiscounts ? this.getNationalDiscounts : this.getActiveAndFutureNationalDiscounts
    },
    subHeaders() {
      return [
        { text: "Franchise", align: "start", value: "franchiseNumberAndName", class: "secondaryDark white--text" },
        { text: "Begin Date", align: "center", value: "beginDate", class: "secondaryDark white--text" },
        { text: "End Date", align: "center", value: "endDate", class: "secondaryDark white--text" }
      ]
    }
  },
  methods: {
    todayAsDate,
    formatAsDateOnly,
    ...mapActions(useSettingsStore, ["fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId"]),
    isDiscountApplicable(discount) {
      return (isDateOnOrAfterToday(discount.endDate))
    },
    setIsAddFranchisesInNationalDiscountDialogVisible(isVisible, nationalDiscount) {
      this.isAddFranchiseNationalDiscountDialogVisible = isVisible
      this.nationalDiscountSelected = Object.assign({}, nationalDiscount)
    },
    itemRowBackground() {
      if (!this.$vuetify.breakpoint.xs) return "backgroundDisabled"
    },
    async fetchNationalDiscounts() {
      await this.fetchNationalDiscountsWithElectedFranchisesByOperatingUnitId(this.getSelectedOperatingUnitId)
    }
  },
  async created() {
    await this.fetchNationalDiscounts()
  }
}
</script>
