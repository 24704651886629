<template>
  <base-dialog ref="admin-create-operating-unit-dialog-root" title="Create Operating Unit" dialog-image="mdi-account-plus-outline" :is-dialog-visible="isDialogVisible">
    <template class="flex-fill" v-slot:content>
      <v-form class="ma-0 pa-0" ref="admin-create-operating-unit-dialog-form">
        <v-row dense>
          <v-col>
            <v-text-field
              ref="admin-create-operating-unit-dialog-operating-unit-number-text"
              label="Operating Unit Number *"
              type="number"
              counter="5"
              v-model="operatingUnitNumber"
              :rules="isRequiredRules"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              ref="admin-create-operating-unit-dialog-operating-unit-name-text"
              label="Operating Unit Name *"
              counter="50"
              v-model="operatingUnitName"
              :rules="isRequiredRules"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field
              ref="admin-create-operating-unit-dialog-dot-number-text"
              label="Department of Transportation Number"
              type="number"
              counter="50"
              v-model="dotNumber"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-checkbox
              ref="admin-create-operating-unit-dialog-is-test-operating-unit-checkbox"
              label="Is Test Operating Unit"
              v-model="isTestOperatingUnit"
              :value="isTestOperatingUnit"
              :input-value="isTestOperatingUnit"
              @change="setIsTestOperatingUnit($event)"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:actions>
      <v-btn class="pa-4" ref="admin-create-operating-unit-dialog-cancel-btn" color="primaryText" :disabled="false" @click="closeDialog" text rounded ripple>Cancel</v-btn>
      <v-btn
        class="pa-4"
        ref="admin-create-operating-unit-dialog-confirm-btn"
        color="primary"
        :loading="getIsLoadingOperatingUnitFranchisesAndSatellites"
        :disabled="getIsLoadingOperatingUnitFranchisesAndSatellites"
        @click="save"
        text
        rounded
        ripple
      >
        Confirm
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { useAdministratorStore } from "@/stores/Administrator"
import { mapState, mapActions } from "pinia"
import { createOperatingUnitDto } from "@/api/dtos/JunkDtos"

export default {
  name: "AdministratorCreateOperatingUnitDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      operatingUnitName: "",
      operatingUnitNumber: "",
      dotNumber: "",
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      isTestOperatingUnit: false
    }
  },
  methods: {
    ...mapActions(useAdministratorStore, ["createOperatingUnit"]),
    async save() {
      if (this.$refs["admin-create-operating-unit-dialog-form"].validate()) {
        let dto = createOperatingUnitDto(this.operatingUnitName, this.operatingUnitNumber, this.dotNumber, this.isTestOperatingUnit)
        await this.createOperatingUnit(dto).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    setIsTestOperatingUnit(bool) {
      this.isTestOperatingUnit = bool ?? false
    }
  },
  computed: {
    ...mapState(useAdministratorStore, ["getIsLoadingOperatingUnitFranchisesAndSatellites"])
  }
}
</script>
