<template>
  <v-card ref="job-queue-date-operating-unit-picker-card">
    <v-card-text>
      <app-date-picker-field-selector
        ref="job-queue-date-picker"
        text-field-label="Scheduled Date"
        text-field-format="MMMM D, YYYY"
        :date-picker-value="getScheduledDate"
        :minimum-date-value="minimumDate"
        :isTextFieldDense="false"
        :rounded="true"
        @updatedPickerValue="updateSelectedScheduledDate($event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import { mapActions, mapState } from "pinia"
import { useMainStore } from "@/stores/Main"
import { useJobQueueStore } from "@/stores/JobQueue"
import { todayAsDate } from "@/utils/DateTimeFormatters"

export default {
  name: "JobQueueScheduledDateCard",
  components: { AppDatePickerFieldSelector },
  data() {
    return {
      minimumDate: todayAsDate()
    }
  },
  methods: {
    ...mapActions(useJobQueueStore, [
      "setScheduledDate",
      "fetchActiveJunkTrucksInOperatingUnitByDate",
      "fetchAllQueuedJobsByScheduledDateAndOperatingUnitId",
      "fetchClaimedJobDistancesFromUser"
    ]),
    async updateSelectedScheduledDate(dateEvent) {
      await this.setScheduledDate(dateEvent)
      await this.fetchActiveJunkTrucksInOperatingUnitByDate({ date: dateEvent, operatingUnitId: this.getSelectedOperatingUnitId })
      await this.fetchAllQueuedJobsAndFetchDistancesFromUser(dateEvent, this.getSelectedOperatingUnitId)
    },
    async fetchAllQueuedJobsAndFetchDistancesFromUser(date, operatingUnitId) {
      await this.fetchAllQueuedJobsByScheduledDateAndOperatingUnitId({ date: date, operatingUnitId: operatingUnitId }).then(async () => {
        await this.fetchClaimedJobDistancesFromUser(this.getUserCurrentPosition)
      })
    }
  },
  computed: {
    ...mapState(useMainStore, ["getSelectedOperatingUnitId"]),
    ...mapState(useJobQueueStore, ["getScheduledDate", "getUserCurrentPosition"])
  }
}
</script>
