<template>
  <dashboard-chart-card
    ref="dashboard-scheduled-conversion-chart-card-root"
    title="Scheduled Conversion"
    sub-card-text="WEEK"
    :is-loading="getIsLoadingDashboardScheduledConversionReport"
  >
    <template v-slot:content>
      <v-card-text>
        <v-row>
          <v-col>
            <g-chart
              v-if="isScheduledConversionReportDataVisible"
              ref="dashboard-scheduled-conversion-chart-card-chart"
              :type="chartType"
              :data="formatData"
              :options="chartOptions"
              :settings="{ packages: ['sankey'] }"
            />
            <AppNotFound ref="dashboard-scheduled-conversion-chart-card-app-not-found" v-else content="Not enough job data available for report.">
              <template v-slot:actions>
                <div />
              </template>
            </AppNotFound>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </dashboard-chart-card>
</template>

<script>
import DashboardChartCard from "@/components/DashboardChartCard"
import { GChart } from "vue-google-charts/legacy"
import AppNotFound from "@/components/AppNotFound"
import { JobStatus } from "@/enums/JobStatus"
import { mapState } from "pinia"
import { useDashboardStore } from "@/stores/Dashboard"
import { getJobStatusColorByStatusName } from "@/utils/JobStatusColors"
import applicationColors from "@/utils/ApplicationColors"

export default {
  name: "DashboardScheduledConversionChartCard",
  components: { DashboardChartCard, "g-chart": GChart, AppNotFound },
  data() {
    return {
      chartType: "Sankey"
    }
  },
  computed: {
    ...mapState(useDashboardStore, ["getScheduledConversionReportData", "getIsLoadingDashboardScheduledConversionReport"]),
    chartOptions() {
      return {
        sankey: {
          iterations: 0,
          node: {
            label: {
              fontName: "roboto",
              fontSize: 12,
              color: this.$vuetify.theme.dark ? applicationColors.junkGray.fifty : applicationColors.junkGray.eightHundred,
              bold: false,
              italic: false
            },
            colors: [
              getJobStatusColorByStatusName(JobStatus.CREATED.name),
              getJobStatusColorByStatusName(JobStatus.SCHEDULED.name),
              getJobStatusColorByStatusName(JobStatus.ABANDONED.name)
            ],
            interactivity: true,
            nodePadding: 36,
            width: 20
          },
          link: {
            colorMode: "gradient",
            color: {
              stroke: getJobStatusColorByStatusName(JobStatus.CREATED.name),
              strokeWidth: 0.5
            },
            colors: [
              getJobStatusColorByStatusName(JobStatus.CREATED.name),
              getJobStatusColorByStatusName(JobStatus.SCHEDULED.name),
              getJobStatusColorByStatusName(JobStatus.ABANDONED.name)
            ]
          }
        }
      }
    },
    formatData() {
      let abandonedCount = this.getScheduledConversionReportData?.abandoned ?? 1
      let abandonedTitle = this.getScheduledConversionReportData?.abandonedTitle ?? "0.00 %"

      let scheduledCount = this.getScheduledConversionReportData?.scheduled ?? 1
      let scheduledTitle = this.getScheduledConversionReportData?.scheduledTitle ?? "0.00 %"

      let fromLabel = `${this.getScheduledConversionReportData?.createdTitle ?? "Created"}`

      return [
        ["From", "To", "Job Count"],
        [fromLabel, scheduledTitle, scheduledCount],
        [fromLabel, abandonedTitle, abandonedCount]
      ]
    },
    isScheduledConversionReportDataVisible() {
      return (
        this.getScheduledConversionReportData.created > 0 &&
        (this.getScheduledConversionReportData.abandoned > 0 || this.getScheduledConversionReportData.scheduled > 0) &&
        !this.getIsLoadingDashboardScheduledConversionReport
      )
    }
  }
}
</script>
