import { defineStore } from "pinia"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import routingApi from "@/api/Routing"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { errorSnackbar, persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import { JobStatus } from "@/enums/JobStatus"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import { set } from "@/store/indexeddb/KeyVal"
import {
  filterByPreferredTimeSlot,
  filterBySelectedFranchise,
  filterBySelectedStatuses,
  filterBySelectedTruckCapacity,
  filterByTotalDistance
} from "@/utils/filters/JobQueueFilters"
import { useMainStore } from "@/stores/Main"

export const useJobQueueStore = defineStore("jobQueue", {
  state: () => ({
    scheduledDate: todayAsDate(),
    selectedJunkTruckId: undefined,
    activeEmployees: [],
    activeTrucks: [],
    jobQueue: [],
    jobAddressesForNavigation: [],
    isLoadingClaimJob: false,
    isLoadingActiveTrucks: false,
    isLoadingSlotQueues: false,
    selectedStatuses: [],
    franchisesInOperatingUnit: [],
    selectedFranchises: [],
    distanceFromMeFilter: {},
    preferredTimeSlotFiltersSelected: [],
    selectedTruckCapacity: {},
    userCurrentPosition: { lat: undefined, lng: undefined }
  }),
  getters: {
    getJobQueue() {
      return this.jobQueue.slice()
    },
    getDistanceFromMeFilter() {
      return this.distanceFromMeFilter
    },
    getDistanceFromMeFilters() {
      return [
        { id: 1, name: "< 10", lower: -1, upper: 10 },
        { id: 2, name: "10-25", lower: 10, upper: 25 },
        { id: 3, name: "25-50", lower: 25, upper: 50 }
      ]
    },
    getPreferredTimeSlotFiltersSelected() {
      return this.preferredTimeSlotFiltersSelected.slice()
    },
    getSelectedFranchiseFilters() {
      return this.selectedFranchises.slice()
    },
    getSelectedStatuses() {
      return this.selectedStatuses.slice()
    },
    getSelectedTruckCapacity() {
      return this.selectedTruckCapacity
    },
    getFilteredJobQueue() {
      return this.getJobQueue
        .slice()
        ?.filter(job => filterByTotalDistance(job, this.getDistanceFromMeFilter))
        ?.filter(job => filterByPreferredTimeSlot(job, this.getPreferredTimeSlotFiltersSelected))
        ?.filter(job => filterBySelectedFranchise(job, this.getSelectedFranchiseFilters))
        ?.filter(job => filterBySelectedStatuses(job, this.getSelectedStatuses))
        ?.filter(job => filterBySelectedTruckCapacity(job, this.getSelectedTruckCapacity))
    },
    getUserCurrentPosition() {
      return this.userCurrentPosition
    },
    getFranchisesInOperatingUnit() {
      return this.franchisesInOperatingUnit.slice()
    },
    getJobs() {
      return this.jobQueue.slice()
    },
    getScheduledDate() {
      return this.scheduledDate
    },
    getSelectedTruckId() {
      return this.selectedJunkTruckId
    },
    getActiveTrucks() {
      return this.activeTrucks.slice()
    },
    getActiveEmployees() {
      let employees = useMainStore().getActiveNonAdEmployees
      return this.activeEmployees.concat(employees).slice()
    },
    getSelectedTruckJobQueue() {
      return this.jobQueue
        .slice()
        ?.filter(job => job.jobTrucks.some(truck => truck.junkTruckId === this.selectedJunkTruckId))
        .sort((a, b) => (a.preferredTimeSlotId < b.preferredTimeSlotId ? -1 : 1))
    },
    getIsLoadingActiveTrucks() {
      return this.isLoadingActiveTrucks
    },
    getIsLoadingClaimJob() {
      return this.isLoadingClaimJob
    },
    getIsLoadingSlotQueues() {
      return this.isLoadingSlotQueues
    },
    getAddressesForNavigation() {
      return this.jobAddressesForNavigation.slice()
    }
  },
  actions: {
    async setUserCurrentPosition(position) {
      this.userCurrentPosition = Object.assign({}, position)
    },
    async setDistanceFromMeFilter(filter) {
      if (!filter) {
        filter = Object.assign({}, {})
      }
      this.distanceFromMeFilter = Object.assign({}, filter)

      await set("jobQueueDistanceFromMeFilter", filter).catch(error => {
        return Promise.reject(error)
      })
    },
    async setPreferredTimeSlotFilter(preferredTimeSlot) {
      if (!preferredTimeSlot) {
        preferredTimeSlot = []
      }
      this.preferredTimeSlotFiltersSelected = preferredTimeSlot

      await set("jobQueuePreferredTimeSlotsFilter", preferredTimeSlot).catch(error => {
        return Promise.reject(error)
      })
    },
    async setSelectedFranchisesFilter(franchises) {
      if (!franchises) {
        franchises = []
      }
      this.selectedFranchises = franchises

      await set("jobQueueFranchisesFilter", franchises).catch(error => {
        return Promise.reject(error)
      })
    },
    async setSelectedStatuses(statuses) {
      if (!statuses) {
        statuses = []
      }
      this.selectedStatuses = statuses

      await set("jobQueueStatusFilters", statuses).catch(error => {
        return Promise.reject(error)
      })
    },
    async setSelectedTruckCapacity(capacity) {
      if (!capacity) {
        capacity = Object.assign({}, {})
      }
      this.selectedTruckCapacity = capacity

      await set("jobQueueTruckCapacityFilters", capacity).catch(error => {
        return Promise.reject(error)
      })
    },
    async addJobToQueue(dto) {
      let idx = this.jobQueue.findIndex(j => j.jobId === dto.jobId)
      if (~idx) {
        let job = this.jobQueue.find(j => j.jobId === dto.jobId)
        dto.totalDistance = job.totalDistance
        dto.totalDistanceKm = job.totalDistanceKm
        this.jobQueue.splice(idx, 1, dto)
      } else {
        this.jobQueue.push(dto)
      }
    },
    removeJobFromQueue(jobId) {
      let idx = this.jobQueue.findIndex(j => j.jobId === jobId)
      if (~idx) {
        this.jobQueue.splice(idx, 1)
      }
    },
    async fetchClaimedJobDistancesFromUser(userLocation) {
      this.getJobs.forEach(j => {
        let primaryLocation = j.jobAddresses.find(ja => !ja.isFranchiseLocation)

        if (primaryLocation && (j.jobStatusId === JobStatus.SCHEDULED.id || j.jobStatusId === JobStatus.CLAIMED.id)) {
          let waypoints = [
            {
              latitude: userLocation.lat,
              longitude: userLocation.lng
            },
            {
              latitude: primaryLocation.latitude,
              longitude: primaryLocation.longitude
            }
          ]

          routingApi.calculateRoute(waypoints).then(data => {
            let distance = {
              totalDistance: data.totalDistance,
              totalDistanceKm: data.totalDistanceKm
            }

            let decoratedJob = this.jobQueue.find(job => job.jobId === j.jobId)
            decoratedJob = {
              ...decoratedJob,
              totalDistance: distance.totalDistance,
              totalDistanceKm: distance.totalDistanceKm
            }
            let idx = this.jobQueue.findIndex(jx => jx.jobId === j.jobId)
            this.jobQueue.splice(idx, 1, decoratedJob)
          })
        } else {
          let decoratedJob = this.jobQueue.find(job => job.jobId === j.jobId)
          decoratedJob = {
            ...decoratedJob,
            totalDistance: -1,
            totalDistanceKm: -1
          }
          let idx = this.jobQueue.findIndex(jx => jx.jobId === j.jobId)
          this.jobQueue.splice(idx, 1, decoratedJob)
        }
      })
    },
    async setAddressesForNavigation(addresses) {
      this.jobAddressesForNavigation = addresses
    },
    async setSelectedJunkTruckId(truckId) {
      this.selectedJunkTruckId = truckId
    },
    async resetAttributes() {
      this.selectedJunkTruckId = undefined
      this.jobQueue = []
      this.franchisesInOperatingUnit = []
      this.selectedFranchises = []
    },
    async setScheduledDate(date) {
      this.scheduledDate = date
    },
    async fetchAllQueuedJobsByScheduledDateAndOperatingUnitId(dto) {
      this.isLoadingSlotQueues = true
      return await junkApi
        .fetchAllQueuedJobsByScheduledDateAndOperatingUnitId(dto.date, dto.operatingUnitId)
        .then(data => {
          this.jobQueue.splice(0, this.jobQueue?.length ?? 0, ...data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Fetching Jobs! ${errorMessage}`, "Retry", () => {
            this.fetchAllQueuedJobsByScheduledDateAndOperatingUnitId(dto)
          })
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingSlotQueues = false
        })
    },
    async fetchActiveJunkTrucksInOperatingUnitByDate(dto) {
      this.isLoadingActiveTrucks = true
      return await junkApi
        .fetchActiveJunkTrucksInOperatingUnitByDate(dto.date, dto.operatingUnitId)
        .then(data => {
          this.activeTrucks.splice(0, this.activeTrucks?.length ?? 0, ...data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Fetching Trucks! ${errorMessage}`, "Retry", () => {
            this.fetchActiveJunkTrucksInOperatingUnitByDate(dto)
          })
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingActiveTrucks = false
        })
    },
    async claimJunkJobByJobId(dto) {
      this.isLoadingClaimJob = true
      return await junkApi
        .claimJunkJob(dto.jobId, dto.claimJobDto)
        .then(data => {
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          const snackbar = errorSnackbar(`Error Claiming Job! ${errorMessage}`)
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.isLoadingClaimJob = false
        })
    },
    async fetchFranchisesInOperatingUnit(operatingUnitId) {
      return await junkApi
        .fetchActiveFranchisesInOperatingUnit(operatingUnitId)
        .then(data => {
          this.franchisesInOperatingUnit.splice(0, this.franchisesInOperatingUnit?.length ?? 0, ...data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const errorMessage = errorMessageHandler(error)
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Fetching Franchises in Operating Unit! ${errorMessage}`, "Retry", () => {
            this.fetchFranchisesInOperatingUnit(operatingUnitId)
          })
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
    },
    async setUsersInOperatingUnit(users) {
      this.activeEmployees.splice(0, this.activeEmployees?.length ?? 0, ...users)
    }
  }
})
