import { defineStore } from "pinia"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"

export const useDataFixStore = defineStore("data-fix", {
  state: () => ({
    isLoadingJobDataFixDetails: false,
    customerDetails: {},
    cancellationReasons: [],
    activeFranchiseTaxes: [],
    jobDataFixPreview: {},
    jobDataFixDetails: {}
  }),
  getters: {
    getIsLoadingJobDataFixDetails() {
      return this.isLoadingJobDataFixDetails
    },
    getCustomerDetails() {
      return this.customerDetails
    },
    getCancellationReasons() {
      return this.cancellationReasons
    },
    getActiveFranchiseTaxes() {
      return this.activeFranchiseTaxes.slice().sort((a, b) => (a.taxName < b.taxName ? -1 : 1))
    }
  },
  actions: {
    setIsLoadingJobDetailsDataFix(isLoading) {
      this.isLoadingJobDataFixDetails = isLoading
    },
    async resetJobDataFixCustomerDetails() {
      this.customerDetails = this.setJobDataFixCustomerDetails({})
    },
    async setJobDataFixCustomerDetails(customerDetails) {
      this.customerDetails = Object.assign({}, customerDetails)
    },
    async fetchJobDataFixCustomerDetails(jobNumber) {
      await this.resetJobDataFixCustomerDetails()
      return await junkApi
        .fetchJobDataFixCustomerDetailsByJobNumber(jobNumber)
        .then(jobDataFixDetails => {
          this.setJobDataFixCustomerDetails(jobDataFixDetails)
          return Promise.resolve(jobDataFixDetails)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.fetchJobDataFixCustomerDetails(jobNumber))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
    },
    setJobDataFixDetails(jobDataFixDetails) {
      this.jobDataFixDetails = Object.assign({}, jobDataFixDetails)
    },
    async fetchJobDataFixDetails(jobId) {
      this.setIsLoadingJobDetailsDataFix(true)
      return await junkApi
        .fetchJobDataFixDetailsByJobId(jobId)
        .then(jobDataFixDetails => {
          this.setJobDataFixDetails(jobDataFixDetails)
          return Promise.resolve(jobDataFixDetails)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Fetching Job Details! ${errorMessageHandler(error)}`, "Retry", () => this.fetchJobDataFixDetails(jobId))
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDetailsDataFix(false)
        })
    },
    setCancellationReasons(cancellationReasons) {
      this.cancellationReasons.splice(0, this.cancellationReasons?.length ?? 0, ...cancellationReasons)
    },
    async fetchCancellationReasons() {
      this.setIsLoadingJobDetailsDataFix(true)
      return await junkApi
        .fetchCancellationReasons()
        .then(data => {
          this.setCancellationReasons(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Fetching Cancellation Reasons! ${errorMessageHandler(error)}`, "Retry", () =>
            this.fetchCancellationReasons()
          )
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDetailsDataFix(false)
        })
    },
    async clearActiveFranchiseTaxes() {
      this.activeFranchiseTaxes.splice(0, this.activeFranchiseTaxes?.length ?? 0, ...[])
    },
    setActiveFranchiseTaxes(activeFranchiseTaxes) {
      this.activeFranchiseTaxes.splice(0, this.activeFranchiseTaxes?.length ?? 0, ...activeFranchiseTaxes)
    },
    async fetchActiveFranchiseTaxes({ franchiseId, date }) {
      this.setIsLoadingJobDetailsDataFix(true)
      return await junkApi
        .fetchActiveFranchiseTaxesByFranchiseIdAndDate(franchiseId, date)
        .then(activeFranchiseTaxes => {
          this.setActiveFranchiseTaxes(activeFranchiseTaxes)
          return Promise.resolve(activeFranchiseTaxes)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () =>
            this.fetchActiveFranchiseTaxes({
              franchiseId,
              date
            })
          )
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => this.setIsLoadingJobDetailsDataFix(false))
    },
    resetJobDataFixPreview() {
      this.setJobDataFixPreview({})
    },
    setJobDataFixPreview(jobDataFixPreview) {
      this.jobDataFixPreview = Object.assign({}, jobDataFixPreview)
    },
    async postJobDataFixForPreview(jobDataFix) {
      this.setIsLoadingJobDetailsDataFix(true)
      this.resetJobDataFixPreview()
      return await junkApi
        .postJobDataFixPreview(jobDataFix)
        .then(data => {
          this.setJobDataFixPreview(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Posting Job Data Fix! ${errorMessageHandler(error)}`, "Retry", () =>
            this.postJobDataFixForPreview(jobDataFix)
          )
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDetailsDataFix(false)
        })
    },
    async postJobDataFixForApply({ jobId, employeeId }) {
      this.setIsLoadingJobDetailsDataFix(true)
      return await junkApi
        .postJobDataFixApply(jobId, employeeId)
        .then(() => {
          this.setJobDataFixDetails({})
          this.setJobDataFixPreview({})
          this.setCancellationReasons([])
          return Promise.resolve()
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`Error Applying Job Data Fix! ${errorMessageHandler(error)}`, "Retry", () =>
            this.postJobDataFixForApply({ jobId, employeeId })
          )
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobDetailsDataFix(false)
        })
    }
  }
})
