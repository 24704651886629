<template>
  <v-container fluid>
    <v-card class="flex-fill flex-column flex" flat>
      <v-card-title>Edit Franchise Details</v-card-title>
      <v-card-text>
        <v-form ref="administrator-edit-franchise-form" :disabled="isFranchiseClosed" v-model="isEditFranchiseDetailsFormValid">
          <v-row dense>
            <v-col>
              <v-text-field ref="administrator-edit-franchise-number-text" label="Franchise Number *" v-model="franchise.franchiseNumber" outlined disabled readonly />
            </v-col>
            <v-col>
              <v-text-field ref="administrator-edit-franchise-name-text" label="Franchise Name *" v-model="mutableFranchiseName" :rules="isRequiredRules" outlined />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-phone-input
                class="d-flex"
                ref="administrator-edit-franchise-primary-phone-text"
                v-model="mutablePhonePrimary"
                label="Primary Phone Number *"
                :rules="isRequiredRules"
                @input="handleChangeInputPrimary"
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="ma-0">
              <v-checkbox ref="administrator-enroll-franchise-is-dialpad-enabled-checkbox" label="Enable Texting" v-model="isDialpadEnabled" @change="handleChangeEnableDialpad" />
            </v-col>
            <v-col class="ma-0" v-if="isDialpadEnabled">
              <v-checkbox
                ref="administrator-enroll-franchise-is-dialpad-primary-phone-checkbox"
                label="Use Primary Phone"
                v-model="isDialpadPrimaryPhone"
                @change="handleChangeIsDialpadPrimary"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="isDialpadEnabled">
            <v-col class="ma-0">
              <v-phone-input
                ref="admin-enroll-franchise-dialog-phone-primary-text"
                label="Dialpad Phone Number"
                class="d-flex"
                v-model="mutablePhoneDialpad"
                :validate-on-blur="!isDialpadPrimaryPhone"
                :disabled="isDialpadPrimaryPhone"
                outlined
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-edit-franchise-email-text"
                label="Email Address *"
                counter="50"
                v-model="mutableEmailPrimary"
                :rules="emailRules"
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-edit-franchise-google-review-url-text"
                label="Google Review URL"
                counter="256"
                v-model="mutableGoogleReviewUrl"
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-edit-franchise-address-text"
                class="cursor_pointer"
                label="Address *"
                :value="formatAddress(mutableAddress)"
                @click="setIsAddressSearchDialogVisible(true)"
                readonly
                outlined
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mb-4 d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-franchise-system-access-date-picker"
                text-field-label="System Access Date *"
                text-field-format="MMMM D, YYYY"
                :date-picker-value="mutableSystemAccessDate"
                :minimum-date-value="minimumDate"
                :maximum-date-value="mutableFirstDayOfBusiness"
                :text-field-disabled="isSystemAccessDateDisabled"
                :is-clear-enabled="!isSystemAccessDateDisabled"
                @updatedPickerValue="updateSystemAccessDate($event)"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mb-4 d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-franchise-start-date-picker"
                text-field-label="First Day of Business *"
                text-field-format="MMMM D, YYYY"
                :date-picker-value="mutableFirstDayOfBusiness"
                :minimum-date-value="mutableSystemAccessDate"
                :maximum-date-value="mutableLastDayOfBusiness"
                :text-field-disabled="isBusinessStartDateDisabled"
                :is-clear-enabled="!isBusinessStartDateDisabled"
                @updatedPickerValue="updateFirstDateOfBusiness($event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="d-flex">
              <app-date-picker-field-selector
                ref="administrator-edit-franchise-end-date-picker"
                text-field-label="Last Day of Business"
                text-field-format="MMMM D, YYYY"
                :date-picker-value="mutableLastDayOfBusiness"
                :minimum-date-value="mutableFirstDayOfBusiness"
                :text-field-disabled="isFranchiseClosed"
                :is-clear-enabled="!isFranchiseClosed"
                @updatedPickerValue="updateLastDateOfBusiness($event)"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-checkbox ref="administrator-edit-franchise-is-mini-market" label="Mini Market" v-model="mutableIsMiniMarket" :disabled="isFranchiseClosed" />
            </v-col>
          </v-row>
          <v-row ref="administrator-edit-franchise-is-closed" v-if="!isFranchiseClosed" dense>
            <v-col>
              <v-btn
                ref="administrator-edit-franchise-update-button"
                class="float-end"
                color="primary"
                :disabled="getIsLoadingOperatingUnitFranchisesAndSatellites"
                :loading="getIsLoadingOperatingUnitFranchisesAndSatellites"
                @click="save"
                rounded
                ripple
                large
              >
                Update Franchise Details
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <app-address-search-dialog
      v-if="isAddressSearchDialogVisible"
      :is-dialog-visible="isAddressSearchDialogVisible"
      :initial-camera-position="{ lat: 42.435712, lng: -84.331992 }"
      :is-create-address-from-marker-button-visible="false"
      @createAddressFromSearch="setFranchiseAddress($event)"
      @createAddressFromForm="setFranchiseAddress($event)"
      @closeDialog="setIsAddressSearchDialogVisible(false)"
    ></app-address-search-dialog>
  </v-container>
</template>

<script>
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import AppAddressSearchDialog from "@/components/AppAddressSearchDialog"
import { useAdministratorStore } from "@/stores/Administrator"
import { useSnackbarStore } from "@/stores/Snackbar"
import { mapActions, mapState } from "pinia"
import { putFranchiseDetailsDto, putFranchiseDetailsWithUpdatedAddressDto } from "@/api/dtos/JunkDtos"
import { formatAsDateOnly, todayAsDate } from "@/utils/DateTimeFormatters"
import { formatHereAddressText } from "@/utils/FormatAddressText"
import { successSnackbar } from "@/utils/SnackbarBuilder"

export default {
  name: "AdministratorEditFranchise",
  components: { AppDatePickerFieldSelector, AppAddressSearchDialog },
  props: {
    franchiseInitial: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      franchise: {},
      isAddressSearchDialogVisible: false,
      isEditFranchiseDetailsFormValid: false,
      minimumDate: todayAsDate(),
      mutableAddress: undefined,
      mutableFranchiseName: "",
      mutablePhonePrimary: "",
      mutablePhoneDialpad: "",
      isDialpadEnabled: true,
      isDialpadPrimaryPhone: false,
      mutableEmailPrimary: "",
      mutableGoogleReviewUrl: "",
      mutableIsMiniMarket: false,
      mutableIsRoyaltyExempt: false,
      mutableSystemAccessDate: null,
      mutableFirstDayOfBusiness: null,
      mutableLastDayOfBusiness: null,
      emailRules: [v => /.+@.+\..+/.test(v.trim()) || "E-mail must be valid"],
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."]
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["addSnackbar"]),
    ...mapActions(useAdministratorStore, ["updateFranchiseDetails"]),
    async save() {
      if (this.$refs["administrator-edit-franchise-form"].validate()) {
        const dto = this.generatePutFranchiseDetailsDto()
        await this.updateFranchiseDetails(dto).then(operatingUnitLeaf => {
          this.addSnackbar(successSnackbar("Franchise Successfully Updated"))
          let updatedFranchise = operatingUnitLeaf.children.find(franchiseLeaf => franchiseLeaf.franchiseId === this.franchise.franchiseId)
          this.setMutableStates(updatedFranchise)
        })
      }
    },
    generatePutFranchiseDetailsDto() {
      return this.mutableAddress.latitude === this.franchise.addressDto.latitude && this.mutableAddress.longitude === this.franchise.addressDto.longitude
        ? putFranchiseDetailsDto(this.franchise.franchiseId, this.mutableFranchiseName, this.mutablePhonePrimary, this.mutablePhoneDialpad, this.mutableEmailPrimary, this.mutableIsMiniMarket, this.mutableIsRoyaltyExempt, this.mutableSystemAccessDate, this.mutableFirstDayOfBusiness, this.mutableLastDayOfBusiness, this.$msal.getCurrentUserId(), this.mutableGoogleReviewUrl) :
          putFranchiseDetailsWithUpdatedAddressDto(this.franchise.franchiseId, this.mutableFranchiseName, this.mutablePhonePrimary, this.mutablePhoneDialpad, this.mutableEmailPrimary, this.mutableIsMiniMarket, this.mutableIsRoyaltyExempt, this.mutableSystemAccessDate, this.mutableFirstDayOfBusiness, this.mutableLastDayOfBusiness, this.$msal.getCurrentUserId(), this.mutableGoogleReviewUrl, this.mutableAddress)
    },
    updateSystemAccessDate(date) {
      this.mutableSystemAccessDate = date
    },
    updateFirstDateOfBusiness(date) {
      this.mutableFirstDayOfBusiness = date
    },
    updateLastDateOfBusiness(date) {
      this.mutableLastDayOfBusiness = date
    },
    formatAddress(address) {
      return formatHereAddressText(address)
    },
    setIsAddressSearchDialogVisible(isVisible) {
      this.isAddressSearchDialogVisible = isVisible
    },
    setFranchiseAddress(event) {
      this.mutableAddress = event
      this.isAddressSearchDialogVisible = false
    },
    toMutableAddress(franchiseAddress) {
      return {
        addressLine1: franchiseAddress.addressLine1,
        addressLine2: franchiseAddress.addressLine2,
        city: franchiseAddress.city,
        stateCode: franchiseAddress.stateOrProvince,
        postalCode: franchiseAddress.zipCode,
        countryCode: franchiseAddress.country,
        isUserValidated: franchiseAddress.isUserValidated,
        latitude: franchiseAddress.latitude,
        longitude: franchiseAddress.longitude
      }
    },
    setMutableStates(franchise) {
      this.franchise = franchise
      this.mutableFranchiseName = franchise.franchiseName
      this.mutablePhonePrimary = franchise.phone
      this.mutablePhoneDialpad = franchise.dialpadPhone
      this.mutableIsMiniMarket = franchise.isMiniMarket
      this.mutableIsRoyaltyExempt = franchise.isRoyaltyExempt
      this.mutableEmailPrimary = franchise.email
      this.mutableGoogleReviewUrl = franchise.googleReviewUrl
      this.mutableAddress = this.toMutableAddress(franchise.addressDto)
      this.mutableSystemAccessDate = formatAsDateOnly(franchise.systemAccessDate)
      this.mutableFirstDayOfBusiness = formatAsDateOnly(franchise.businessStartDate)
      this.mutableLastDayOfBusiness = formatAsDateOnly(franchise.businessEndDate)
      this.isDialpadEnabled = !this.isDialpadEmpty
    },
    handleChangeEnableDialpad() {
      if (!this.isDialpadEnabled) {
        this.isDialpadPrimaryPhone = false
        this.mutablePhoneDialpad = ""
      }
    },
    handleChangeIsDialpadPrimary() {
      this.mutablePhoneDialpad = this.isDialpadPrimaryPhone ? this.mutablePhonePrimary : ""
    },
    handleChangeInputPrimary() {
      if (this.isDialpadPrimaryPhone) {
        this.mutablePhoneDialpad = this.mutablePhonePrimary
      }
    }
  },
  computed: {
    ...mapState(useAdministratorStore, ["getIsLoadingOperatingUnitFranchisesAndSatellites"]),
    isSystemAccessDateDisabled() {
      return this.mutableSystemAccessDate <= todayAsDate() || this.isFranchiseClosed
    },
    isBusinessStartDateDisabled() {
      return this.mutableFirstDayOfBusiness <= todayAsDate() || this.isFranchiseClosed
    },
    isFranchiseClosed() {
      return this.franchise.businessEndDate !== undefined && this.franchise.businessEndDate !== null
    },
    isDialpadEmpty() {
      return this.mutablePhoneDialpad === ""
    }
  },
  created() {
    this.setMutableStates(this.franchiseInitial)
  }
}
</script>
