<template>
  <v-container fluid>
    <base-dialog title="Enroll Franchise" ref="administrator-enroll-franchise-dialog-root" dialog-image="mdi-domain-plus" :is-dialog-visible="isDialogVisible">
      <template v-slot:content>
        <v-form ref="administrator-enroll-franchise-dialog-form">
          <v-row dense>
            <v-col>
              <v-autocomplete
                ref="administrator-enroll-franchise-dialog-operating-unit-selector"
                label="Selected Operating Unit *"
                item-value="operatingUnitId"
                item-text="displayName"
                v-model="selectedOperatingUnitId"
                :rules="operatingUnitSelectionValidationRules"
                :items="getOperatingUnitFranchisesAndSatellites"
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-enroll-franchise-dialog-franchise-number-txt"
                label="Franchise Number *"
                counter="4"
                type="number"
                v-model="franchiseNumber"
                :rules="isRequiredRules"
                outlined
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-enroll-franchise-dialog-franchise-name-txt"
                label="Franchise Name *"
                counter="50"
                v-model="franchiseName"
                :rules="isRequiredRules"
                outlined
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="d-flex">
              <app-date-picker-field-selector
                ref="administrator-enroll-franchise-dialog-system-access-date-picker"
                :minimum-date-value="minimumDate"
                :maximum-date-value="firstDateOfBusiness"
                :date-picker-value="systemAccessDate"
                :text-field-disabled="false"
                @updatedPickerValue="setSystemAccessDate($event)"
                text-field-label="System Access Date *"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="d-flex mt-4">
              <app-date-picker-field-selector
                ref="administrator-enroll-franchise-dialog-enrollment-date-picker"
                :minimum-date-value="systemAccessDate"
                :date-picker-value="firstDateOfBusiness"
                :text-field-disabled="false"
                @updatedPickerValue="setFirstDateOfBusiness($event)"
                text-field-label="First Date of Business *"
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-enroll-franchise-dialog-address-txt"
                class="cursor_pointer mt-4"
                label="Address *"
                :value="formatAddress(franchiseAddress)"
                :rules="isRequiredRules"
                @click="setIsAddressSearchDialogVisible(true)"
                outlined
                readonly
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="ma-0">
              <v-phone-input
                class="d-flex"
                ref="admin-enroll-franchise-dialog-phone-primary-text"
                label="Primary Phone Number *"
                v-model="phonePrimary"
                :rules="isRequiredRules"
                @input="handleChangeInputPrimary"
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="ma-0">
              <v-checkbox ref="administrator-enroll-franchise-is-dialpad-enabled-checkbox" label="Enable Texting" v-model="isDialpadEnabled" @change="handleChangeEnableDialpad" />
            </v-col>
            <v-col class="ma-0" v-if="isDialpadEnabled">
              <v-checkbox
                ref="administrator-enroll-franchise-is-dialpad-primary-phone-checkbox"
                label="Use Primary Phone"
                v-model="isDialpadPrimaryPhone"
                @change="handleChangeDialpadIsPrimary"
              />
            </v-col>
          </v-row>

          <v-row dense v-if="isDialpadEnabled">
            <v-col class="ma-0">
              <v-phone-input
                ref="admin-enroll-franchise-dialog-dialpad-phone-text"
                label="Dialpad Phone Number"
                class="d-flex"
                v-model="phoneDialpad"
                :rules="isDialpadRequiredRules"
                :validate-on-blur="!isDialpadPrimaryPhone"
                :disabled="isDialpadPrimaryPhone"
                outlined
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-enroll-franchise-email-text"
                label="Email Address *"
                counter="50"
                v-model.trim="emailPrimary"
                :rules="emailRules"
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <v-text-field
                ref="administrator-enroll-franchise-google-review-url-text"
                label="Google Review URL"
                v-model.trim="googleReviewUrl"
                counter="256"
                outlined
                validate-on-blur
              />
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="mt-n4">
              <v-checkbox ref="administrator-enroll-franchise-is-mini-market-checkbox" label="Mini-Market?" v-model="isMiniMarket" />
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" ref="administrator-enroll-franchise-dialog-cancel-btn" color="secondary" :disabled="false" @click="closeDialog" text rounded ripple>Cancel</v-btn>
        <v-btn
          class="pa-4"
          ref="administrator-enroll-franchise-dialog-confirm-btn"
          color="primary"
          :loading="getIsLoadingOperatingUnitFranchisesAndSatellites"
          :disabled="isSaveDisabled"
          @click="save"
          text
          rounded
          ripple
        >
          Confirm
        </v-btn>
      </template>
    </base-dialog>
    <app-address-search-dialog
      ref="administrator-enroll-franchise-dialog-address-search-dialog"
      v-if="isAddressSearchDialogVisible"
      :is-dialog-visible="isAddressSearchDialogVisible"
      :initial-camera-position="{ lat: 42.435712, lng: -84.331992 }"
      :is-create-address-from-marker-button-visible="false"
      @createAddressFromSearch="setFranchiseAddress($event)"
      @createAddressFromForm="setFranchiseAddress($event)"
      @closeDialog="setIsAddressSearchDialogVisible(false)"
    />
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import AppDatePickerFieldSelector from "@/components/AppDatePickerFieldSelector"
import AppAddressSearchDialog from "@/components/AppAddressSearchDialog"
import { useAdministratorStore } from "@/stores/Administrator"
import { mapActions, mapState } from "pinia"
import { todayAsDate, tomorrowAsDate } from "@/utils/DateTimeFormatters"
import { enrollFranchiseDto } from "@/api/dtos/JunkDtos"
import { formatHereAddressText } from "@/utils/FormatAddressText"

export default {
  name: "AdministratorEnrollFranchiseDialog",
  components: { BaseDialog, AppDatePickerFieldSelector, AppAddressSearchDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      minimumDate: todayAsDate(),
      systemAccessDate: todayAsDate(),
      firstDateOfBusiness: tomorrowAsDate(),
      franchiseName: "",
      franchiseNumber: "",
      franchiseAddress: "",
      phonePrimary: "",
      phoneDialpad: "",
      isDialpadEnabled: true,
      isDialpadPrimaryPhone: false,
      emailPrimary: "",
      googleReviewUrl: "",
      selectedOperatingUnitId: undefined,
      isMiniMarket: false,
      isAddressSearchDialogVisible: false,
      operatingUnitSelectionValidationRules: [value => !!value || "A Operating Unit Selection is Required."],
      emailRules: [v => /.+@.+\..+/.test(v.trim()) || "E-mail must be valid"],
      isRequiredRules: [value => (!!value && !!value.trim()) || "Is required."],
      isDialpadRequiredRules: [value => (!!value && !!value.trim() && this.isDialpadEnabled) || "Is required."]
    }
  },
  methods: {
    ...mapActions(useAdministratorStore, ["createFranchise"]),
    async save() {
      if (this.$refs["administrator-enroll-franchise-dialog-form"].validate()) {
        let dto = enrollFranchiseDto(this.selectedOperatingUnitId, this.phonePrimary, this.phoneDialpad, this.emailPrimary, this.franchiseName, this.franchiseNumber, this.isMiniMarket, false, this.systemAccessDate, this.firstDateOfBusiness, this.googleReviewUrl, this.$msal.getCurrentUserId(), this.franchiseAddress.addressLine1, this.franchiseAddress.addressLine2, this.franchiseAddress.city, this.franchiseAddress.stateCode, this.franchiseAddress.postalCode, this.franchiseAddress.countryCode, false, this.franchiseAddress.latitude, this.franchiseAddress.longitude)
        await this.createFranchise(dto).then(() => this.closeDialog())
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    },
    formatAddress(address) {
      return formatHereAddressText(address)
    },
    setFranchiseAddress(franchiseAddress) {
      this.franchiseAddress = franchiseAddress
      this.setIsAddressSearchDialogVisible(false)
    },
    setSystemAccessDate(date) {
      this.systemAccessDate = date
    },
    setFirstDateOfBusiness(date) {
      this.firstDateOfBusiness = date
    },
    setIsAddressSearchDialogVisible(isVisible) {
      this.isAddressSearchDialogVisible = isVisible
    },
    handleChangeEnableDialpad() {
      if (!this.isDialpadEnabled) {
        this.isDialpadPrimaryPhone = false
        this.phoneDialpad = ""
      }
    },
    handleChangeDialpadIsPrimary() {
      this.phoneDialpad = this.isDialpadPrimaryPhone ? this.phonePrimary : ""
    },
    handleChangeInputPrimary() {
      if (this.isDialpadPrimaryPhone) {
        this.phoneDialpad = this.phonePrimary
      }
    }
  },
  computed: {
    ...mapState(useAdministratorStore, ["getIsLoadingOperatingUnitFranchisesAndSatellites", "getOperatingUnitFranchisesAndSatellites"]),
    isSaveDisabled() {
      return this.selectedOperatingUnitId === undefined || this.getIsLoadingOperatingUnitFranchisesAndSatellites
    }
  }
}
</script>
