<template>
  <base-dialog ref="administrator-discounts-confirmation-dialog-root" title="Confirm" :is-dialog-visible="isDialogVisible" dialog-image="mdi-alert-outline">
    <template class="flex-fill" v-slot:content>
      <v-card-title class="pa-4 word-wrap">
        Saving this discount will modify any associated elected franchise discounts.
      </v-card-title>
    </template>
    <template v-slot:actions>
      <v-btn ref="administrator-discounts-confirmation-dialog-cancel-button" class="justify-end pa-4" color="primary" text rounded ripple @click="closeDialog">Cancel</v-btn>
      <v-btn ref="administrator-discounts-confirmation-dialog-save-button" class="justify-end pa-4" color="primary" text rounded ripple @click="handleSaveEdit">Save</v-btn>
    </template>
  </base-dialog>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
export default {
  name: "AdministratorDiscountsConfirmationDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog")
    },
    handleSaveEdit() {
      this.$emit("saveEdit")
    }
  }
}
</script>
