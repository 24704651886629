<template>
  <v-card class="elevation-4" ref="settings-franchise-business-hours-card-root">
    <v-card-title>
      <v-row>
        <v-col>
          <span class="headline word-wrap" ref="settings-franchise-business-hours-heading">
            Business Hours
          </span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="settings-franchise-business-hours-form">
        <v-data-table
          class="data_table_body"
          ref="settings-franchise-business-hours-table"
          :headers="businessHoursHeaders"
          disable-sort
          :items="getFranchiseBusinessHours"
          :loading="getIsLoadingFranchiseBusinessHours"
          item-key="franchiseBusinessHourId"
          loading-text="🔎 Fetching Business Hours 🔍"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-col class="ma-0 pa-0 align-self-center d-flex">
              <app-not-found title="Ope! No available records :(" content="Looks like you don't have any records, please select a franchise.">
                <template v-slot:actions>
                  <v-btn
                    ref="settings-franchise-business-hours-table-franchise-selector-btn"
                    class="mt-4 primary justify-center align-self-center"
                    @click="$emit('selectFranchiseSelector')"
                    ripple
                    rounded
                    large
                    >Select Franchise</v-btn
                  >
                </template>
              </app-not-found>
            </v-col>
          </template>
          <template v-slot:item.officeOpenTime="{item}">
            <div v-if="!isItemBeingEdited(item.franchiseBusinessHourId)">{{ item.isOfficeClosed ? "Closed" : formatTime(item.officeOpenTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-business-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.officeOpenTime"
              :is-disabled="!isItemBeingEdited(item.franchiseBusinessHourId) || editedItem.isOfficeClosed"
              @updateTimeValue="setEditableItemFranchiseOpenTime($event)"
              :validation-rules="franchiseTimesValid"
              :allowed-minutes="allowedMinutes"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.officeCloseTime="{item}">
            <div v-if="!isItemBeingEdited(item.franchiseBusinessHourId)">{{ item.isOfficeClosed ? "Closed" : formatTime(item.officeCloseTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-business-hours-card-business-start-time-picker"
              text-field-label=""
              :min-value="editedItem.officeOpenTime"
              :time-value="editedItem.officeCloseTime"
              :is-disabled="!isItemBeingEdited(item.franchiseBusinessHourId) || editedItem.isOfficeClosed"
              @updateTimeValue="setEditableItemBusinessCloseTime($event)"
              :validation-rules="franchiseTimesValid"
              :allowed-minutes="allowedMinutes"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.customerBookingAmCutoffTime="{item}">
            <div v-if="!isItemBeingEdited(item.franchiseBusinessHourId)">{{ item.isHaulingClosed ? "Closed" : formatTime(item.customerBookingAmCutoffTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-business-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.customerBookingAmCutoffTime"
              :is-disabled="!isItemBeingEdited(item.franchiseBusinessHourId) || editedItem.isHaulingClosed"
              @updateTimeValue="setEditableItemCustomerBookingAmCutoff($event)"
              :allowed-minutes="allowedMinutes"
              :max-value="'11:59'"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.customerBookingPmCutoffTime="{item}">
            <div v-if="!isItemBeingEdited(item.franchiseBusinessHourId)">{{ item.isHaulingClosed ? "Closed" : formatTime(item.customerBookingPmCutoffTime) }}</div>
            <app-time-picker-menu
              v-else
              class="mt-2 mb-n5 mx-0 pa-0"
              ref="settings-franchise-business-hours-card-business-start-time-picker"
              text-field-label=""
              :time-value="editedItem.customerBookingPmCutoffTime"
              :is-disabled="!isItemBeingEdited(item.franchiseBusinessHourId) || editedItem.isHaulingClosed"
              @updateTimeValue="setEditableItemCustomerBookingPmCutoff($event)"
              :allowed-minutes="allowedMinutes"
              dense
            ></app-time-picker-menu>
          </template>

          <template v-slot:item.isOfficeClosed="{ item }">
            <v-checkbox v-if="!isItemBeingEdited(item.franchiseBusinessHourId)" :disabled="!isItemBeingEdited(item.franchiseBusinessHourId)" :input-value="item.isOfficeClosed" />
            <v-checkbox v-else :disabled="!isItemBeingEdited(item.franchiseBusinessHourId)" :input-value="editedItem.isOfficeClosed" @change="setEditableItemIsOfficeClosed($event)"/>
          </template>

          <template v-slot:item.isHaulingClosed="{ item }">
            <v-checkbox v-if="!isItemBeingEdited(item.franchiseBusinessHourId)" :disabled="!isItemBeingEdited(item.franchiseBusinessHourId)" :input-value="item.isHaulingClosed" />
            <v-checkbox v-else :disabled="!isItemBeingEdited(item.franchiseBusinessHourId)" :input-value="editedItem.isHaulingClosed" @change="setEditableItemIsHaulingClosed($event)"/>
          </template>

          <template v-if="!isActionsDisabled" v-slot:item.actions="{ item }">
            <div class="justify-center" v-if="isItemBeingEdited(item.franchiseBusinessHourId)">
              <v-btn color="primary" ref="settings-franchise-business-hours-save-item-btn" data-cy="settings-franchise-business-hours-save-item-btn" @click.stop="saveEditableItem" icon>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn color="red" ref="settings-franchise-business-hours-cancel-item-btn" data-cy="settings-franchise-business-hours-cancel-item-btn" @click="resetEditableItem" icon>
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </div>
            <div v-else class="justify-center">
              <v-btn color="primary" ref="settings-franchise-business-hours-edit-item-btn" data-cy="settings-franchise-business-hours-edit-item-btn" @click="editItem(item)" icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import AppTimePickerMenu from "@/components/AppTimePickerMenu.vue"
import { putFranchiseBusinessHoursDto } from "@/api/dtos/JunkDtos"
import AppNotFound from "@/components/AppNotFound.vue"
import { consoleLog } from "@/utils/Logging"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"

export default {
  name: "SettingsFranchiseBusinessHoursCard",
  components: { AppNotFound, AppTimePickerMenu },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      editedItem: {},
      franchiseTimesValid: []
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["updateFranchiseBusinessHours"]),
    isItemBeingEdited(itemId) {
      return itemId === this.editedItem?.franchiseBusinessHourId
    },
    formatTime(time) {
      // Split the input string into hours, minutes, and seconds
      const [hours, minutes] = time.split(":").map(Number)

      // Determine whether it's AM or PM
      const period = hours < 12 ? "AM" : "PM"

      // Convert hours to 12-hour format
      const twelveHour = (hours % 12 === 0) ? 12 : hours % 12;

      // Format the result as "hh:mm AM/PM"
      const result = `${String(twelveHour)}:${String(minutes).padStart(2, "0")} ${period}`

      return result
    },
    allowedMinutes: m => m % 15 === 0,
    setEditableItemFranchiseOpenTime(time) {
      this.editedItem.officeOpenTime = `${time}:00`
    },
    setEditableItemBusinessCloseTime(time) {
      this.editedItem.officeCloseTime = `${time}:00`
    },
    setEditableItemCustomerBookingAmCutoff(time) {
      consoleLog("setEditableItemCustomerBookingAmCutoff.time", time)
      this.editedItem.customerBookingAmCutoffTime = `${time}:00`
    },
    setEditableItemCustomerBookingPmCutoff(time) {
      this.editedItem.customerBookingPmCutoffTime = `${time}:00`
    },
    editItem(item) {
      this.setEditedItem(item)
    },
    setEditedItem(item) {
      this.editedItem = Object.assign({}, item)
    },
    setEditableItemIsOfficeClosed(isClosed) {
      this.editedItem.isOfficeClosed = isClosed
    },
    setEditableItemIsHaulingClosed(isClosed) {
      this.editedItem.isHaulingClosed = isClosed
    },
    async resetFranchiseTimesValid() {
      this.franchiseTimesValid = []
    },
    async saveEditableItem() {
      await this.setFranchiseTimesValidRules()

      if (this.$refs["settings-franchise-business-hours-form"].validate()) {
        const dto = this.generatePutFranchiseBusinessHoursDto()
        console.log("DTG ", dto)
        await this.updateFranchiseBusinessHours(dto).then(() => this.resetEditableItem())
      }
    },
    async setFranchiseTimesValidRules() {
      await this.resetFranchiseTimesValid()
      if (!this.editedItem.isOfficeClosed) {
        const [hours1, minutes1, seconds1] = this.editedItem.officeOpenTime.split(":")
        const [hours2, minutes2, seconds2] = this.editedItem.officeCloseTime.split(":")
        const officeOpenTime = new Date(0, 0, 1, +hours1, +minutes1, +seconds1)
        consoleLog("setFranchiseTimesValidRules.officeOpenTime", officeOpenTime)
        const officeCloseTime = new Date(0, 0, 1, +hours2, +minutes2, +seconds2)
        consoleLog("setFranchiseTimesValidRules.officeCloseTime", officeCloseTime)
        this.franchiseTimesValid.push(officeOpenTime <= officeCloseTime || "Franchise must open before it closes.")
      }
    },
    generatePutFranchiseBusinessHoursDto() {
      return putFranchiseBusinessHoursDto(
        this.editedItem.franchiseBusinessHourId,
        this.editedItem.isOfficeClosed,
        this.editedItem.isHaulingClosed,
        this.editedItem.isOfficeClosed ? "00:00:00" : this.editedItem.officeOpenTime,
        this.editedItem.isOfficeClosed ? "00:00:00" : this.editedItem.officeCloseTime,
        this.editedItem.isHaulingClosed ? "00:00:00" : this.editedItem.customerBookingAmCutoffTime,
        this.editedItem.isHaulingClosed ? "00:00:00" : this.editedItem.customerBookingPmCutoffTime
      )
    },
    async resetEditableItem() {
      this.editedItem = {}
      await this.resetFranchiseTimesValid()
    }
  },
  computed: {
    ...mapState(useSettingsStore, ["getIsLoadingFranchiseBusinessHours", "getFranchiseBusinessHours"]),
    businessHoursHeaders() {
      let headers = [
        { text: "Day", align: "start", value: "day" },
        { text: "Office Closed", align: "start", value: "isOfficeClosed" },
        { text: "Office Opens", align: "start", value: "officeOpenTime" },
        { text: "Office Closed", align: "start", value: "officeCloseTime" },
        { text: "Hauling Closed", align: "start", value: "isHaulingClosed" },
        { text: "Self-Scheduling AM Cutoff", align: "start", value: "customerBookingAmCutoffTime" },
        { text: "Self-Scheduling PM Cutoff", align: "start", value: "customerBookingPmCutoffTime" },
        { text: "Actions", align: "start", value: "actions" }
      ]
      if (this.isActionsDisabled) {
        return headers.slice().filter(header => header.value !== "actions" && header.value !== "isOfficeClosed" && header.value !== "isHaulingClosed")
      } else if (Object.keys(this.editedItem).length === 0) {
        return headers.slice().filter(header => header.value !== "isOfficeClosed" && header.value !== "isHaulingClosed")
      }
      return headers
    }
  }
}
</script>

<style scoped></style>
