<template>
  <v-card class="flex d-flex flex-column pb-2" elevation="4" :loading="getIsLoadingJobDesiredTimeFrame">
    <v-card-title class="py-4">
      <span class="ps-4 headline">Desired Time Frame</span>
      <v-spacer></v-spacer>
      <v-icon large class="float-end" color="primary" @click="close">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <div>
        <v-form ref="job-desired-time-frame-form" v-model="isJobDesiredTimeFrameFormValid">
          <v-row class="justify-center align-content-center">
            <v-col cols="auto" md="5" sm="12">
              <app-time-picker-menu
                text-field-label="Start"
                :time-value="startTime"
                @updateTimeValue="setStartTime($event)"
                :allowed-minutes="allowedMinutes"
                :is-hide-details-enabled="false"
                :is-disabled="false"
              ></app-time-picker-menu>
            </v-col>
            <v-col cols="auto" md="5" sm="12">
              <app-time-picker-menu
                class="mt-sm-n8 mt-md-0"
                text-field-label="End"
                :time-value="endTime"
                @updateTimeValue="setEndTime($event)"
                :allowed-minutes="allowedMinutes"
                :is-hide-details-enabled="false"
                :is-disabled="false"
                :validation-rules="endTimeValidationRules"
              ></app-time-picker-menu>
            </v-col>
            <v-col cols="auto" md="2" class="mb-8 ms-n4 mt-sm-n8 mt-md-4">
              <v-btn v-if="isSaveEnabled" ref="job-desired-time-frame-save-item-button" @click="saveTimeFrame" color="primary" icon>
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
              <v-btn v-if="hasDesiredTimeFrameId" class="ms-4" ref="job-desired-time-frame-delete-item-button" @click="deleteTimeFrame" color="error" icon>
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import AppTimePickerMenu from "@/components/AppTimePickerMenu.vue"
import { mapActions, mapState } from "pinia"
import { useJobStore } from "@/stores/Job"
import { upsertDesiredTimeFrameDto } from "@/api/dtos/JunkDtos"
import dayjs from "dayjs"

export default {
  name: "JobDesiredTimeFrameCard",
  components: { AppTimePickerMenu },
  props: {
    isActionsDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      startTime: undefined,
      endTime: undefined,
      isJobDesiredTimeFrameFormValid: false,
      endTimeValidationRules: [
        value => {
          if (!value || !this.startTime) return true
          const baseDate = "1970-01-01"
          const start = dayjs(`${baseDate}T${this.startTime}`)
          const end = dayjs(`${baseDate}T${this.endTime}`)

          return end.isAfter(start) || "End Time must be after the Start Time."
        }
      ]
    }
  },
  methods: {
    ...mapActions(useJobStore, ["upsertDesiredTimeFrame", "deleteDesiredTimeFrame"]),
    allowedMinutes: m => m % 15 === 0,
    close() {
      this.$emit("close")
    },
    async deleteTimeFrame() {
      if (!this.hasDesiredTimeFrameId) return // if this is not defined, then do not process the request
      await this.deleteDesiredTimeFrame(this.getJobDesiredTimeFrame?.desiredTimeFrameId)
    },
    async saveTimeFrame() {
      if (this.$refs["job-desired-time-frame-form"].validate()) {
        const dto = upsertDesiredTimeFrameDto(this.getCurrentJob.id, this.startTime, this.endTime)
        await this.upsertDesiredTimeFrame(dto)
      }
    },
    setStartTime(time) {
      this.$refs["job-desired-time-frame-form"].resetValidation()
      this.startTime = `${time}:00`
    },
    setEndTime(time) {
      this.$refs["job-desired-time-frame-form"].resetValidation()
      this.endTime = `${time}:00`
    }
  },
  computed: {
    ...mapState(useJobStore, ["getCurrentJob", "getIsLoadingJobDesiredTimeFrame", "getJobDesiredTimeFrame"]),
    hasDesiredTimeFrameId() {
      return this.getJobDesiredTimeFrame?.desiredTimeFrameId != null
    },
    isSaveEnabled() {
      return this.startTime !== "" && this.startTime != null && this.endTime !== "" && this.endTime != null
    }
  },
  watch: {
    getJobDesiredTimeFrame: {
      handler(newVal) {
        this.startTime = newVal?.startTime
        this.endTime = newVal?.endTime
      },
      immediate: true
    }
  }
}
</script>

<style scoped></style>
