<template>
  <v-container fluid>
    <base-dialog
      ref="settings-create-update-helper-employee-dialog-root"
      :is-dialog-visible="isDialogVisible"
      :title="title"
      dialog-image="mdi-account-plus-outline"
      @close-dialog="closeDialog"
    >
      <template v-slot:content>
        <v-form ref="settings-create-update-helper-employee-dialog-form" v-model="isCreateUpdateHelperEmployeeFormValid">
          <v-row dense>
            <v-text-field
              class="ma-2"
              ref="settings-create-update-helper-employee-dialog-first-name"
              label="First Name"
              v-model="mutableFirstName"
              :rules="isRequiredRules"
              outlined
              required
              flat
              solo
              clearable
            />
            <v-text-field
              class="ma-2"
              ref="settings-create-update-helper-employee-dialog-last-name"
              label="Last Name"
              v-model="mutableLastName"
              :rules="isRequiredRules"
              outlined
              required
              flat
              solo
              clearable
            />
          </v-row>
          <v-row dense>
            <v-autocomplete
              class="ma-2"
              ref="settings-create-update-helper-employee-dialog-selected-role"
              label="Selected Operating Units"
              item-text="displayName"
              item-value="id"
              v-model="mutableAssignedOperatingUnits"
              :items="getOperatingUnitMetadataList"
              :disabled="getIsLoadingEmployees"
              outlined
              multiple
              flat
              solo
              clearable
              chips
              deletable-chips
            />
          </v-row>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn class="pa-4" color="primaryText" ref="settings-create-update-helper-employee-dialog-cancel-button" @click="closeDialog" text rounded ripple>Cancel </v-btn>
        <v-btn
          class="pa-4"
          ref="settings-create-update-helper-employee-dialog-confirm-button"
          color="primary"
          :loading="getIsLoadingEmployees"
          :disabled="isSaveButtonDisabled"
          @click="handleSave"
          text
          rounded
          ripple
        >
          {{ confirmationButtonText }}
        </v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>

<script>
import BaseDialog from "@/components/BaseDialog"
import { useMainStore } from "@/stores/Main"
import { useSettingsStore } from "@/stores/Settings"
import { mapActions, mapState } from "pinia"
import { createHelperEmployeeDto, updateHelperEmployeeDto } from "@/api/dtos/JunkDtos"

export default {
  name: "SettingsCreateHelperEmployeeDialog",
  components: { BaseDialog },
  props: {
    isDialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Create Helper Employee"
    },
    id: {
      type: String,
      default: null
    },
    firstName: {
      type: String,
      default: ""
    },
    lastName: {
      type: String,
      default: ""
    },
    type: {
      type: Number,
      default: 1
    },
    assignedOperatingUnits: {
      type: Array,
      default: () => []
    },
    confirmationButtonText: {
      type: String,
      default: "Create"
    }
  },
  data() {
    return {
      isCreateUpdateHelperEmployeeFormValid: false,
      mutableFirstName: "",
      mutableLastName: "",
      mutableAssignedOperatingUnits: [],
      isRequiredRules: [value => !!value || "Is required."]
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ["createHelperEmployee", "updateHelperEmployee"]),
    handleSave() {
      this.type === 1 ? this.handleCreate() : this.handleUpdate()
    },
    async handleCreate() {
      if (this.$refs["settings-create-update-helper-employee-dialog-form"].validate()) {
        const dto = createHelperEmployeeDto(this.mutableFirstName.trim(), this.mutableLastName.trim(), this.active)
        await this.createHelperEmployee(dto).then(() => {
          this.closeDialog()
        })
      }
    },
    async handleUpdate() {
      if (this.$refs["settings-create-update-helper-employee-dialog-form"].validate()) {
        const dto = updateHelperEmployeeDto(this.id, this.mutableFirstName.trim(), this.mutableLastName.trim(), this.active, this.inactive)
        await this.updateHelperEmployee(dto).then(() => {
          this.closeDialog()
        })
      }
    },
    closeDialog() {
      this.$emit("closeDialog")
    }
  },
  computed: {
    ...mapState(useMainStore, ["getOperatingUnitMetadataList"]),
    ...mapState(useSettingsStore, ["getIsLoadingEmployees"]),
    active() {
      return this.mutableAssignedOperatingUnits.slice().filter(unit => !this.assignedOperatingUnits.includes(unit))
    },
    inactive() {
      return this.assignedOperatingUnits.slice().map(o => o.operatingUnitId).filter(unit => !this.mutableAssignedOperatingUnits.includes(unit))
    },
    isNameEmptyOnCreate() {
      return this.type === 1 && (this.mutableFirstName.length === 0 || this.mutableLastName.length === 0)
    },
    isNameUnmodifiedOnUpdate() {
      return this.type === 2 && this.firstName === this.mutableFirstName && this.lastName === this.mutableLastName
    },
    isAssignedOperatingUnitsEmptyOnCreate() {
      return this.type === 1 && this.mutableAssignedOperatingUnits.length === 0
    },
    isOperatingUnitsUnmodifiedOnUpdate() {
      return (
        this.type === 2 &&
        this.assignedOperatingUnits.length === this.mutableAssignedOperatingUnits.length &&
        this.assignedOperatingUnits.every(unit => this.mutableAssignedOperatingUnits.includes(unit))
      )
    },
    isFormInvalidForCreateOrUpdate() {
      return this.isNameEmptyOnCreate || this.isAssignedOperatingUnitsEmptyOnCreate || (this.isNameUnmodifiedOnUpdate && this.isOperatingUnitsUnmodifiedOnUpdate)
    },
    isSaveButtonDisabled() {
      return this.getIsLoadingEmployees || this.isFormInvalidForCreateOrUpdate
    }
  },
  created() {
    this.mutableFirstName = this.firstName
    this.mutableLastName = this.lastName
    this.mutableAssignedOperatingUnits = this.assignedOperatingUnits.filter(ou => ou.isEnabled).map(ou => ou.operatingUnitId)
  }
}
</script>
