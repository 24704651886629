<template>
  <v-card ref="dashboard-chart-card-root" :loading="isLoading">
    <v-card-title class="headline" ref="dashboard-chart-card-title">
      {{ title }}
      <v-spacer />
      <v-card outlined flat>
        <div class="px-4 py-2 text-md-body-1" ref="dashboard-chart-card-subtitle-text">{{ subCardText }}</div>
      </v-card>
    </v-card-title>
    <slot name="content" />
  </v-card>
</template>

<script>
export default {
  name: "DashboardChartCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    subCardText: {
      type: String,
      default: ""
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
