import { defineStore } from "pinia"
import { useSnackbarStore } from "@/stores/Snackbar"
import junkApi from "@/api/Junk"
import { errorMessageHandler } from "@/utils/ErrorMessageHandler"
import { persistentErrorSnackbarWithPositiveAction } from "@/utils/SnackbarBuilder"
import { todayAsDate } from "@/utils/DateTimeFormatters"
import dayjs from "dayjs"

export const useJobManagementStore = defineStore("job-management", {
  state: () => ({
    isLoadingJobStatuses: false,
    jobStatuses: [],
    isJobManagementLoading: false,
    jobManagementPaginationOptions: {
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      totalCount: 0,
      hasPrevious: false,
      hasNext: false
    },
    itemsPerPageOptions: [10, 25, 50, 100],
    jobs: [],
    selectedStatuses: [1, 2, 3],
    selectedDateRange: [
      todayAsDate(),
      dayjs()
        .add(5, "day")
        .format("YYYY-MM-DD")
    ],
    includeOnlyCustomerSelfScheduled: false
  }),
  getters: {
    getIsLoadingJobStatuses() {
      return this.isLoadingJobStatuses
    },
    getJobStatuses() {
      return this.jobStatuses.slice()
    },
    getIsJobManagementLoading() {
      return this.isJobManagementLoading
    },
    getJobManagementPaginationOptions() {
      return this.jobManagementPaginationOptions
    },
    getItemsPerPageOptions() {
      return this.itemsPerPageOptions
    },
    getJobs() {
      return this.jobs.slice()
    },
    getSelectedStatuses() {
      return this.selectedStatuses
    },
    getSelectedDateRange() {
      return this.selectedDateRange
    },
    getIncludeOnlyCustomerSelfScheduled() {
      return this.includeOnlyCustomerSelfScheduled
    }
  },
  actions: {
    async setIsLoadingJobStatuses(isLoading) {
      this.isLoadingJobStatuses = isLoading
    },
    async setJobStatuses(statuses) {
      this.jobStatuses = statuses
    },
    async fetchJunkJobStatuses() {
      await this.setIsLoadingJobStatuses(true)
      return await junkApi
        .fetchJunkJobStatuses()
        .then(data => {
          this.setJobStatuses(data)
          return Promise.resolve(data)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.fetchJunkJobStatuses)
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobStatuses(false)
        })
    },
    async setIsLoadingJobManagement(isLoading) {
      this.isJobManagementLoading = isLoading
    },
    async setJobManagementPaginationOptions(options) {
      this.jobManagementPaginationOptions = Object.assign({}, options)
    },
    async setJobs(jobs) {
      this.jobs = jobs
    },
    async resetJobManagementJobs() {
      this.jobs = []
    },
    async fetchJobManagementData({ operatingUnitId, fromDate, toDate, pageNumber, pageSize }) {
      await this.setIsLoadingJobManagement(true)
      return await junkApi
        .fetchJobManagementData(operatingUnitId, this.getSelectedStatuses, fromDate, toDate, pageNumber, pageSize, this.getIncludeOnlyCustomerSelfScheduled)
        .then(data => {
          const options = {
            currentPage: data.currentPage,
            totalPages: data.totalPages,
            pageSize: data.pageSize,
            totalCount: data.totalCount,
            hasPrevious: data.hasPrevious,
            hasNext: data.hasNext
          }
          this.setJobManagementPaginationOptions(options)
          this.setJobs(data.items)
          return Promise.resolve(data.items)
        })
        .catch(error => {
          const snackbarStore = useSnackbarStore()
          const snackbar = persistentErrorSnackbarWithPositiveAction(`${errorMessageHandler(error)}`, "Retry", () => this.fetchJobManagementData)
          snackbarStore.addSnackbar(snackbar)
          return Promise.reject(error)
        })
        .finally(() => {
          this.setIsLoadingJobManagement(false)
        })
    },
    setSelectedStatuses(statuses) {
      this.selectedStatuses = statuses
    },
    async setSelectedDateRange(dateRange) {
      this.selectedDateRange = dateRange
    },
    setIncludeOnlyCustomerSelfScheduled(includeOnlyCustomerSelfScheduled) {
      this.includeOnlyCustomerSelfScheduled = includeOnlyCustomerSelfScheduled
    }
  }
})
